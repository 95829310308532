/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const getEvents: OperationData<Types.GetEvents> = {
  method: 'get',
  name: 'getEvents',
  summary: `Retrieve events`,
  path: '/events',
  queryKey: ({}) => ['events'],
}

export const createEvent: OperationData<Types.CreateEvent> = {
  method: 'post',
  name: 'createEvent',
  summary: `Create event`,
  path: '/events',
  queryKey: ({}) => ['events'],
}

export const createEventsBulk: OperationData<Types.CreateEventsBulk> = {
  method: 'post',
  name: 'createEventsBulk',
  summary: `Create many events`,
  path: '/events/bulk',
  queryKey: ({}) => ['events', 'bulk'],
}

export const amortizationGet: OperationData<Types.AmortizationGet> = {
  method: 'post',
  name: 'amortizationGet',
  summary: `Amortize loan`,
  path: '/amortize',
  queryKey: ({}) => ['amortize'],
}

export const consentGet: OperationData<Types.ConsentGet> = {
  method: 'get',
  name: 'consentGet',
  summary: `Get consents`,
  path: '/people/{personId}/consents',
  queryKey: ({ personId }) => ['people', personId, 'consents'],
}

export const consentUpdate: OperationData<Types.ConsentUpdate> = {
  method: 'put',
  name: 'consentUpdate',
  summary: `Update consents`,
  path: '/people/{personId}/consents',
  queryKey: ({ personId }) => ['people', personId, 'consents'],
}

export const doNotInteractTypeGetAll: OperationData<Types.DoNotInteractTypeGetAll> = {
  method: 'get',
  name: 'doNotInteractTypeGetAll',
  summary: `Get types`,
  path: '/do-not-interact-types',
  queryKey: ({}) => ['do-not-interact-types'],
}

export const doNotInteractTypeCreate: OperationData<Types.DoNotInteractTypeCreate> = {
  method: 'post',
  name: 'doNotInteractTypeCreate',
  summary: `Create type`,
  path: '/do-not-interact-types',
  queryKey: ({}) => ['do-not-interact-types'],
}

export const doNotInteractTypeGetById: OperationData<Types.DoNotInteractTypeGetById> = {
  method: 'get',
  name: 'doNotInteractTypeGetById',
  summary: `Get type by ID`,
  path: '/do-not-interact-types/{doNotInteractTypeId}',
  queryKey: ({ doNotInteractTypeId }) => ['do-not-interact-types', doNotInteractTypeId],
}

export const doNotInteractTypeUpdate: OperationData<Types.DoNotInteractTypeUpdate> = {
  method: 'put',
  name: 'doNotInteractTypeUpdate',
  summary: `Update type`,
  path: '/do-not-interact-types/{doNotInteractTypeId}',
  queryKey: ({ doNotInteractTypeId }) => ['do-not-interact-types', doNotInteractTypeId],
}

export const notificationTypeGetAll: OperationData<Types.NotificationTypeGetAll> = {
  method: 'get',
  name: 'notificationTypeGetAll',
  summary: `Get types`,
  path: '/notification-types',
  queryKey: ({}) => ['notification-types'],
}

export const notificationTypeCreate: OperationData<Types.NotificationTypeCreate> = {
  method: 'post',
  name: 'notificationTypeCreate',
  summary: `Create type`,
  path: '/notification-types',
  queryKey: ({}) => ['notification-types'],
}

export const notificationTypeGetById: OperationData<Types.NotificationTypeGetById> = {
  method: 'get',
  name: 'notificationTypeGetById',
  summary: `Get type by ID`,
  path: '/notification-types/{notificationTypeId}',
  queryKey: ({ notificationTypeId }) => ['notification-types', notificationTypeId],
}

export const notificationTypeUpdate: OperationData<Types.NotificationTypeUpdate> = {
  method: 'put',
  name: 'notificationTypeUpdate',
  summary: `Update type`,
  path: '/notification-types/{notificationTypeId}',
  queryKey: ({ notificationTypeId }) => ['notification-types', notificationTypeId],
}

export const snoozeCase: OperationData<Types.SnoozeCase> = {
  method: 'post',
  name: 'snoozeCase',
  summary: `Snooze case`,
  path: '/people/{personId}/cases/{caseId}/snooze',
  queryKey: ({ personId, caseId }) => ['people', personId, 'cases', caseId, 'snooze'],
}

export const caseDoNotInteractGetAll: OperationData<Types.CaseDoNotInteractGetAll> = {
  method: 'get',
  name: 'caseDoNotInteractGetAll',
  summary: `Get case do not interacts`,
  path: '/people/{personId}/cases/{caseId}/do-not-interacts',
  queryKey: ({ personId, caseId }) => ['people', personId, 'cases', caseId, 'do-not-interacts'],
}

export const doNotInteractCreate: OperationData<Types.DoNotInteractCreate> = {
  method: 'post',
  name: 'doNotInteractCreate',
  summary: `Create do not interact`,
  path: '/people/{personId}/cases/{caseId}/do-not-interacts',
  queryKey: ({ personId, caseId }) => ['people', personId, 'cases', caseId, 'do-not-interacts'],
}

export const doNotInteractGetById: OperationData<Types.DoNotInteractGetById> = {
  method: 'get',
  name: 'doNotInteractGetById',
  summary: `Get do not interact by ID`,
  path: '/people/{personId}/cases/{caseId}/do-not-interacts/{doNotInteractId}',
  queryKey: ({ personId, caseId, doNotInteractId }) => [
    'people',
    personId,
    'cases',
    caseId,
    'do-not-interacts',
    doNotInteractId,
  ],
}

export const doNotInteractUpdate: OperationData<Types.DoNotInteractUpdate> = {
  method: 'put',
  name: 'doNotInteractUpdate',
  summary: `Update do not interact`,
  path: '/people/{personId}/cases/{caseId}/do-not-interacts/{doNotInteractId}',
  queryKey: ({ personId, caseId, doNotInteractId }) => [
    'people',
    personId,
    'cases',
    caseId,
    'do-not-interacts',
    doNotInteractId,
  ],
}

export const doNotInteractDelete: OperationData<Types.DoNotInteractDelete> = {
  method: 'delete',
  name: 'doNotInteractDelete',
  summary: `Remove do not interact`,
  path: '/people/{personId}/cases/{caseId}/do-not-interacts/{doNotInteractId}',
  queryKey: ({ personId, caseId, doNotInteractId }) => [
    'people',
    personId,
    'cases',
    caseId,
    'do-not-interacts',
    doNotInteractId,
  ],
}

export const listCaseNotes: OperationData<Types.ListCaseNotes> = {
  method: 'get',
  name: 'listCaseNotes',
  summary: `Get notes`,
  path: '/people/{personId}/cases/{caseId}/notes',
  queryKey: ({ personId, caseId }) => ['people', personId, 'cases', caseId, 'notes'],
}

export const createCaseNote: OperationData<Types.CreateCaseNote> = {
  method: 'post',
  name: 'createCaseNote',
  summary: `Create note`,
  path: '/people/{personId}/cases/{caseId}/notes',
  queryKey: ({ personId, caseId }) => ['people', personId, 'cases', caseId, 'notes'],
}

export const getCaseNote: OperationData<Types.GetCaseNote> = {
  method: 'get',
  name: 'getCaseNote',
  summary: `Get note by ID`,
  path: '/people/{personId}/cases/{caseId}/notes/{noteId}',
  queryKey: ({ personId, caseId, noteId }) => ['people', personId, 'cases', caseId, 'notes', noteId],
}

export const updateCaseNote: OperationData<Types.UpdateCaseNote> = {
  method: 'put',
  name: 'updateCaseNote',
  summary: `Update note`,
  path: '/people/{personId}/cases/{caseId}/notes/{noteId}',
  queryKey: ({ personId, caseId, noteId }) => ['people', personId, 'cases', caseId, 'notes', noteId],
}

export const listInteractionNotes: OperationData<Types.ListInteractionNotes> = {
  method: 'get',
  name: 'listInteractionNotes',
  summary: `Get notes`,
  path: '/interactions/{interactionId}/notes',
  queryKey: ({ interactionId }) => ['interactions', interactionId, 'notes'],
}

export const createInteractionNote: OperationData<Types.CreateInteractionNote> = {
  method: 'post',
  name: 'createInteractionNote',
  summary: `Create note`,
  path: '/interactions/{interactionId}/notes',
  queryKey: ({ interactionId }) => ['interactions', interactionId, 'notes'],
}

export const getInteractionNote: OperationData<Types.GetInteractionNote> = {
  method: 'get',
  name: 'getInteractionNote',
  summary: `Get note by ID`,
  path: '/interactions/{interactionId}/notes/{noteId}',
  queryKey: ({ interactionId, noteId }) => ['interactions', interactionId, 'notes', noteId],
}

export const updateInteractionNote: OperationData<Types.UpdateInteractionNote> = {
  method: 'put',
  name: 'updateInteractionNote',
  summary: `Update note`,
  path: '/interactions/{interactionId}/notes/{noteId}',
  queryKey: ({ interactionId, noteId }) => ['interactions', interactionId, 'notes', noteId],
}

export const listBorrowerNotes: OperationData<Types.ListBorrowerNotes> = {
  method: 'get',
  name: 'listBorrowerNotes',
  summary: `Get notes`,
  path: '/people/{personId}/notes',
  queryKey: ({ personId }) => ['people', personId, 'notes'],
}

export const createBorrowerNote: OperationData<Types.CreateBorrowerNote> = {
  method: 'post',
  name: 'createBorrowerNote',
  summary: `Create note`,
  path: '/people/{personId}/notes',
  queryKey: ({ personId }) => ['people', personId, 'notes'],
}

export const getBorrowerNote: OperationData<Types.GetBorrowerNote> = {
  method: 'get',
  name: 'getBorrowerNote',
  summary: `Get note by ID`,
  path: '/people/{personId}/notes/{noteId}',
  queryKey: ({ personId, noteId }) => ['people', personId, 'notes', noteId],
}

export const updateBorrowerNote: OperationData<Types.UpdateBorrowerNote> = {
  method: 'put',
  name: 'updateBorrowerNote',
  summary: `Update note`,
  path: '/people/{personId}/notes/{noteId}',
  queryKey: ({ personId, noteId }) => ['people', personId, 'notes', noteId],
}

export const peopleDoNotInteractGetAll: OperationData<Types.PeopleDoNotInteractGetAll> = {
  method: 'get',
  name: 'peopleDoNotInteractGetAll',
  summary: `Get all do not interacts`,
  path: '/people/{personId}/do-not-interacts',
  queryKey: ({ personId }) => ['people', personId, 'do-not-interacts'],
}

export const listBorrowerInteractionNotes: OperationData<Types.ListBorrowerInteractionNotes> = {
  method: 'get',
  name: 'listBorrowerInteractionNotes',
  summary: `Get interaction notes for a person`,
  path: '/people/{personId}/interaction-notes',
  queryKey: ({ personId }) => ['people', personId, 'interaction-notes'],
}

export const loansGetByCompany: OperationData<Types.LoansGetByCompany> = {
  method: 'get',
  name: 'loansGetByCompany',
  summary: `Get loans`,
  path: '/loans',
  queryKey: ({}) => ['loans'],
}

export const loansGet: OperationData<Types.LoansGet> = {
  method: 'get',
  name: 'loansGet',
  summary: `Get borrower's loans`,
  path: '/people/{personId}/loans',
  queryKey: ({ personId }) => ['people', personId, 'loans'],
}

export const loansAdd: OperationData<Types.LoansAdd> = {
  method: 'post',
  name: 'loansAdd',
  summary: `Create loan`,
  path: '/people/{personId}/loans',
  queryKey: ({ personId }) => ['people', personId, 'loans'],
}

export const loanGet: OperationData<Types.LoanGet> = {
  method: 'get',
  name: 'loanGet',
  summary: `Get loan by ID`,
  path: '/people/{personId}/loans/{loanId}',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId],
}

export const loanUpdate: OperationData<Types.LoanUpdate> = {
  method: 'put',
  name: 'loanUpdate',
  summary: `Update loan`,
  path: '/people/{personId}/loans/{loanId}',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId],
}

export const getLockStatus: OperationData<Types.GetLockStatus> = {
  method: 'get',
  name: 'getLockStatus',
  summary: `Get loan lock status`,
  path: '/people/{personId}/loans/{loanId}/lock-status',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'lock-status'],
}

export const getLoanAdvances: OperationData<Types.GetLoanAdvances> = {
  method: 'get',
  name: 'getLoanAdvances',
  summary: `Get advances`,
  path: '/people/{personId}/loans/{loanId}/advances',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'advances'],
}

export const createLoanAdvance: OperationData<Types.CreateLoanAdvance> = {
  method: 'post',
  name: 'createLoanAdvance',
  summary: `Create advance`,
  path: '/people/{personId}/loans/{loanId}/advances',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'advances'],
}

export const getLoanAdvance: OperationData<Types.GetLoanAdvance> = {
  method: 'get',
  name: 'getLoanAdvance',
  summary: `Get advance by ID`,
  path: '/people/{personId}/loans/{loanId}/advances/{advanceId}',
  queryKey: ({ personId, loanId, advanceId }) => ['people', personId, 'loans', loanId, 'advances', advanceId],
}

export const updateLoanAdvance: OperationData<Types.UpdateLoanAdvance> = {
  method: 'put',
  name: 'updateLoanAdvance',
  summary: `Update advance`,
  path: '/people/{personId}/loans/{loanId}/advances/{advanceId}',
  queryKey: ({ personId, loanId, advanceId }) => ['people', personId, 'loans', loanId, 'advances', advanceId],
}

export const getLoanPeriods: OperationData<Types.GetLoanPeriods> = {
  method: 'get',
  name: 'getLoanPeriods',
  summary: `Get loan periods`,
  path: '/people/{personId}/loans/{loanId}/periods',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'periods'],
}

export const getLoanPeriodById: OperationData<Types.GetLoanPeriodById> = {
  method: 'get',
  name: 'getLoanPeriodById',
  summary: `Get loan period by id`,
  path: '/people/{personId}/loans/{loanId}/periods/{loanPeriodId}',
  queryKey: ({ personId, loanId, loanPeriodId }) => ['people', personId, 'loans', loanId, 'periods', loanPeriodId],
}

export const loanScheduleOptionsGet: OperationData<Types.LoanScheduleOptionsGet> = {
  method: 'get',
  name: 'loanScheduleOptionsGet',
  summary: `Get loan schedule options`,
  path: '/people/{personId}/loans/{loanId}/schedule-options',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'schedule-options'],
}

export const loanActivate: OperationData<Types.LoanActivate> = {
  method: 'post',
  name: 'loanActivate',
  summary: `Activate loan`,
  path: '/people/{personId}/loans/{loanId}/activate',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'activate'],
}

export const loanMigrate: OperationData<Types.LoanMigrate> = {
  method: 'post',
  name: 'loanMigrate',
  summary: `Migrate loan`,
  path: '/people/{personId}/loans/{loanId}/migrate',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'migrate'],
}

export const loanReimburse: OperationData<Types.LoanReimburse> = {
  method: 'post',
  name: 'loanReimburse',
  summary: `Reimburse`,
  path: '/people/{personId}/loans/{loanId}/reimburse',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'reimburse'],
}

export const loanRefundsGet: OperationData<Types.LoanRefundsGet> = {
  method: 'get',
  name: 'loanRefundsGet',
  summary: `Get refunds`,
  path: '/people/{personId}/loans/{loanId}/refunds',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'refunds'],
}

export const loanRefundsPost: OperationData<Types.LoanRefundsPost> = {
  method: 'post',
  name: 'loanRefundsPost',
  summary: `Create refund`,
  path: '/people/{personId}/loans/{loanId}/refunds',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'refunds'],
}

export const loanRefundsCancel: OperationData<Types.LoanRefundsCancel> = {
  method: 'post',
  name: 'loanRefundsCancel',
  summary: `Cancel refund`,
  path: '/people/{personId}/loans/{loanId}/refunds/{loanRefundId}/cancel',
  queryKey: ({ personId, loanId, loanRefundId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'refunds',
    loanRefundId,
    'cancel',
  ],
}

export const loanRefundsV2Post: OperationData<Types.LoanRefundsV2Post> = {
  method: 'post',
  name: 'loanRefundsV2Post',
  summary: `Create refund v2`,
  path: '/people/{personId}/loans/{loanId}/refunds-v2',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'refunds-v2'],
}

export const loanCancel: OperationData<Types.LoanCancel> = {
  method: 'post',
  name: 'loanCancel',
  summary: `Cancel loan`,
  path: '/people/{personId}/loans/{loanId}/cancel',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'cancel'],
}

export const loanClose: OperationData<Types.LoanClose> = {
  method: 'post',
  name: 'loanClose',
  summary: `Close loan`,
  path: '/people/{personId}/loans/{loanId}/close',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'close'],
}

export const loanReopen: OperationData<Types.LoanReopen> = {
  method: 'post',
  name: 'loanReopen',
  summary: `Reopen a closed loan`,
  path: '/people/{personId}/loans/{loanId}/reopen',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'reopen'],
}

export const drawDynamicFeesAtOriginationUpdate: OperationData<Types.DrawDynamicFeesAtOriginationUpdate> = {
  method: 'put',
  name: 'drawDynamicFeesAtOriginationUpdate',
  summary: `Update draw fees`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/dynamic-fees',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'dynamic-fees'],
}

export const drawClose: OperationData<Types.DrawClose> = {
  method: 'post',
  name: 'drawClose',
  summary: `Close draw`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/close',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'close'],
}

export const drawReopen: OperationData<Types.DrawReopen> = {
  method: 'post',
  name: 'drawReopen',
  summary: `Reopen draw`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/reopen',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'reopen'],
}

export const drawCancel: OperationData<Types.DrawCancel> = {
  method: 'post',
  name: 'drawCancel',
  summary: `Cancel draw`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/cancel',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'cancel'],
}

export const loanGetCreditLimit: OperationData<Types.LoanGetCreditLimit> = {
  method: 'get',
  name: 'loanGetCreditLimit',
  summary: `Get loan credit limit`,
  path: '/people/{personId}/loans/{loanId}/credit-limit',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'credit-limit'],
}

export const loanUpdateCreditLimit: OperationData<Types.LoanUpdateCreditLimit> = {
  method: 'post',
  name: 'loanUpdateCreditLimit',
  summary: `Update loan credit limit`,
  path: '/people/{personId}/loans/{loanId}/credit-limit',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'credit-limit'],
}

export const drawGetCreditLimit: OperationData<Types.DrawGetCreditLimit> = {
  method: 'get',
  name: 'drawGetCreditLimit',
  summary: `Get draw credit limit`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/credit-limit',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'credit-limit'],
}

export const drawUpdateCreditLimit: OperationData<Types.DrawUpdateCreditLimit> = {
  method: 'post',
  name: 'drawUpdateCreditLimit',
  summary: `Update draw credit limit`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/credit-limit',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'credit-limit'],
}

export const feeTypesGet: OperationData<Types.FeeTypesGet> = {
  method: 'get',
  name: 'feeTypesGet',
  summary: `Get fee types`,
  path: '/loan-types/{loanTypeId}/fee-types',
  queryKey: ({ loanTypeId }) => ['loan-types', loanTypeId, 'fee-types'],
}

export const dynamicFeeUpdate: OperationData<Types.DynamicFeeUpdate> = {
  method: 'put',
  name: 'dynamicFeeUpdate',
  summary: `Update dynamic fee`,
  path: '/people/{personId}/loans/{loanId}/fees/{loanFeeId}',
  queryKey: ({ personId, loanId, loanFeeId }) => ['people', personId, 'loans', loanId, 'fees', loanFeeId],
}

export const feesGet: OperationData<Types.FeesGet> = {
  method: 'get',
  name: 'feesGet',
  summary: `Get fees`,
  path: '/people/{personId}/loans/{loanId}/fees',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'fees'],
}

export const feesPost: OperationData<Types.FeesPost> = {
  method: 'post',
  name: 'feesPost',
  summary: `Charge dynamic fee`,
  path: '/people/{personId}/loans/{loanId}/fees',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'fees'],
}

export const feesCancelOne: OperationData<Types.FeesCancelOne> = {
  method: 'post',
  name: 'feesCancelOne',
  summary: `Cancel dynamic fee`,
  path: '/people/{personId}/loans/{loanId}/fees/{loanFeeId}/cancel',
  queryKey: ({ personId, loanId, loanFeeId }) => ['people', personId, 'loans', loanId, 'fees', loanFeeId, 'cancel'],
}

export const feesCancel: OperationData<Types.FeesCancel> = {
  method: 'post',
  name: 'feesCancel',
  summary: `Cancel dynamic fees`,
  path: '/people/{personId}/loans/{loanId}/cancel-fees',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'cancel-fees'],
}

export const updateOriginationFee: OperationData<Types.UpdateOriginationFee> = {
  method: 'put',
  name: 'updateOriginationFee',
  summary: `Update origination fee`,
  path: '/people/{personId}/loans/{loanId}/fees/origination',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'fees', 'origination'],
}

export const updateDrawFee: OperationData<Types.UpdateDrawFee> = {
  method: 'put',
  name: 'updateDrawFee',
  summary: `Update draw fee`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/fees/draw',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'fees', 'draw'],
}

export const loanFreeze: OperationData<Types.LoanFreeze> = {
  method: 'post',
  name: 'loanFreeze',
  summary: `Freeze loan`,
  path: '/people/{personId}/loans/{loanId}/freeze',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'freeze'],
}

export const loanUnfreeze: OperationData<Types.LoanUnfreeze> = {
  method: 'post',
  name: 'loanUnfreeze',
  summary: `Unfreeze loan`,
  path: '/people/{personId}/loans/{loanId}/unfreeze',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'unfreeze'],
}

export const loanChargeOff: OperationData<Types.LoanChargeOff> = {
  method: 'post',
  name: 'loanChargeOff',
  summary: `Charge-off loan`,
  path: '/people/{personId}/loans/{loanId}/charge-off',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'charge-off'],
}

export const loanChargeOffReverse: OperationData<Types.LoanChargeOffReverse> = {
  method: 'post',
  name: 'loanChargeOffReverse',
  summary: `Reverse charge-off`,
  path: '/people/{personId}/loans/{loanId}/reverse-charge-off',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'reverse-charge-off'],
}

export const loanAccelerate: OperationData<Types.LoanAccelerate> = {
  method: 'post',
  name: 'loanAccelerate',
  summary: `Accelerate loan`,
  path: '/people/{personId}/loans/{loanId}/accelerate',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'accelerate'],
}

export const loanAccelerateReverse: OperationData<Types.LoanAccelerateReverse> = {
  method: 'post',
  name: 'loanAccelerateReverse',
  summary: `Reverse accelerate`,
  path: '/people/{personId}/loans/{loanId}/reverse-accelerate',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'reverse-accelerate'],
}

export const loanAgentDashboardToolsGet: OperationData<Types.LoanAgentDashboardToolsGet> = {
  method: 'get',
  name: 'loanAgentDashboardToolsGet',
  summary: `Get agent dashboard tools`,
  path: '/people/{personId}/loans/{loanId}/agent-dashboard-tools',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'agent-dashboard-tools'],
}

export const loanCustomPaymentPlanGet: OperationData<Types.LoanCustomPaymentPlanGet> = {
  method: 'get',
  name: 'loanCustomPaymentPlanGet',
  summary: `Get payment plans`,
  path: '/people/{personId}/loans/{loanId}/custom-payment-plans',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'custom-payment-plans'],
}

export const loanCustomPaymentPlanCreate: OperationData<Types.LoanCustomPaymentPlanCreate> = {
  method: 'post',
  name: 'loanCustomPaymentPlanCreate',
  summary: `Create payment plan`,
  path: '/people/{personId}/loans/{loanId}/custom-payment-plans',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'custom-payment-plans'],
}

export const loanCustomPaymentPlanGetById: OperationData<Types.LoanCustomPaymentPlanGetById> = {
  method: 'get',
  name: 'loanCustomPaymentPlanGetById',
  summary: `Get payment plan by id`,
  path: '/people/{personId}/loans/{loanId}/custom-payment-plans/{paymentPlanId}',
  queryKey: ({ personId, loanId, paymentPlanId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'custom-payment-plans',
    paymentPlanId,
  ],
}

export const loanCustomPaymentPlanCancel: OperationData<Types.LoanCustomPaymentPlanCancel> = {
  method: 'post',
  name: 'loanCustomPaymentPlanCancel',
  summary: `Cancel payment plan`,
  path: '/people/{personId}/loans/{loanId}/custom-payment-plans/{paymentPlanId}/cancel',
  queryKey: ({ personId, loanId, paymentPlanId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'custom-payment-plans',
    paymentPlanId,
    'cancel',
  ],
}

export const loanChangeTerms: OperationData<Types.LoanChangeTerms> = {
  method: 'post',
  name: 'loanChangeTerms',
  summary: `Change loan terms`,
  path: '/people/{personId}/loans/{loanId}/payment-plan',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'payment-plan'],
}

export const loanChangeTermsScra: OperationData<Types.LoanChangeTermsScra> = {
  method: 'post',
  name: 'loanChangeTermsScra',
  summary: `Change loan terms SCRA`,
  path: '/people/{personId}/loans/{loanId}/payment-plan-scra',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'payment-plan-scra'],
}

export const loanDueDateDefer: OperationData<Types.LoanDueDateDefer> = {
  method: 'post',
  name: 'loanDueDateDefer',
  summary: `Defer single due date`,
  path: '/people/{personId}/loans/{loanId}/defer-due-date',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'defer-due-date'],
}

export const loanScheduleDefer: OperationData<Types.LoanScheduleDefer> = {
  method: 'post',
  name: 'loanScheduleDefer',
  summary: `Defer schedule`,
  path: '/people/{personId}/loans/{loanId}/defer-schedule',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'defer-schedule'],
}

export const loanRefresh: OperationData<Types.LoanRefresh> = {
  method: 'post',
  name: 'loanRefresh',
  summary: `Refresh loan`,
  path: '/people/{personId}/loans/{loanId}/refresh',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'refresh'],
}

export const loanRatesGet: OperationData<Types.LoanRatesGet> = {
  method: 'get',
  name: 'loanRatesGet',
  summary: `Get loan rates`,
  path: '/people/{personId}/loans/{loanId}/rates',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'rates'],
}

export const loanRatesAdd: OperationData<Types.LoanRatesAdd> = {
  method: 'post',
  name: 'loanRatesAdd',
  summary: `Add loan rate`,
  path: '/people/{personId}/loans/{loanId}/rates',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'rates'],
}

export const loanEffectiveRatesGet: OperationData<Types.LoanEffectiveRatesGet> = {
  method: 'get',
  name: 'loanEffectiveRatesGet',
  summary: `Get loan effective rates`,
  path: '/people/{personId}/loans/{loanId}/effective-rates',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'effective-rates'],
}

export const loanRatesUpdate: OperationData<Types.LoanRatesUpdate> = {
  method: 'put',
  name: 'loanRatesUpdate',
  summary: `Update loan rate`,
  path: '/people/{personId}/loans/{loanId}/rates/{rateId}',
  queryKey: ({ personId, loanId, rateId }) => ['people', personId, 'loans', loanId, 'rates', rateId],
}

export const loanRatesDelete: OperationData<Types.LoanRatesDelete> = {
  method: 'delete',
  name: 'loanRatesDelete',
  summary: `Remove loan rate`,
  path: '/people/{personId}/loans/{loanId}/rates/{rateId}',
  queryKey: ({ personId, loanId, rateId }) => ['people', personId, 'loans', loanId, 'rates', rateId],
}

export const drawRatesGet: OperationData<Types.DrawRatesGet> = {
  method: 'get',
  name: 'drawRatesGet',
  summary: `Get draw rates`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/rates',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'rates'],
}

export const drawRatesAdd: OperationData<Types.DrawRatesAdd> = {
  method: 'post',
  name: 'drawRatesAdd',
  summary: `Add draw rate`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/rates',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'rates'],
}

export const drawEffectiveRatesGet: OperationData<Types.DrawEffectiveRatesGet> = {
  method: 'get',
  name: 'drawEffectiveRatesGet',
  summary: `Get draw effective rates`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/effective-rates',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'effective-rates'],
}

export const drawRatesUpdate: OperationData<Types.DrawRatesUpdate> = {
  method: 'put',
  name: 'drawRatesUpdate',
  summary: `Update draw rate`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/rates/{rateId}',
  queryKey: ({ personId, loanId, drawId, rateId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'rates',
    rateId,
  ],
}

export const drawRatesDelete: OperationData<Types.DrawRatesDelete> = {
  method: 'delete',
  name: 'drawRatesDelete',
  summary: `Remove draw rate`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/rates/{rateId}',
  queryKey: ({ personId, loanId, drawId, rateId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'rates',
    rateId,
  ],
}

export const loanExpectedPaymentsGet: OperationData<Types.LoanExpectedPaymentsGet> = {
  method: 'get',
  name: 'loanExpectedPaymentsGet',
  summary: `Get loan expected payments`,
  path: '/people/{personId}/loans/{loanId}/expected-payments',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'expected-payments'],
}

export const loanExpectedPaymentsPost: OperationData<Types.LoanExpectedPaymentsPost> = {
  method: 'post',
  name: 'loanExpectedPaymentsPost',
  summary: `Change due dates schedule`,
  path: '/people/{personId}/loans/{loanId}/expected-payments',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'expected-payments'],
}

export const drawExpectedPaymentsGet: OperationData<Types.DrawExpectedPaymentsGet> = {
  method: 'get',
  name: 'drawExpectedPaymentsGet',
  summary: `Get draw expected payments`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/expected-payments',
  queryKey: ({ personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'expected-payments',
  ],
}

export const promoProgramsGetAll: OperationData<Types.PromoProgramsGetAll> = {
  method: 'get',
  name: 'promoProgramsGetAll',
  summary: `Get promo programs`,
  path: '/people/{personId}/loans/{loanId}/promo-programs',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'promo-programs'],
}

export const promoProgramsAdd: OperationData<Types.PromoProgramsAdd> = {
  method: 'post',
  name: 'promoProgramsAdd',
  summary: `Create promo program`,
  path: '/people/{personId}/loans/{loanId}/promo-programs',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'promo-programs'],
}

export const promoProgramsActivate: OperationData<Types.PromoProgramsActivate> = {
  method: 'post',
  name: 'promoProgramsActivate',
  summary: `Activate promo program`,
  path: '/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}/activate',
  queryKey: ({ personId, loanId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
    'activate',
  ],
}

export const promoProgramsGet: OperationData<Types.PromoProgramsGet> = {
  method: 'get',
  name: 'promoProgramsGet',
  summary: `Get promo program by ID`,
  path: '/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}',
  queryKey: ({ personId, loanId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
  ],
}

export const promoProgramsUpdate: OperationData<Types.PromoProgramsUpdate> = {
  method: 'put',
  name: 'promoProgramsUpdate',
  summary: `Update promo program`,
  path: '/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}',
  queryKey: ({ personId, loanId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
  ],
}

export const promoProgramsDelete: OperationData<Types.PromoProgramsDelete> = {
  method: 'delete',
  name: 'promoProgramsDelete',
  summary: `Cancel promo program`,
  path: '/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}',
  queryKey: ({ personId, loanId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
  ],
}

export const loanTypeIndexRateGetAll: OperationData<Types.LoanTypeIndexRateGetAll> = {
  method: 'get',
  name: 'loanTypeIndexRateGetAll',
  summary: `Get index interest rates`,
  path: '/loan-types/{loanTypeId}/index-rates',
  queryKey: ({ loanTypeId }) => ['loan-types', loanTypeId, 'index-rates'],
}

export const loanTypeIndexRateAdd: OperationData<Types.LoanTypeIndexRateAdd> = {
  method: 'post',
  name: 'loanTypeIndexRateAdd',
  summary: `Add index interest rate`,
  path: '/loan-types/{loanTypeId}/index-rates',
  queryKey: ({ loanTypeId }) => ['loan-types', loanTypeId, 'index-rates'],
}

export const loanTypeIndexRateGet: OperationData<Types.LoanTypeIndexRateGet> = {
  method: 'get',
  name: 'loanTypeIndexRateGet',
  summary: `Get index interest rate by ID`,
  path: '/loan-types/{loanTypeId}/index-rates/{indexRateId}',
  queryKey: ({ loanTypeId, indexRateId }) => ['loan-types', loanTypeId, 'index-rates', indexRateId],
}

export const loanTypeIndexRateUpdate: OperationData<Types.LoanTypeIndexRateUpdate> = {
  method: 'put',
  name: 'loanTypeIndexRateUpdate',
  summary: `Update index interest rate`,
  path: '/loan-types/{loanTypeId}/index-rates/{indexRateId}',
  queryKey: ({ loanTypeId, indexRateId }) => ['loan-types', loanTypeId, 'index-rates', indexRateId],
}

export const loanTypeIndexRateDelete: OperationData<Types.LoanTypeIndexRateDelete> = {
  method: 'delete',
  name: 'loanTypeIndexRateDelete',
  summary: `Cancel index interest rate`,
  path: '/loan-types/{loanTypeId}/index-rates/{indexRateId}',
  queryKey: ({ loanTypeId, indexRateId }) => ['loan-types', loanTypeId, 'index-rates', indexRateId],
}

export const obligationsGetAll: OperationData<Types.ObligationsGetAll> = {
  method: 'get',
  name: 'obligationsGetAll',
  summary: `Get loan obligations`,
  path: '/people/{personId}/loans/{loanId}/obligations',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'obligations'],
}

export const obligationCreate: OperationData<Types.ObligationCreate> = {
  method: 'post',
  name: 'obligationCreate',
  summary: `Create obligation`,
  path: '/people/{personId}/loans/{loanId}/obligations',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'obligations'],
}

export const drawObligationsGetAll: OperationData<Types.DrawObligationsGetAll> = {
  method: 'get',
  name: 'drawObligationsGetAll',
  summary: `Get draw obligations`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/obligations',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'obligations'],
}

export const obligationGetById: OperationData<Types.ObligationGetById> = {
  method: 'get',
  name: 'obligationGetById',
  summary: `Get loan obligation by ID`,
  path: '/people/{personId}/loans/{loanId}/obligations/{obligationId}',
  queryKey: ({ personId, loanId, obligationId }) => ['people', personId, 'loans', loanId, 'obligations', obligationId],
}

export const obligationUpdate: OperationData<Types.ObligationUpdate> = {
  method: 'put',
  name: 'obligationUpdate',
  summary: `Update obligation`,
  path: '/people/{personId}/loans/{loanId}/obligations/{obligationId}',
  queryKey: ({ personId, loanId, obligationId }) => ['people', personId, 'loans', loanId, 'obligations', obligationId],
}

export const drawObligationGetById: OperationData<Types.DrawObligationGetById> = {
  method: 'get',
  name: 'drawObligationGetById',
  summary: `Get draw obligation by ID`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/obligations/{obligationId}',
  queryKey: ({ personId, loanId, drawId, obligationId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'obligations',
    obligationId,
  ],
}

export const loanDrawsGet: OperationData<Types.LoanDrawsGet> = {
  method: 'get',
  name: 'loanDrawsGet',
  summary: `Get draws`,
  path: '/people/{personId}/loans/{loanId}/draws',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'draws'],
}

export const loanDrawsPost: OperationData<Types.LoanDrawsPost> = {
  method: 'post',
  name: 'loanDrawsPost',
  summary: `Create draw`,
  path: '/people/{personId}/loans/{loanId}/draws',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'draws'],
}

export const loanDrawGet: OperationData<Types.LoanDrawGet> = {
  method: 'get',
  name: 'loanDrawGet',
  summary: `Get draw by ID`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId],
}

export const drawUpdate: OperationData<Types.DrawUpdate> = {
  method: 'put',
  name: 'drawUpdate',
  summary: `Update draw`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId],
}

export const drawActivate: OperationData<Types.DrawActivate> = {
  method: 'post',
  name: 'drawActivate',
  summary: `Activate draw`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/activate',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'activate'],
}

export const previewDrawAmortize: OperationData<Types.PreviewDrawAmortize> = {
  method: 'post',
  name: 'previewDrawAmortize',
  summary: `Preview draw amortization`,
  path: '/people/{personId}/loans/{loanId}/amortize-preview',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'amortize-preview'],
}

export const loanDrawAmortize: OperationData<Types.LoanDrawAmortize> = {
  method: 'post',
  name: 'loanDrawAmortize',
  summary: `Amortize draw`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/amortize',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'amortize'],
}

export const drawPurchasesGet: OperationData<Types.DrawPurchasesGet> = {
  method: 'get',
  name: 'drawPurchasesGet',
  summary: `Get draw purchases`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'purchases'],
}

export const drawPurchasesCreate: OperationData<Types.DrawPurchasesCreate> = {
  method: 'post',
  name: 'drawPurchasesCreate',
  summary: `Create purchase`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'purchases'],
}

export const drawPurchasesTimelineGet: OperationData<Types.DrawPurchasesTimelineGet> = {
  method: 'get',
  name: 'drawPurchasesTimelineGet',
  summary: `Get draw purchases timeline`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases-timeline',
  queryKey: ({ personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases-timeline',
  ],
}

export const drawPurchaseGetOne: OperationData<Types.DrawPurchaseGetOne> = {
  method: 'get',
  name: 'drawPurchaseGetOne',
  summary: `Get purchase by ID`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}',
  queryKey: ({ personId, loanId, drawId, purchaseId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
  ],
}

export const drawPurchaseUpdate: OperationData<Types.DrawPurchaseUpdate> = {
  method: 'put',
  name: 'drawPurchaseUpdate',
  summary: `Update purchase`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}',
  queryKey: ({ personId, loanId, drawId, purchaseId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
  ],
}

export const loanPurchasesGet: OperationData<Types.LoanPurchasesGet> = {
  method: 'get',
  name: 'loanPurchasesGet',
  summary: `Get line of credit purchases`,
  path: '/people/{personId}/loans/{loanId}/purchases',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'purchases'],
}

export const loanPurchasesTimelineGet: OperationData<Types.LoanPurchasesTimelineGet> = {
  method: 'get',
  name: 'loanPurchasesTimelineGet',
  summary: `Get line of credit purchases timeline`,
  path: '/people/{personId}/loans/{loanId}/purchases-timeline',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'purchases-timeline'],
}

export const loanTransactionsGetAll: OperationData<Types.LoanTransactionsGetAll> = {
  method: 'get',
  name: 'loanTransactionsGetAll',
  summary: `Get transactions`,
  path: '/people/{personId}/loans/{loanId}/transactions',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'transactions'],
}

export const transactionsCreate: OperationData<Types.TransactionsCreate> = {
  method: 'post',
  name: 'transactionsCreate',
  summary: `Create transaction`,
  path: '/people/{personId}/loans/{loanId}/transactions',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'transactions'],
}

export const transactionsGetById: OperationData<Types.TransactionsGetById> = {
  method: 'get',
  name: 'transactionsGetById',
  summary: `Get transaction by ID`,
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}',
  queryKey: ({ personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
  ],
}

export const transactionsUpdate: OperationData<Types.TransactionsUpdate> = {
  method: 'put',
  name: 'transactionsUpdate',
  summary: `Update transaction`,
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}',
  queryKey: ({ personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
  ],
}

export const transactionCancel: OperationData<Types.TransactionCancel> = {
  method: 'post',
  name: 'transactionCancel',
  summary: `Cancel transaction`,
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}/cancel',
  queryKey: ({ personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'cancel',
  ],
}

export const transactionReverse: OperationData<Types.TransactionReverse> = {
  method: 'post',
  name: 'transactionReverse',
  summary: `Reverse transaction`,
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}/reverse',
  queryKey: ({ personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'reverse',
  ],
}

export const getChargebacks: OperationData<Types.GetChargebacks> = {
  method: 'get',
  name: 'getChargebacks',
  summary: `Get transaction chargebacks`,
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}/chargebacks',
  queryKey: ({ personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
  ],
}

export const transactionChargeback: OperationData<Types.TransactionChargeback> = {
  method: 'post',
  name: 'transactionChargeback',
  summary: `Create transaction chargeback`,
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}/chargebacks',
  queryKey: ({ personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
  ],
}

export const getChargebackById: OperationData<Types.GetChargebackById> = {
  method: 'get',
  name: 'getChargebackById',
  summary: `Get chargeback by ID`,
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}/chargebacks/{chargebackId}',
  queryKey: ({ personId, loanId, transactionId, chargebackId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
    chargebackId,
  ],
}

export const updateChargeback: OperationData<Types.UpdateChargeback> = {
  method: 'put',
  name: 'updateChargeback',
  summary: `Update chargeback`,
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}/chargebacks/{chargebackId}',
  queryKey: ({ personId, loanId, transactionId, chargebackId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
    chargebackId,
  ],
}

export const transactionBackdate: OperationData<Types.TransactionBackdate> = {
  method: 'post',
  name: 'transactionBackdate',
  summary: `Backdate transaction`,
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}/backdate',
  queryKey: ({ personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'backdate',
  ],
}

export const getStatements: OperationData<Types.GetStatements> = {
  method: 'get',
  name: 'getStatements',
  summary: `Get statements`,
  path: '/people/{personId}/loans/{loanId}/statements',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'statements'],
}

export const updateStatement: OperationData<Types.UpdateStatement> = {
  method: 'put',
  name: 'updateStatement',
  summary: `Update statement`,
  path: '/people/{personId}/loans/{loanId}/statements/{statementId}',
  queryKey: ({ personId, loanId, statementId }) => ['people', personId, 'loans', loanId, 'statements', statementId],
}

export const getStatementDetails: OperationData<Types.GetStatementDetails> = {
  method: 'get',
  name: 'getStatementDetails',
  summary: `Get statement details`,
  path: '/people/{personId}/loans/{loanId}/statements/{statementId}/details',
  queryKey: ({ personId, loanId, statementId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'statements',
    statementId,
    'details',
  ],
}

export const generateStatement: OperationData<Types.GenerateStatement> = {
  method: 'post',
  name: 'generateStatement',
  summary: `Generate statement`,
  path: '/people/{personId}/loans/{loanId}/periods/{periodId}/generate-statement',
  queryKey: ({ personId, loanId, periodId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'periods',
    periodId,
    'generate-statement',
  ],
}

export const loanAutopayGet: OperationData<Types.LoanAutopayGet> = {
  method: 'get',
  name: 'loanAutopayGet',
  summary: `Get Autopay plan`,
  path: '/people/{personId}/loans/{loanId}/autopay',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'autopay'],
}

export const loanAutopayPut: OperationData<Types.LoanAutopayPut> = {
  method: 'put',
  name: 'loanAutopayPut',
  summary: `Update Autopay plan`,
  path: '/people/{personId}/loans/{loanId}/autopay',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'autopay'],
}

export const loanAutopayPost: OperationData<Types.LoanAutopayPost> = {
  method: 'post',
  name: 'loanAutopayPost',
  summary: `Create Autopay plan`,
  path: '/people/{personId}/loans/{loanId}/autopay',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'autopay'],
}

export const loanAutopayDelete: OperationData<Types.LoanAutopayDelete> = {
  method: 'delete',
  name: 'loanAutopayDelete',
  summary: `Cancel Autopay plan`,
  path: '/people/{personId}/loans/{loanId}/autopay',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'autopay'],
}

export const loanInterestGet: OperationData<Types.LoanInterestGet> = {
  method: 'get',
  name: 'loanInterestGet',
  summary: `Get loan interest`,
  path: '/people/{personId}/loans/{loanId}/interest',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'interest'],
}

export const loanBalancesGet: OperationData<Types.LoanBalancesGet> = {
  method: 'get',
  name: 'loanBalancesGet',
  summary: `Get loan balances`,
  path: '/people/{personId}/loans/{loanId}/balances',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'balances'],
}

export const loanFutureBalancesGet: OperationData<Types.LoanFutureBalancesGet> = {
  method: 'get',
  name: 'loanFutureBalancesGet',
  summary: `Get loan future balances`,
  path: '/people/{personId}/loans/{loanId}/future-balances',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'future-balances'],
}

export const drawBalancesGet: OperationData<Types.DrawBalancesGet> = {
  method: 'get',
  name: 'drawBalancesGet',
  summary: `Get draw balances`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/balances',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'balances'],
}

export const drawFutureBalancesGet: OperationData<Types.DrawFutureBalancesGet> = {
  method: 'get',
  name: 'drawFutureBalancesGet',
  summary: `Get draw future balances`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/future-balances',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'future-balances'],
}

export const loanInvestorsGetAll: OperationData<Types.LoanInvestorsGetAll> = {
  method: 'get',
  name: 'loanInvestorsGetAll',
  summary: `Get loan investors`,
  path: '/people/{personId}/loans/{loanId}/investors',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'investors'],
}

export const loanInvestorsUpdate: OperationData<Types.LoanInvestorsUpdate> = {
  method: 'put',
  name: 'loanInvestorsUpdate',
  summary: `Update loan investors`,
  path: '/people/{personId}/loans/{loanId}/investors',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'investors'],
}

export const personPaymentInstrumentGetAll: OperationData<Types.PersonPaymentInstrumentGetAll> = {
  method: 'get',
  name: 'personPaymentInstrumentGetAll',
  summary: `Get payment instruments`,
  path: '/people/{personId}/payment-instruments',
  queryKey: ({ personId }) => ['people', personId, 'payment-instruments'],
}

export const personPaymentInstrumentCreate: OperationData<Types.PersonPaymentInstrumentCreate> = {
  method: 'post',
  name: 'personPaymentInstrumentCreate',
  summary: `Create payment instrument`,
  path: '/people/{personId}/payment-instruments',
  queryKey: ({ personId }) => ['people', personId, 'payment-instruments'],
}

export const personPaymentInstrumentGet: OperationData<Types.PersonPaymentInstrumentGet> = {
  method: 'get',
  name: 'personPaymentInstrumentGet',
  summary: `Get payment instrument by ID`,
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}',
  queryKey: ({ personId, paymentInstrumentId }) => ['people', personId, 'payment-instruments', paymentInstrumentId],
}

export const personPaymentInstrumentUpdate: OperationData<Types.PersonPaymentInstrumentUpdate> = {
  method: 'put',
  name: 'personPaymentInstrumentUpdate',
  summary: `Update payment instrument`,
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}',
  queryKey: ({ personId, paymentInstrumentId }) => ['people', personId, 'payment-instruments', paymentInstrumentId],
}

export const personPaymentInstrumentDelete: OperationData<Types.PersonPaymentInstrumentDelete> = {
  method: 'delete',
  name: 'personPaymentInstrumentDelete',
  summary: `Delete payment instrument`,
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}',
  queryKey: ({ personId, paymentInstrumentId }) => ['people', personId, 'payment-instruments', paymentInstrumentId],
}

export const personPaymentInstrumentVerify: OperationData<Types.PersonPaymentInstrumentVerify> = {
  method: 'post',
  name: 'personPaymentInstrumentVerify',
  summary: `Verify amounts of microdeposits`,
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}/verify',
  queryKey: ({ personId, paymentInstrumentId }) => [
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'verify',
  ],
}

export const accountLinkPut: OperationData<Types.AccountLinkPut> = {
  method: 'put',
  name: 'accountLinkPut',
  summary: `Update account link`,
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}/account-link',
  queryKey: ({ personId, paymentInstrumentId }) => [
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'account-link',
  ],
}

export const accountLinkPost: OperationData<Types.AccountLinkPost> = {
  method: 'post',
  name: 'accountLinkPost',
  summary: `Create account link`,
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}/account-link',
  queryKey: ({ personId, paymentInstrumentId }) => [
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'account-link',
  ],
}

export const accountLinkDelete: OperationData<Types.AccountLinkDelete> = {
  method: 'delete',
  name: 'accountLinkDelete',
  summary: `Delete account link`,
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}/account-link',
  queryKey: ({ personId, paymentInstrumentId }) => [
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'account-link',
  ],
}

export const balancesGet: OperationData<Types.BalancesGet> = {
  method: 'get',
  name: 'balancesGet',
  summary: `Get balance data`,
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}/balance',
  queryKey: ({ personId, paymentInstrumentId }) => [
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'balance',
  ],
}

export const balancesPost: OperationData<Types.BalancesPost> = {
  method: 'post',
  name: 'balancesPost',
  summary: `Refresh balance data`,
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}/balance',
  queryKey: ({ personId, paymentInstrumentId }) => [
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'balance',
  ],
}

export const legalRepresentativeGetAll: OperationData<Types.LegalRepresentativeGetAll> = {
  method: 'get',
  name: 'legalRepresentativeGetAll',
  summary: `Get representatives`,
  path: '/people/{personId}/legal-representatives',
  queryKey: ({ personId }) => ['people', personId, 'legal-representatives'],
}

export const legalRepresentativeAdd: OperationData<Types.LegalRepresentativeAdd> = {
  method: 'post',
  name: 'legalRepresentativeAdd',
  summary: `Create representative`,
  path: '/people/{personId}/legal-representatives',
  queryKey: ({ personId }) => ['people', personId, 'legal-representatives'],
}

export const legalRepresentativeGetById: OperationData<Types.LegalRepresentativeGetById> = {
  method: 'get',
  name: 'legalRepresentativeGetById',
  summary: `Get representative by ID`,
  path: '/people/{personId}/legal-representatives/{legalRepresentativeId}',
  queryKey: ({ personId, legalRepresentativeId }) => [
    'people',
    personId,
    'legal-representatives',
    legalRepresentativeId,
  ],
}

export const legalRepresentativeUpdate: OperationData<Types.LegalRepresentativeUpdate> = {
  method: 'put',
  name: 'legalRepresentativeUpdate',
  summary: `Update representative`,
  path: '/people/{personId}/legal-representatives/{legalRepresentativeId}',
  queryKey: ({ personId, legalRepresentativeId }) => [
    'people',
    personId,
    'legal-representatives',
    legalRepresentativeId,
  ],
}

export const loanCreditreportingGet: OperationData<Types.LoanCreditreportingGet> = {
  method: 'get',
  name: 'loanCreditreportingGet',
  summary: `Get credit reporting by ID`,
  path: '/people/{personId}/loans/{loanId}/creditreportings/{creditReportingId}',
  queryKey: ({ personId, loanId, creditReportingId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'creditreportings',
    creditReportingId,
  ],
}

export const loanCreditreportingUpdate: OperationData<Types.LoanCreditreportingUpdate> = {
  method: 'put',
  name: 'loanCreditreportingUpdate',
  summary: `Update credit reporting`,
  path: '/people/{personId}/loans/{loanId}/creditreportings/{creditReportingId}',
  queryKey: ({ personId, loanId, creditReportingId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'creditreportings',
    creditReportingId,
  ],
}

export const loanCreditreportingDelete: OperationData<Types.LoanCreditreportingDelete> = {
  method: 'delete',
  name: 'loanCreditreportingDelete',
  summary: `Delete credit reporting`,
  path: '/people/{personId}/loans/{loanId}/creditreportings/{creditReportingId}',
  queryKey: ({ personId, loanId, creditReportingId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'creditreportings',
    creditReportingId,
  ],
}

export const loanCreditreportingsGet: OperationData<Types.LoanCreditreportingsGet> = {
  method: 'get',
  name: 'loanCreditreportingsGet',
  summary: `Get credit reportings`,
  path: '/people/{personId}/loans/{loanId}/creditreportings',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'creditreportings'],
}

export const loanCreditreportingsAdd: OperationData<Types.LoanCreditreportingsAdd> = {
  method: 'post',
  name: 'loanCreditreportingsAdd',
  summary: `Add credit reporting`,
  path: '/people/{personId}/loans/{loanId}/creditreportings',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'creditreportings'],
}

export const usersGet: OperationData<Types.UsersGet> = {
  method: 'get',
  name: 'usersGet',
  summary: `Get users`,
  path: '/users',
  queryKey: ({}) => ['users'],
}

export const userCreate: OperationData<Types.UserCreate> = {
  method: 'post',
  name: 'userCreate',
  summary: `Create user`,
  path: '/users',
  queryKey: ({}) => ['users'],
}

export const userGet: OperationData<Types.UserGet> = {
  method: 'get',
  name: 'userGet',
  summary: `Get user`,
  path: '/users/{userId}',
  queryKey: ({ userId }) => ['users', userId],
}

export const userUpdate: OperationData<Types.UserUpdate> = {
  method: 'put',
  name: 'userUpdate',
  summary: `Update user`,
  path: '/users/{userId}',
  queryKey: ({ userId }) => ['users', userId],
}

export const userRoleGet: OperationData<Types.UserRoleGet> = {
  method: 'get',
  name: 'userRoleGet',
  summary: `Get user roles`,
  path: '/users/{userId}/roles',
  queryKey: ({ userId }) => ['users', userId, 'roles'],
}

export const userRoleUpdate: OperationData<Types.UserRoleUpdate> = {
  method: 'put',
  name: 'userRoleUpdate',
  summary: `Add role to user`,
  path: '/users/{userId}/roles/{roleId}',
  queryKey: ({ userId, roleId }) => ['users', userId, 'roles', roleId],
}

export const userRoleDelete: OperationData<Types.UserRoleDelete> = {
  method: 'delete',
  name: 'userRoleDelete',
  summary: `Remove role from user`,
  path: '/users/{userId}/roles/{roleId}',
  queryKey: ({ userId, roleId }) => ['users', userId, 'roles', roleId],
}

export const userGetPermissions: OperationData<Types.UserGetPermissions> = {
  method: 'get',
  name: 'userGetPermissions',
  summary: `Get user's current permissions`,
  path: '/users/{userId}/permissions',
  queryKey: ({ userId }) => ['users', userId, 'permissions'],
}

export const getPaymentProcessors: OperationData<Types.GetPaymentProcessors> = {
  method: 'get',
  name: 'getPaymentProcessors',
  summary: `Get payment processors`,
  path: '/payment-processors',
  queryKey: ({}) => ['payment-processors'],
}

export const createPaymentProcessor: OperationData<Types.CreatePaymentProcessor> = {
  method: 'post',
  name: 'createPaymentProcessor',
  summary: `Create payment processor`,
  path: '/payment-processors',
  queryKey: ({}) => ['payment-processors'],
}

export const getOnePaymentProcessor: OperationData<Types.GetOnePaymentProcessor> = {
  method: 'get',
  name: 'getOnePaymentProcessor',
  summary: `Get payment processor`,
  path: '/payment-processors/{paymentProcessorId}',
  queryKey: ({ paymentProcessorId }) => ['payment-processors', paymentProcessorId],
}

export const updatePaymentProcessor: OperationData<Types.UpdatePaymentProcessor> = {
  method: 'put',
  name: 'updatePaymentProcessor',
  summary: `Update payment processor`,
  path: '/payment-processors/{paymentProcessorId}',
  queryKey: ({ paymentProcessorId }) => ['payment-processors', paymentProcessorId],
}

export const deletePaymentProcessor: OperationData<Types.DeletePaymentProcessor> = {
  method: 'delete',
  name: 'deletePaymentProcessor',
  summary: `Delete payment processor`,
  path: '/payment-processors/{paymentProcessorId}',
  queryKey: ({ paymentProcessorId }) => ['payment-processors', paymentProcessorId],
}

export const internalPromoProgramTypesGetAll: OperationData<Types.InternalPromoProgramTypesGetAll> = {
  method: 'get',
  name: 'internalPromoProgramTypesGetAll',
  summary: `Get promo program types`,
  path: '/companies/{companyId}/promo-program-types',
  queryKey: ({ companyId }) => ['companies', companyId, 'promo-program-types'],
}

export const internalPromoProgramTypesAdd: OperationData<Types.InternalPromoProgramTypesAdd> = {
  method: 'post',
  name: 'internalPromoProgramTypesAdd',
  summary: `Create promo program type`,
  path: '/companies/{companyId}/promo-program-types',
  queryKey: ({ companyId }) => ['companies', companyId, 'promo-program-types'],
}

export const internalPromoProgramTypesGet: OperationData<Types.InternalPromoProgramTypesGet> = {
  method: 'get',
  name: 'internalPromoProgramTypesGet',
  summary: `Get promo program type by ID`,
  path: '/companies/{companyId}/promo-program-types/{promoProgramTypeId}',
  queryKey: ({ companyId, promoProgramTypeId }) => ['companies', companyId, 'promo-program-types', promoProgramTypeId],
}

export const internalPromoProgramTypesUpdate: OperationData<Types.InternalPromoProgramTypesUpdate> = {
  method: 'put',
  name: 'internalPromoProgramTypesUpdate',
  summary: `Update promo program type`,
  path: '/companies/{companyId}/promo-program-types/{promoProgramTypeId}',
  queryKey: ({ companyId, promoProgramTypeId }) => ['companies', companyId, 'promo-program-types', promoProgramTypeId],
}

export const internalPromoProgramTypesDelete: OperationData<Types.InternalPromoProgramTypesDelete> = {
  method: 'delete',
  name: 'internalPromoProgramTypesDelete',
  summary: `Cancel promo program type`,
  path: '/companies/{companyId}/promo-program-types/{promoProgramTypeId}',
  queryKey: ({ companyId, promoProgramTypeId }) => ['companies', companyId, 'promo-program-types', promoProgramTypeId],
}

export const getAchFileContent: OperationData<Types.GetAchFileContent> = {
  method: 'get',
  name: 'getAchFileContent',
  summary: `Get ACH file content`,
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/ach-files/{achFileId}/content',
  queryKey: ({ companyId, paymentProcessorId, achFileId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'ach-files',
    achFileId,
    'content',
  ],
}

export const operationStatusGet: OperationData<Types.OperationStatusGet> = {
  method: 'get',
  name: 'operationStatusGet',
  summary: `Get operation status`,
  path: '/operations/{operationId}',
  queryKey: ({ operationId }) => ['operations', operationId],
}
