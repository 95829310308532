import {
  CLEAR_DOCUMENT_DESCRIPTORS,
  CREATE_DOCUMENT_DESCRIPTORS,
  GET_DOCUMENT_DESCRIPTORS,
  UPDATE_DOCUMENT_DESCRIPTOR,
} from 'core/actions/constants'

/**
 * The byId object is designated to document descriptors.
 */
const initialState = {
  byId: {},
  allIds: [],
}

const documents = function (state = initialState, action) {
  switch (action.type) {
    case CREATE_DOCUMENT_DESCRIPTORS:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.payload.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.id]: {
                ...curr,
                personId: action.personId,
              },
            }),
            {},
          ),
        },
      }
    case GET_DOCUMENT_DESCRIPTORS:
      const newById = {
        // keep all existing document descriptors for other personIds
        ...Object.values(state.byId).reduce((acc, curr) => {
          if (curr.personId !== action.personId) {
            acc[curr.id] = curr
          }
          return acc
        }, {}),
        // insert the fresh document descriptors for this personId
        ...action.payload.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: {
              ...curr,
              personId: action.personId,
              uploadedByUserType: action.uploadedByUserType,
            },
          }),
          {},
        ),
      }
      const allIds = Object.keys(newById).map((d) => d)
      return {
        ...state,
        byId: newById,
        allIds,
      }
    case UPDATE_DOCUMENT_DESCRIPTOR:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            ...action.payload,
          },
        },
      }
    case CLEAR_DOCUMENT_DESCRIPTORS:
      return {
        ...state,
        byId: {},
      }

    default:
      return state
  }
}

export default documents
