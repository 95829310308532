/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const loanLabelsList: OperationData<Types.LoanLabelsList> = {
  method: 'get',
  name: 'loanLabelsList',
  summary: `Get loan labels`,
  path: '/companies/{companyId}/loan-labels',
  queryKey: ({ companyId }) => ['companies', companyId, 'loan-labels'],
}

export const loanLabelCreate: OperationData<Types.LoanLabelCreate> = {
  method: 'post',
  name: 'loanLabelCreate',
  summary: `Create loan label`,
  path: '/companies/{companyId}/loan-labels',
  queryKey: ({ companyId }) => ['companies', companyId, 'loan-labels'],
}

export const loanLabelGet: OperationData<Types.LoanLabelGet> = {
  method: 'get',
  name: 'loanLabelGet',
  summary: `Get loan label by ID`,
  path: '/companies/{companyId}/loan-labels/{loanLabelId}',
  queryKey: ({ companyId, loanLabelId }) => ['companies', companyId, 'loan-labels', loanLabelId],
}

export const loanLabelUpdate: OperationData<Types.LoanLabelUpdate> = {
  method: 'put',
  name: 'loanLabelUpdate',
  summary: `Update loan label`,
  path: '/companies/{companyId}/loan-labels/{loanLabelId}',
  queryKey: ({ companyId, loanLabelId }) => ['companies', companyId, 'loan-labels', loanLabelId],
}

export const loanLabelDelete: OperationData<Types.LoanLabelDelete> = {
  method: 'delete',
  name: 'loanLabelDelete',
  summary: `Delete loan label`,
  path: '/companies/{companyId}/loan-labels/{loanLabelId}',
  queryKey: ({ companyId, loanLabelId }) => ['companies', companyId, 'loan-labels', loanLabelId],
}

export const loanLabelsGetLoans: OperationData<Types.LoanLabelsGetLoans> = {
  method: 'get',
  name: 'loanLabelsGetLoans',
  summary: `Get loans by loan labels`,
  path: '/companies/{companyId}/loan-labels/{loanLabelIds}/loans',
  queryKey: ({ companyId, loanLabelIds }) => ['companies', companyId, 'loan-labels', loanLabelIds, 'loans'],
}

export const loanLabelsAddLoans: OperationData<Types.LoanLabelsAddLoans> = {
  method: 'put',
  name: 'loanLabelsAddLoans',
  summary: `Add labels to loans`,
  path: '/companies/{companyId}/loan-labels/{loanLabelIds}/loans',
  queryKey: ({ companyId, loanLabelIds }) => ['companies', companyId, 'loan-labels', loanLabelIds, 'loans'],
}

export const loanLabelsRemoveLoans: OperationData<Types.LoanLabelsRemoveLoans> = {
  method: 'put',
  name: 'loanLabelsRemoveLoans',
  summary: `Remove labels from loans`,
  path: '/companies/{companyId}/loan-labels/{loanLabelIds}/loans/remove',
  queryKey: ({ companyId, loanLabelIds }) => ['companies', companyId, 'loan-labels', loanLabelIds, 'loans', 'remove'],
}
