import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'

import rootReducers from 'core/reducers'

import crmReducers from 'crm/crmReducers'

const isDev = import.meta.env.VITE_ENV_LABEL === 'local'
// TODO: Incrementally move to domain-specific nested reducers
// * state specific to "admin", "crm", and "loan" applications should live in nested reducers
// * depending on how much global state we actually need, there should either be a "global" reducer or just "auth" reducer

export const rootReducer = combineReducers({
  ...rootReducers,
  crm: crmReducers,
})

const store = configureStore({
  middleware: (getDefaultMiddleware) => {
    if (isDev) {
      // Includes redux-thunk and middlewares to detect issues with reducers
      // TODO: immutableCheck: false should be removed here
      // A fix is required in src/core/hooks/useEntity.js to restore this check
      return getDefaultMiddleware({ immutableCheck: false }).concat(createLogger({ collapsed: true }))
    }
    // Includes only redux-thunk in production
    return getDefaultMiddleware()
  },
  reducer: rootReducer,
  devTools: isDev,
})

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
