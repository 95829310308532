export const CURRENCY_SYMBOL = {
  USD: '$',
}

// takes in a number and optional type
// returns a properly formatted currency value with symbol
export default function formatCurrency(num?: number, type: keyof typeof CURRENCY_SYMBOL = 'USD') {
  if (num === undefined || !Number.isFinite(num)) return

  const unit = CURRENCY_SYMBOL[type] ?? CURRENCY_SYMBOL['USD']

  if (num === 0) return `${unit}0.00`

  const sign = num < 0 ? '-' : ''
  const value = Math.abs(num)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return `${sign}${unit}${value}`
}
