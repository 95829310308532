import isValidColor from 'core/helpers/isValidColor'
import variables from 'core/styles/variables'

const USER_TYPE_BY_APP_DOMAIN_TYPE = {
  agent: 'agent',
  borrower: 'borrower',
  admin: 'agent',
}

/** peachy colors */
const themeDefaults = {
  primary: variables.colorBluePrimary,
  secondary: variables.colorBlueSecondary,
  tertiary: variables.colorBlueTertiary,
  lighten: variables.colorBlueLighten,
}

const formatTheme = (colors = {}) => {
  // handle previous deeply nested config setup.
  // can remove once that is fully not in use
  const $colors = colors.theme ? colors.theme : colors

  const { primary, secondary, tertiary, lighten } = $colors

  return {
    primary: isValidColor(primary) ? primary : themeDefaults.primary,
    secondary: isValidColor(secondary) ? secondary : themeDefaults.secondary,
    tertiary: isValidColor(tertiary) ? tertiary : themeDefaults.tertiary,
    lighten: isValidColor(lighten) ? lighten : themeDefaults.lighten,
  }
}

const parseBoostrapInfo = (bootstrapData) => {
  const {
    appDomainType,
    authType,
    authValueType,
    company,
    ssoLoginUrl: ssoUrl,
    userType: legacyUserType,
  } = bootstrapData || {}

  const { id: companyId, timezone, brandAssets } = company || {}

  const { colors } = brandAssets || {}

  const userType = USER_TYPE_BY_APP_DOMAIN_TYPE[appDomainType] || legacyUserType || 'borrower'

  return {
    appDomainType,
    authType,
    authValueType,
    colors,
    company,
    companyId,
    ssoUrl,
    timezone,
    userType,
  }
}

export { parseBoostrapInfo, formatTheme }
