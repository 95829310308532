import React from 'react'

import PropTypes from 'prop-types'

import TitleWithEducationTip from 'core/components/lib/TitleWithEducationTip'

import { FormContextProvider } from 'loan/components/actionCards/shared/DisabledContext'

import InfoCardBottomCap from './InfoCardBottomCap'
import InfoCardContainer from './InfoCardContainer'

const InfoCard = (props) => {
  const {
    agent,
    borderless,
    children,
    completeUrl,
    customClassName,
    disabled,
    ellipsizeTitle,
    errorMessage,
    helpText,
    helpTextMargin,
    helpTitle,
    iconMargin,
    onComplete,
    removeHelpTextTitle,
    status,
    statusIcon,
    style,
    subTitle,
    successMessage,
    title,
  } = props

  const bottomCap = status && (
    <InfoCardBottomCap
      status={status}
      successMessage={successMessage ?? 'Action complete'}
      errorMessage={errorMessage}
      completeUrl={completeUrl}
      onComplete={onComplete}
    />
  )

  return (
    <FormContextProvider disabled={disabled}>
      <InfoCardContainer
        customClassName={customClassName}
        style={style}
        borderless={borderless}
        agent={agent}
        bottomCap={bottomCap}
      >
        <TitleWithEducationTip
          title={title}
          subTitle={subTitle}
          helpText={helpText}
          helpTextMargin={helpTextMargin}
          removeHelpTextTitle={removeHelpTextTitle}
          iconMargin={iconMargin}
          statusIcon={statusIcon}
          ellipsize={ellipsizeTitle}
          helpTitle={helpTitle ?? 'Agent Education:'}
        />

        {children}
      </InfoCardContainer>
    </FormContextProvider>
  )
}

InfoCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subTitle: PropTypes.string,
  customClassName: PropTypes.string,
  borderless: PropTypes.bool,
  style: PropTypes.object,
  /** If true, the card is styled for agent-only view */
  agent: PropTypes.bool,
  /** If included, there will be a help tooltip next
   * to the title with this text in it. If no title is
   * provided, there is no help tooltip.
   */
  ellipsizeTitle: PropTypes.bool,
  helpText: PropTypes.node,
  helpTextMargin: PropTypes.string,
  removeHelpTextTitle: PropTypes.bool,
  iconMargin: PropTypes.string,
  /** The parent component can pass the loading status stored
   * in redux for the api call here to show a message at the
   * bottom of the InfoCard.
   */
  status: PropTypes.oneOf(['success', 'loading', 'error']),
  /** The message that should be displayed at the bottom of
   * the InfoCard when `status` is set to "success".
   */
  successMessage: PropTypes.node,
  errorMessage: PropTypes.node,
  completeUrl: PropTypes.string,
  onComplete: PropTypes.func,
  statusIcon: PropTypes.node,
  disabled: PropTypes.bool,
  helpTitle: PropTypes.string,
}

export default InfoCard
