import { checkTimeordate } from '../types'
import mtz from '../utils/mtz'

const allowableUnits = ['milliseconds', 'seconds', 'minutes', 'hours', 'days', 'months', 'years']

const makeDifference = (context = {}) => {
  const { timezone } = context

  const difference = (later, earlier, timeUnit) => {
    checkTimeordate(later)
    checkTimeordate(earlier)

    let unit = 'milliseconds'
    if (timeUnit && allowableUnits.includes(timeUnit)) {
      unit = timeUnit
    } else if (timeUnit) {
      throw Error(`timeUnit "${timeUnit}" not allowed`)
    }

    const laterMoment = mtz(later, timezone)
    const earlierMoment = mtz(earlier, timezone)
    return laterMoment.diff(earlierMoment, unit)
  }

  return difference
}

export default makeDifference
