/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const listTemplateDescriptors: OperationData<Types.ListTemplateDescriptors> = {
  method: 'get',
  name: 'listTemplateDescriptors',
  summary: `List template descriptors`,
  path: '/communicator/templatedescriptors',
  queryKey: ({}) => ['communicator', 'templatedescriptors'],
}

export const createTemplateDescriptor: OperationData<Types.CreateTemplateDescriptor> = {
  method: 'post',
  name: 'createTemplateDescriptor',
  summary: `Create template descriptor`,
  path: '/communicator/templatedescriptors',
  queryKey: ({}) => ['communicator', 'templatedescriptors'],
}

export const getTemplateDescriptor: OperationData<Types.GetTemplateDescriptor> = {
  method: 'get',
  name: 'getTemplateDescriptor',
  summary: `Get template descriptor`,
  path: '/communicator/templatedescriptors/{descriptorId}',
  queryKey: ({ descriptorId }) => ['communicator', 'templatedescriptors', descriptorId],
}

export const updateTemplateDescriptor: OperationData<Types.UpdateTemplateDescriptor> = {
  method: 'put',
  name: 'updateTemplateDescriptor',
  summary: `Update template descriptor`,
  path: '/communicator/templatedescriptors/{descriptorId}',
  queryKey: ({ descriptorId }) => ['communicator', 'templatedescriptors', descriptorId],
}

export const deleteTemplateDescriptor: OperationData<Types.DeleteTemplateDescriptor> = {
  method: 'delete',
  name: 'deleteTemplateDescriptor',
  summary: `Delete template descriptor`,
  path: '/communicator/templatedescriptors/{descriptorId}',
  queryKey: ({ descriptorId }) => ['communicator', 'templatedescriptors', descriptorId],
}

export const listTemplateVersionsByDescriptor: OperationData<Types.ListTemplateVersionsByDescriptor> = {
  method: 'get',
  name: 'listTemplateVersionsByDescriptor',
  summary: `List template versions for descriptor`,
  path: '/communicator/templatedescriptors/{descriptorId}/templates',
  queryKey: ({ descriptorId }) => ['communicator', 'templatedescriptors', descriptorId, 'templates'],
}

export const listTemplateVersions: OperationData<Types.ListTemplateVersions> = {
  method: 'get',
  name: 'listTemplateVersions',
  summary: `List all template versions (Deprecated)`,
  path: '/communicator/templates',
  queryKey: ({}) => ['communicator', 'templates'],
}

export const createTemplateVersion: OperationData<Types.CreateTemplateVersion> = {
  method: 'post',
  name: 'createTemplateVersion',
  summary: `Create template version`,
  path: '/communicator/templates',
  queryKey: ({}) => ['communicator', 'templates'],
}

export const listTemplateVersionsModern: OperationData<Types.ListTemplateVersionsModern> = {
  method: 'get',
  name: 'listTemplateVersionsModern',
  summary: `List all template versions (v2)`,
  path: '/v2/communicator/templates',
  queryKey: ({}) => ['v2', 'communicator', 'templates'],
}

export const getTemplateVersion: OperationData<Types.GetTemplateVersion> = {
  method: 'get',
  name: 'getTemplateVersion',
  summary: `Get template version`,
  path: '/communicator/templates/{templateVersionId}',
  queryKey: ({ templateVersionId }) => ['communicator', 'templates', templateVersionId],
}

export const updateTemplateVersion: OperationData<Types.UpdateTemplateVersion> = {
  method: 'put',
  name: 'updateTemplateVersion',
  summary: `Update template version`,
  path: '/communicator/templates/{templateVersionId}',
  queryKey: ({ templateVersionId }) => ['communicator', 'templates', templateVersionId],
}

export const deleteTemplateVersion: OperationData<Types.DeleteTemplateVersion> = {
  method: 'delete',
  name: 'deleteTemplateVersion',
  summary: `Delete template version`,
  path: '/communicator/templates/{templateVersionId}',
  queryKey: ({ templateVersionId }) => ['communicator', 'templates', templateVersionId],
}

export const activateTemplateVersion: OperationData<Types.ActivateTemplateVersion> = {
  method: 'put',
  name: 'activateTemplateVersion',
  summary: `Activate template version`,
  path: '/communicator/templates/{templateVersionId}/activate',
  queryKey: ({ templateVersionId }) => ['communicator', 'templates', templateVersionId, 'activate'],
}

export const previewTemplateVersion: OperationData<Types.PreviewTemplateVersion> = {
  method: 'post',
  name: 'previewTemplateVersion',
  summary: `Render template version preview`,
  path: '/communicator/templates/{templateVersionId}/preview',
  queryKey: ({ templateVersionId }) => ['communicator', 'templates', templateVersionId, 'preview'],
}

export const renderTemplateVersion: OperationData<Types.RenderTemplateVersion> = {
  method: 'post',
  name: 'renderTemplateVersion',
  summary: `Render template version`,
  path: '/communicator/templates/{templateVersionId}/render',
  queryKey: ({ templateVersionId }) => ['communicator', 'templates', templateVersionId, 'render'],
}

export const getTemplateVars: OperationData<Types.GetTemplateVars> = {
  method: 'get',
  name: 'getTemplateVars',
  summary: `List context vars for template version`,
  path: '/communicator/templates/{templateVersionId}/vars',
  queryKey: ({ templateVersionId }) => ['communicator', 'templates', templateVersionId, 'vars'],
}

export const exportTemplates: OperationData<Types.ExportTemplates> = {
  method: 'get',
  name: 'exportTemplates',
  summary: `Export all template descriptors and versions`,
  path: '/communicator/templates/export',
  queryKey: ({}) => ['communicator', 'templates', 'export'],
}

export const importTemplates: OperationData<Types.ImportTemplates> = {
  method: 'post',
  name: 'importTemplates',
  summary: `Import exported template descriptors and versions`,
  path: '/communicator/templates/import',
  queryKey: ({}) => ['communicator', 'templates', 'import'],
}

export const handleSend: OperationData<Types.HandleSend> = {
  method: 'post',
  name: 'handleSend',
  summary: `Send message`,
  path: '/communicator/send',
  queryKey: ({}) => ['communicator', 'send'],
}

export const handleReceive: OperationData<Types.HandleReceive> = {
  method: 'post',
  name: 'handleReceive',
  summary: `Receive message`,
  path: '/communicator/receive',
  queryKey: ({}) => ['communicator', 'receive'],
}

export const resend: OperationData<Types.Resend> = {
  method: 'post',
  name: 'resend',
  summary: `Resend existing interaction`,
  path: '/communicator/resend',
  queryKey: ({}) => ['communicator', 'resend'],
}

export const render: OperationData<Types.Render> = {
  method: 'post',
  name: 'render',
  summary: `Render to download`,
  path: '/communicator/render',
  queryKey: ({}) => ['communicator', 'render'],
}

export const renderToDocument: OperationData<Types.RenderToDocument> = {
  method: 'post',
  name: 'renderToDocument',
  summary: `Render to document`,
  path: '/communicator/render-to-document',
  queryKey: ({}) => ['communicator', 'render-to-document'],
}

export const preview: OperationData<Types.Preview> = {
  method: 'post',
  name: 'preview',
  summary: `Render template preview for subject`,
  path: '/communicator/preview',
  queryKey: ({}) => ['communicator', 'preview'],
}

export const sendFreeFormEmail: OperationData<Types.SendFreeFormEmail> = {
  method: 'post',
  name: 'sendFreeFormEmail',
  summary: `Send free-form email`,
  path: '/communicator/send-free-form-email',
  queryKey: ({}) => ['communicator', 'send-free-form-email'],
}

export const previewFreeFormEmail: OperationData<Types.PreviewFreeFormEmail> = {
  method: 'post',
  name: 'previewFreeFormEmail',
  summary: `Preview free-form email`,
  path: '/communicator/preview-free-form-email',
  queryKey: ({}) => ['communicator', 'preview-free-form-email'],
}

export const sendConfirmationCode: OperationData<Types.SendConfirmationCode> = {
  method: 'post',
  name: 'sendConfirmationCode',
  summary: `Send confirmation code for contact verification`,
  path: '/communicator/send-confirmation-code',
  queryKey: ({}) => ['communicator', 'send-confirmation-code'],
}

export const subjectDescriptorsList: OperationData<Types.SubjectDescriptorsList> = {
  method: 'get',
  name: 'subjectDescriptorsList',
  summary: `List subject desciptors`,
  path: '/communicator/subjects',
  queryKey: ({}) => ['communicator', 'subjects'],
}

export const subjectDescriptorGetBySubject: OperationData<Types.SubjectDescriptorGetBySubject> = {
  method: 'get',
  name: 'subjectDescriptorGetBySubject',
  summary: `Get subject descriptor`,
  path: '/communicator/subjects/{subject}',
  queryKey: ({ subject }) => ['communicator', 'subjects', subject],
}

export const subjectDescriptorUpdate: OperationData<Types.SubjectDescriptorUpdate> = {
  method: 'put',
  name: 'subjectDescriptorUpdate',
  summary: `Update subject descriptor`,
  path: '/communicator/subjects/{subject}',
  queryKey: ({ subject }) => ['communicator', 'subjects', subject],
}

export const internalListTemplateDescriptors: OperationData<Types.InternalListTemplateDescriptors> = {
  method: 'get',
  name: 'internalListTemplateDescriptors',
  summary: `List template descriptors`,
  path: '/companies/{companyId}/communicator/templatedescriptors',
  queryKey: ({ companyId }) => ['companies', companyId, 'communicator', 'templatedescriptors'],
}

export const internalCreateTemplateDescriptor: OperationData<Types.InternalCreateTemplateDescriptor> = {
  method: 'post',
  name: 'internalCreateTemplateDescriptor',
  summary: `Create template descriptor`,
  path: '/companies/{companyId}/communicator/templatedescriptors',
  queryKey: ({ companyId }) => ['companies', companyId, 'communicator', 'templatedescriptors'],
}

export const internalGetTemplateDescriptor: OperationData<Types.InternalGetTemplateDescriptor> = {
  method: 'get',
  name: 'internalGetTemplateDescriptor',
  summary: `Get template descriptor`,
  path: '/companies/{companyId}/communicator/templatedescriptors/{descriptorId}',
  queryKey: ({ companyId, descriptorId }) => [
    'companies',
    companyId,
    'communicator',
    'templatedescriptors',
    descriptorId,
  ],
}

export const internalUpdateTemplateDescriptor: OperationData<Types.InternalUpdateTemplateDescriptor> = {
  method: 'put',
  name: 'internalUpdateTemplateDescriptor',
  summary: `Update template descriptor`,
  path: '/companies/{companyId}/communicator/templatedescriptors/{descriptorId}',
  queryKey: ({ companyId, descriptorId }) => [
    'companies',
    companyId,
    'communicator',
    'templatedescriptors',
    descriptorId,
  ],
}

export const internalDeleteTemplateDescriptor: OperationData<Types.InternalDeleteTemplateDescriptor> = {
  method: 'delete',
  name: 'internalDeleteTemplateDescriptor',
  summary: `Delete template descriptor`,
  path: '/companies/{companyId}/communicator/templatedescriptors/{descriptorId}',
  queryKey: ({ companyId, descriptorId }) => [
    'companies',
    companyId,
    'communicator',
    'templatedescriptors',
    descriptorId,
  ],
}

export const internalListTemplateVersionsByDescriptor: OperationData<Types.InternalListTemplateVersionsByDescriptor> = {
  method: 'get',
  name: 'internalListTemplateVersionsByDescriptor',
  summary: `List templates versions for descriptor`,
  path: '/companies/{companyId}/communicator/templatedescriptors/{descriptorId}/templates',
  queryKey: ({ companyId, descriptorId }) => [
    'companies',
    companyId,
    'communicator',
    'templatedescriptors',
    descriptorId,
    'templates',
  ],
}

export const internalListTemplateVersions: OperationData<Types.InternalListTemplateVersions> = {
  method: 'get',
  name: 'internalListTemplateVersions',
  summary: `List company templates`,
  path: '/companies/{companyId}/communicator/templates',
  queryKey: ({ companyId }) => ['companies', companyId, 'communicator', 'templates'],
}

export const internalCreateTemplateVersion: OperationData<Types.InternalCreateTemplateVersion> = {
  method: 'post',
  name: 'internalCreateTemplateVersion',
  summary: `Create template`,
  path: '/companies/{companyId}/communicator/templates',
  queryKey: ({ companyId }) => ['companies', companyId, 'communicator', 'templates'],
}

export const internalListTemplateVersionsModern: OperationData<Types.InternalListTemplateVersionsModern> = {
  method: 'get',
  name: 'internalListTemplateVersionsModern',
  summary: `List company templates (v2)`,
  path: '/v2/companies/{companyId}/communicator/templates',
  queryKey: ({ companyId }) => ['v2', 'companies', companyId, 'communicator', 'templates'],
}

export const internalGetTemplateVersion: OperationData<Types.InternalGetTemplateVersion> = {
  method: 'get',
  name: 'internalGetTemplateVersion',
  summary: `Get template version`,
  path: '/companies/{companyId}/communicator/templates/{templateVersionId}',
  queryKey: ({ companyId, templateVersionId }) => [
    'companies',
    companyId,
    'communicator',
    'templates',
    templateVersionId,
  ],
}

export const internalUpdateTemplateVersion: OperationData<Types.InternalUpdateTemplateVersion> = {
  method: 'put',
  name: 'internalUpdateTemplateVersion',
  summary: `Update template version`,
  path: '/companies/{companyId}/communicator/templates/{templateVersionId}',
  queryKey: ({ companyId, templateVersionId }) => [
    'companies',
    companyId,
    'communicator',
    'templates',
    templateVersionId,
  ],
}

export const internalDeleteTemplateVersion: OperationData<Types.InternalDeleteTemplateVersion> = {
  method: 'delete',
  name: 'internalDeleteTemplateVersion',
  summary: `Delete template version`,
  path: '/companies/{companyId}/communicator/templates/{templateVersionId}',
  queryKey: ({ companyId, templateVersionId }) => [
    'companies',
    companyId,
    'communicator',
    'templates',
    templateVersionId,
  ],
}

export const internalActivateTemplateVersion: OperationData<Types.InternalActivateTemplateVersion> = {
  method: 'put',
  name: 'internalActivateTemplateVersion',
  summary: `Activate template version`,
  path: '/companies/{companyId}/communicator/templates/{templateVersionId}/activate',
  queryKey: ({ companyId, templateVersionId }) => [
    'companies',
    companyId,
    'communicator',
    'templates',
    templateVersionId,
    'activate',
  ],
}

export const internalExportTemplates: OperationData<Types.InternalExportTemplates> = {
  method: 'get',
  name: 'internalExportTemplates',
  summary: `Export all template descriptors and versions`,
  path: '/companies/{companyId}/communicator/templates/export',
  queryKey: ({ companyId }) => ['companies', companyId, 'communicator', 'templates', 'export'],
}

export const internalImportTemplates: OperationData<Types.InternalImportTemplates> = {
  method: 'post',
  name: 'internalImportTemplates',
  summary: `Import exported template descriptors and versions`,
  path: '/companies/{companyId}/communicator/templates/import',
  queryKey: ({ companyId }) => ['companies', companyId, 'communicator', 'templates', 'import'],
}

export const internalHandleSend: OperationData<Types.InternalHandleSend> = {
  method: 'post',
  name: 'internalHandleSend',
  summary: `Send message`,
  path: '/companies/{companyId}/communicator/send',
  queryKey: ({ companyId }) => ['companies', companyId, 'communicator', 'send'],
}

export const sendTemplatedEmailHandler: OperationData<Types.SendTemplatedEmailHandler> = {
  method: 'post',
  name: 'sendTemplatedEmailHandler',
  summary: `Send templated emails`,
  path: '/companies/{companyId}/communicator/send-templated-email',
  queryKey: ({ companyId }) => ['companies', companyId, 'communicator', 'send-templated-email'],
}

export const internalHandleReceive: OperationData<Types.InternalHandleReceive> = {
  method: 'post',
  name: 'internalHandleReceive',
  summary: `Receive message`,
  path: '/companies/{companyId}/communicator/receive',
  queryKey: ({ companyId }) => ['companies', companyId, 'communicator', 'receive'],
}

export const internalRender: OperationData<Types.InternalRender> = {
  method: 'post',
  name: 'internalRender',
  summary: `Render message`,
  path: '/companies/{companyId}/communicator/render',
  queryKey: ({ companyId }) => ['companies', companyId, 'communicator', 'render'],
}

export const internalRenderToDocument: OperationData<Types.InternalRenderToDocument> = {
  method: 'post',
  name: 'internalRenderToDocument',
  summary: `Render to document`,
  path: '/companies/{companyId}/communicator/render-to-document',
  queryKey: ({ companyId }) => ['companies', companyId, 'communicator', 'render-to-document'],
}

export const internalSubjectDescriptorsList: OperationData<Types.InternalSubjectDescriptorsList> = {
  method: 'get',
  name: 'internalSubjectDescriptorsList',
  summary: `List subject descriptor`,
  path: '/companies/{companyId}/communicator/subjects',
  queryKey: ({ companyId }) => ['companies', companyId, 'communicator', 'subjects'],
}

export const internalSubjectDescriptorGetBySubject: OperationData<Types.InternalSubjectDescriptorGetBySubject> = {
  method: 'get',
  name: 'internalSubjectDescriptorGetBySubject',
  summary: `Get subject descriptor`,
  path: '/companies/{companyId}/communicator/subjects/{subject}',
  queryKey: ({ companyId, subject }) => ['companies', companyId, 'communicator', 'subjects', subject],
}

export const internalSubjectDescriptorUpdate: OperationData<Types.InternalSubjectDescriptorUpdate> = {
  method: 'put',
  name: 'internalSubjectDescriptorUpdate',
  summary: `Update subject descriptor`,
  path: '/companies/{companyId}/communicator/subjects/{subject}',
  queryKey: ({ companyId, subject }) => ['companies', companyId, 'communicator', 'subjects', subject],
}
