import React from 'react'

import PropTypes from 'prop-types'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import EnvVariables from './EnvVariables'

const EnvProviders = ({ children }) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/env'>
          <EnvVariables />
        </Route>
        <Route>{children}</Route>
      </Switch>
    </BrowserRouter>
  )
}

EnvProviders.propTypes = {
  children: PropTypes.node,
}

export default EnvProviders
