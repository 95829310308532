import mtz from '../utils/mtz'

const makeParse = (context = {}) => {
  const { timezone } = context

  const parse = (timeordate, inputFormat) => {
    const parsedTimeordate = mtz(timeordate, inputFormat, timezone).format()
    if (parsedTimeordate === 'Invalid date') throw Error('Invalid date. Not able to parse time or date.')
    return parsedTimeordate
  }

  return parse
}

export default makeParse
