import styled from 'styled-components'

import { PoweredByPeach } from 'core/components'
import { useCompanyCopies, useCompanyInfo } from 'core/hooks'
import variables from 'core/styles/variables'

// this is styling using old school CSS
// so that it will pick  up anything custom links supplied
// for a company
const Wrapper = styled.div`
  color: ${variables.colorBlack70};

  a {
    color: ${variables.colorBluePrimary};

    &:hover {
      text-decoration: underline;
    }
  }
`

const Link = styled.a``

const termsLink = 'https://www.peachfinance.com/terms-conditions.html'

const privacyPolicyLink = 'https://www.peachfinance.com/privacy-policy.html'

const privacyNoticeLink = 'https://www.peachfinance.com/documents/Privacy-Notice.pdf'

const TermsAndConditions = () => {
  const { borrowerLoginConsent } = useCompanyCopies()
  const { showPoweredByPeach } = useCompanyInfo()

  if (borrowerLoginConsent === '') {
    // empty string signals that this company has
    // specifically opted out of showing this content all
    return null
  }

  if (borrowerLoginConsent) {
    // this value being present signals that the company
    // is overriding the consent copy and will supply their own
    const consentHtml = { __html: borrowerLoginConsent }
    return (
      <Wrapper>
        <span dangerouslySetInnerHTML={consentHtml} />

        {showPoweredByPeach && <PoweredByPeach />}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {'By Continuing, I agree to these '}
      <Link href={termsLink} target='_blank' rel='noopener noreferrer'>
        Terms of Use
      </Link>
      {', '}
      <Link href={privacyPolicyLink} target='_blank' rel='noopener noreferrer'>
        Privacy Policy
      </Link>
      {', and '}
      <Link href={privacyNoticeLink} target='_blank' rel='noopener noreferrer'>
        Privacy Notice
      </Link>
      {'. '}
      <PoweredByPeach />
    </Wrapper>
  )
}

export default TermsAndConditions
