import { getOverride } from 'core/components/PeachOverrides/storage'

const APIendpoint =
  import.meta.env.VITE_ENV_LABEL === 'prod' ?
    import.meta.env.VITE_API_ENDPOINT
  : getOverride('customEndpoint') ?? import.meta.env.VITE_API_ENDPOINT

export const isCustomEndpoint = APIendpoint !== import.meta.env.VITE_API_ENDPOINT

export default APIendpoint
