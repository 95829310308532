import { forwardRef } from 'react'

import styled from 'styled-components'

import Icon, { IconProps } from 'core/components/Icon/Icon'
import { PendingEventHandler, usePendingCallback } from 'core/components/lib/Button/Button'
import Spinner from 'core/components/lib/Spinner'
import { variables } from 'core/styles'

type IconButtonProps = IconProps & {
  onClick?: PendingEventHandler
  checked?: boolean
  disabled?: boolean
  isLoading?: boolean
  className?: string
  ariaLabel?: string
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      onClick: _onClick,
      checked,
      disabled = false,
      isLoading = false,
      width = '24px',
      height = '24px',
      fontSize = '24px',
      color = variables.colorBlack70,
      className,
      ariaLabel,
      ...rest
    },
    ref,
  ) => {
    const [isPending, onClick] = usePendingCallback(_onClick)

    return isPending || isLoading ?
        <Spinner width={width} height={height} />
      : <StyledButton
          aria-label={ariaLabel}
          type='button'
          disabled={disabled}
          onClick={onClick}
          ref={ref}
          aria-checked={checked === true}
          color={
            disabled ? variables.colorBlack30
            : checked === false ?
              variables.colorBlack50
            : color
          }
          className={className}
        >
          <Icon width={width} height={height} fontSize={fontSize} {...rest} />
        </StyledButton>
  },
)
export default IconButton

const StyledButton = styled.button<{ color?: string }>`
  color: ${(p) => p.color};
  cursor: pointer;
  background: none;
  border: none;
  transform-origin: center;

  &:disabled {
    cursor: initial;
  }

  &:hover,
  &:focus-visible {
    transform: scale(1.1);
  }

  &:focus-visible {
    outline: 1px solid ${variables.colorBlueTertiary};
  }

  &:active {
    transform: none;
  }
`
