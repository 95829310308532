import { checkTimeordate } from '../types'
import mtz from '../utils/mtz'

const makeIsAfter = (context = {}) => {
  const { timezone } = context

  const isAfter = (after, before) => {
    checkTimeordate(before)
    checkTimeordate(after)

    // If either arg is nully, return false
    if (!before || !after) {
      return false
    }

    return mtz(after, timezone).isAfter(mtz(before, timezone))
  }

  return isAfter
}

export default makeIsAfter
