import { Fragment, forwardRef, useEffect, useRef, useState } from 'react'

import 'react-datepicker/dist/react-datepicker.css'
import { format, getMonth, getYear, parse } from 'date-fns'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import InputMask, { Props as ReactInputMaskProps } from 'react-input-mask'
import styled from 'styled-components'

import Icon from 'core/components/Icon'
import { Select } from 'core/components/legacy'
import {
  convertInDate,
  convertInDatetime,
  convertOutDate,
  convertOutDatetime,
} from 'core/components/lib/DateInput/lib/dateInputHelpers'
import TextInput, { TextInputProps, Wrapper } from 'core/components/lib/TextInput/TextInput'
import { variables } from 'core/styles'
import { useTime } from 'core/time'

const monthOptions = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
].map((label, value) => ({ label, value }))

const dateFormatStr = 'MM/dd/yyyy'
const dateMask = '99/99/9999'
const dateMaskPlaceholder = 'mm/dd/yyyy'
const datetimeFormatStr = `${dateFormatStr} hh:mm aaa`
const datetimeMask = `${dateMask} 99:99 am`
const datetimeMaskPlaceholder = `${dateMaskPlaceholder} hh:mm am`

export type DateInputProps = Omit<TextInputProps, 'onChange' | 'onValueChange' | 'value' | 'prefix' | 'postfix'> &
  Pick<ReactDatePickerProps, 'dateFormat' | 'isClearable'> &
  Partial<Pick<ReactInputMaskProps, 'mask' | 'maskPlaceholder'>> & {
    minDate?: string
    maxDate?: string
    type?: 'date' | 'datetime'
    onChange: (value: string | null) => void
    value: string | null
  }

type TrueInputMask = HTMLInputElement & InputMask

const DateInput = forwardRef<InputMask, DateInputProps>(
  (
    {
      isClearable,
      dateFormat: customDateFormat,
      mask,
      maskPlaceholder,
      minDate,
      maxDate,
      onBlur,
      onChange,
      value,
      variant,
      width = '100%',
      type = 'date',
      ...rest
    },
    ref,
  ) => {
    const internalRef = useRef<TrueInputMask | null>(null)

    const { addDays, isAfter } = useTime()

    const dateFormat = customDateFormat ?? type === 'date' ? dateFormatStr : datetimeFormatStr
    const convertIn = type === 'date' ? convertInDate : convertInDatetime
    const convertOut = type === 'date' ? convertOutDate : convertOutDatetime
    const convertOutParsed = (str: string) => convertOut(parse(str, dateFormat, new Date()))
    const formatReadable = (str: string | null) => (str ? format(convertIn(str)!, dateFormat) : '')

    const readableValue = value ? formatReadable(value) : ''
    const [inputValue, setInputValue] = useState(readableValue)
    const [selectedPart, setSelectedPart] = useState(0)

    const getPartAtCursor = (cursorPosition: number) =>
      cursorPosition < 3 ? 0
      : cursorPosition < 6 ? 1
      : cursorPosition < 11 ? 2
      : cursorPosition < 14 ? 3
      : cursorPosition < 17 ? 4
      : 5

    const resetSelectionStartToPart = (part: number) => {
      const newSelectionStart =
        part === 1 ? 3
        : part === 2 ? 6
        : part === 3 ? 11
        : part === 4 ? 14
        : part === 5 ? 17
        : 0
      setTimeout(() => internalRef.current?.setSelectionRange(newSelectionStart, newSelectionStart), 0)
    }
    const decrementSelectedPart = () => {
      const newPart = Math.max(selectedPart - 1, 0)
      setSelectedPart(newPart)
      resetSelectionStartToPart(newPart)
    }
    const incrementSelectedPart = () => {
      const newPart = Math.min(selectedPart + 1, 5)
      if ((newPart < 3 && type === 'date') || (newPart < 6 && type === 'datetime')) {
        setSelectedPart(newPart)
        resetSelectionStartToPart(newPart)
      }
    }
    const handleClickOrFocus = () => {
      const newPart = getPartAtCursor(internalRef.current?.selectionStart ?? 0)
      setSelectedPart(newPart)
      resetSelectionStartToPart(newPart)
    }

    useEffect(() => {
      if (inputValue !== formatReadable(value)) {
        try {
          onChange(inputValue ? convertOutParsed(inputValue) : null)
        } catch (err) {}
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue])

    // reset value when reset externally
    useEffect(() => setInputValue(readableValue), [readableValue])

    return (
      <DateInputWrapper variant={variant} width={width} disabled={rest.disabled}>
        <InputWrapper>
          <StyledInputMask
            ref={(instance) => {
              // the types from InputMask are of very poor quality
              internalRef.current = instance as TrueInputMask
              if (typeof ref === 'function') {
                ref(instance)
              } else if (ref) {
                ref.current = instance
              }
            }}
            mask={mask ?? type === 'date' ? dateMask : datetimeMask}
            maskPlaceholder={maskPlaceholder ?? type === 'date' ? dateMaskPlaceholder : datetimeMaskPlaceholder}
            onChange={(e) => {
              let value = ''
              let incrementPart = false
              for (let i = 0; i < e.target.value.length; i++) {
                const char = e.target.value[i]
                const num = parseInt(char)
                if (isNaN(num)) {
                  if (i === 17 || i === 18) {
                    if (char.toLowerCase() === 'a') {
                      i++
                      value += 'am'
                    } else if (char.toLowerCase() === 'p') {
                      i++
                      value += 'pm'
                    }
                  } else {
                    value += char
                  }
                } else {
                  if (i === 0 && num > 1) {
                    i++
                    value += '0' + char
                    incrementPart = true
                  } else if (i === 3 && num > 3) {
                    i++
                    value += '0' + char
                    incrementPart = true
                  } else if (i === 11 && num > 1) {
                    i++
                    value += '0' + char
                    incrementPart = true
                  } else if (i === 14 && num > 6) {
                    i++
                    value += '0' + char
                    incrementPart = true
                  } else {
                    value += char
                  }
                }
              }
              setInputValue(value)
              if (incrementPart) {
                setTimeout(incrementSelectedPart, 0)
              } else {
                setSelectedPart(getPartAtCursor(internalRef.current?.selectionStart ?? 0))
              }
            }}
            onBlur={(e) => {
              try {
                const parsedValue = convertOutParsed(inputValue)
                if (
                  (minDate && !isAfter(parsedValue, addDays(minDate, -1))) ||
                  (maxDate && !isAfter(addDays(maxDate, 1), parsedValue))
                ) {
                  onChange(null)
                  setInputValue(formatReadable(null))
                } else {
                  onChange(parsedValue)
                }
              } catch (err) {
                setInputValue(formatReadable(value))
              }
              onBlur?.(e)
            }}
            onClick={handleClickOrFocus}
            onFocus={handleClickOrFocus}
            onKeyDown={(e) => {
              if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                e.preventDefault()
                if (e.key === 'ArrowLeft') decrementSelectedPart()
                if (e.key === 'ArrowRight') incrementSelectedPart()
              } else {
                // ignore illegal keypress for am/pm
                if (
                  internalRef.current?.selectionStart === 17 &&
                  e.key !== 'a' &&
                  e.key !== 'p' &&
                  e.key !== 'Backspace'
                )
                  e.preventDefault()
              }
            }}
            value={inputValue || readableValue}
            spellCheck='false'
            placeholder='Enter a date'
            {...rest}
          />
          <UnderlayWrapper>
            {inputValue.split(/[/\s:]+/).map((part, index) => (
              <Fragment key={`part-${index}`}>
                <UnderlayPart $active={index === selectedPart}>{part}</UnderlayPart>
                {index < 2 ?
                  '/'
                : index === 3 ?
                  ':'
                : ' '}
              </Fragment>
            ))}
          </UnderlayWrapper>
        </InputWrapper>

        {isClearable && (
          <StyledButton type='button' onClick={() => setInputValue('')} disabled={rest.disabled}>
            <Icon name='clear' fontSize='inherit' />
          </StyledButton>
        )}

        <DatePickerWrapper>
          <ReactDatePicker
            customInput={rest.disabled ? <></> : <CalendarButton />}
            dateFormat={dateFormat}
            formatWeekDay={(d) => d.toString().charAt(0)}
            minDate={minDate ? convertIn(minDate) : undefined}
            maxDate={maxDate ? convertIn(maxDate) : undefined}
            onChange={(v) => {
              if (v) {
                onChange(convertOut(v))
                setInputValue(format(v, dateFormat))
              }
            }}
            renderCustomHeader={({
              changeMonth,
              changeYear,
              date,
              decreaseMonth,
              increaseMonth,
              nextMonthButtonDisabled,
              prevMonthButtonDisabled,
            }) => (
              <>
                <Select
                  options={monthOptions}
                  controlledValue={monthOptions.find((m) => m.value === getMonth(date))}
                  getSelectionValue={changeMonth}
                  isControlled
                  small
                />
                <YearInput maxLength={4} value={getYear(date)} onChange={(e) => changeYear(Number(e.target.value))} />
                <MonthSeekButton type='button' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                  <Icon fontSize='inherit' name='arrow_back_ios' />
                </MonthSeekButton>
                <MonthSeekButton type='button' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                  <Icon fontSize='inherit' name='arrow_forward_ios' />
                </MonthSeekButton>
              </>
            )}
            popperPlacement='bottom'
            popperProps={{ offset: [0, 0] }}
            selected={convertIn(value)}
            showPopperArrow={false}
            // time input will not work properly without setting showTimeInput to true
            showTimeInput={type === 'datetime'}
            // we don't actually want to show the time input in the popover though
            // render an empty fragment instead without a label and hide the parent div with css
            customTimeInput={<></>}
            timeInputLabel=''
          />
        </DatePickerWrapper>
      </DateInputWrapper>
    )
  },
)

export default DateInput

const CalendarButton = forwardRef<HTMLButtonElement, any>(({ onClick, ...props }, ref) => {
  return (
    <StyledButton ref={ref} onClick={onClick} type='button'>
      <Icon fontSize='inherit' name='calendar_today' />
    </StyledButton>
  )
})

const DatePickerWrapper = styled.div`
  /* stylelint-disable selector-class-pattern */

  box-sizing: border-box;
  height: 100%;
  font-size: inherit;

  .react-datepicker-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .react-datepicker {
    padding: 8px;
    font-size: 14px;
    border: none;
    border-radius: 8px;
    box-shadow: ${variables.peachyShadow};
  }

  .react-datepicker__month-container,
  .react-datepicker__header,
  .react-datepicker__month,
  .react-datepicker__week,
  .react-datepicker__day-names {
    display: grid;
    gap: 4px;
    padding: 0;
    margin: 0;
  }

  .react-datepicker__header {
    grid-template-columns: 1fr repeat(3, max-content);
    background: none;
    border: none;
    border-radius: 0;
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__month {
    grid-template-rows: repeat(6, 1fr);
  }

  .react-datepicker__week,
  .react-datepicker__day-names {
    grid-template-columns: repeat(7, 1fr);
  }

  .react-datepicker__day-names {
    grid-column: 1 / -1;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin: 0;
    border-radius: 100%;
  }

  .react-datepicker__day:enabled:hover,
  .react-datepicker__day:enabled:focus {
    color: ${variables.colorBlack100};
    background: ${variables.colorBlack20};
    border: none;
  }

  .react-datepicker__day-name,
  .react-datepicker__day--disabled {
    color: ${variables.colorBlack50};
  }

  .react-datepicker__day--disabled {
    cursor: not-allowed;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--today {
    font-weight: 500;
  }

  .react-datepicker__day--keyboard-selected {
    background: none;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--selected:enabled:hover {
    color: ${variables.colorWhite};
    background: ${variables.colorBluePrimary};
  }

  .react-datepicker__day--today {
    box-shadow: inset 0 0 0 1px ${variables.colorBluePrimary};
  }

  .react-datepicker__input-container {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .react-datepicker__input-time-container {
    display: none;
    visibility: hidden;
  }
`
const MonthSeekButton = styled.button`
  padding: 8px;
  font-size: 18px;
  background: none;
  border: none;

  &:disabled {
    color: ${variables.colorBlack30};
    cursor: not-allowed;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
`
const YearInput = styled(TextInput)`
  width: 55px;
  text-align: center;
`

const DateInputWrapper = styled(Wrapper)`
  position: relative;
  grid-template-columns: 1fr auto auto;
  width: ${(p) => p.width};
  padding-right: 3px;
`

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  padding: 0 3px;
  font-size: 1.1em;
  line-height: 0;
  cursor: pointer;
  background: none;
  border: none;
`
const StyledInputMask = styled(InputMask)`
  position: relative;
  width: 100%;
`
const UnderlayWrapper = styled.div`
  position: absolute;
  inset: 0;
  z-index: -1;
  color: transparent;
  opacity: 0;
`
const UnderlayPart = styled.span<{ $active?: boolean }>`
  ${(p) =>
    p.$active &&
    `
    background: ${variables.colorBlueLighten};
  `}
`
const InputWrapper = styled.div`
  position: relative;

  &:focus-within {
    ${StyledInputMask} {
      z-index: 1;
    }
    ${UnderlayWrapper} {
      z-index: 0;
      opacity: 1;
    }
  }
`
