/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const peachy: OperationData<Types.Peachy> = {
  method: 'get',
  name: 'peachy',
  summary: `Is it peachy?`,
  path: '/peachy',
  queryKey: ({}) => ['peachy'],
}

export const peachyReady: OperationData<Types.PeachyReady> = {
  method: 'get',
  name: 'peachyReady',
  summary: `Is it peachy?`,
  path: '/peachy-ready',
  queryKey: ({}) => ['peachy-ready'],
}

export const pit: OperationData<Types.Pit> = {
  method: 'get',
  name: 'pit',
  summary: `Check the pit`,
  path: '/peachy/pit',
  queryKey: ({}) => ['peachy', 'pit'],
}

export const internalGetEvents: OperationData<Types.InternalGetEvents> = {
  method: 'get',
  name: 'internalGetEvents',
  summary: `Retrieve events`,
  path: '/companies/{companyId}/events',
  queryKey: ({ companyId }) => ['companies', companyId, 'events'],
}

export const internalCreateEvent: OperationData<Types.InternalCreateEvent> = {
  method: 'post',
  name: 'internalCreateEvent',
  summary: `Create event`,
  path: '/companies/{companyId}/events',
  queryKey: ({ companyId }) => ['companies', companyId, 'events'],
}

export const internalCreateEventsBulk: OperationData<Types.InternalCreateEventsBulk> = {
  method: 'post',
  name: 'internalCreateEventsBulk',
  summary: `Create many events`,
  path: '/companies/{companyId}/events/bulk',
  queryKey: ({ companyId }) => ['companies', companyId, 'events', 'bulk'],
}

export const internalUpdateEvent: OperationData<Types.InternalUpdateEvent> = {
  method: 'put',
  name: 'internalUpdateEvent',
  summary: `Update event`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/events/{eventId}',
  queryKey: ({ companyId, personId, loanId, eventId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'events',
    eventId,
  ],
}

export const internalLoansGetByCompany: OperationData<Types.InternalLoansGetByCompany> = {
  method: 'get',
  name: 'internalLoansGetByCompany',
  summary: `Get loans`,
  path: '/companies/{companyId}/loans',
  queryKey: ({ companyId }) => ['companies', companyId, 'loans'],
}

export const internalLoansGetCount: OperationData<Types.InternalLoansGetCount> = {
  method: 'get',
  name: 'internalLoansGetCount',
  summary: `Get loan count`,
  path: '/companies/{companyId}/loan-count',
  queryKey: ({ companyId }) => ['companies', companyId, 'loan-count'],
}

export const companyLoanConfigurationsGetAll: OperationData<Types.CompanyLoanConfigurationsGetAll> = {
  method: 'get',
  name: 'companyLoanConfigurationsGetAll',
  summary: `Get loan types`,
  path: '/companies/{companyId}/loan-types',
  queryKey: ({ companyId }) => ['companies', companyId, 'loan-types'],
}

export const companyLoanConfigurationsAdd: OperationData<Types.CompanyLoanConfigurationsAdd> = {
  method: 'post',
  name: 'companyLoanConfigurationsAdd',
  summary: `Create loan type`,
  path: '/companies/{companyId}/loan-types',
  queryKey: ({ companyId }) => ['companies', companyId, 'loan-types'],
}

export const companyLoanConfigurationsGet: OperationData<Types.CompanyLoanConfigurationsGet> = {
  method: 'get',
  name: 'companyLoanConfigurationsGet',
  summary: `Get loan type by ID`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}',
  queryKey: ({ companyId, loanTypeId }) => ['companies', companyId, 'loan-types', loanTypeId],
}

export const companyLoanConfigurationsUpdate: OperationData<Types.CompanyLoanConfigurationsUpdate> = {
  method: 'put',
  name: 'companyLoanConfigurationsUpdate',
  summary: `Update loan type by ID`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}',
  queryKey: ({ companyId, loanTypeId }) => ['companies', companyId, 'loan-types', loanTypeId],
}

export const companyLoanConfigurationsDelete: OperationData<Types.CompanyLoanConfigurationsDelete> = {
  method: 'delete',
  name: 'companyLoanConfigurationsDelete',
  summary: `Delete loan type by ID`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}',
  queryKey: ({ companyId, loanTypeId }) => ['companies', companyId, 'loan-types', loanTypeId],
}

export const loansGetByLoanType: OperationData<Types.LoansGetByLoanType> = {
  method: 'get',
  name: 'loansGetByLoanType',
  summary: `Get all loans by loan type`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}/loans',
  queryKey: ({ companyId, loanTypeId }) => ['companies', companyId, 'loan-types', loanTypeId, 'loans'],
}

export const internalBorrowerPaymentInstrumentGetAll: OperationData<Types.InternalBorrowerPaymentInstrumentGetAll> = {
  method: 'get',
  name: 'internalBorrowerPaymentInstrumentGetAll',
  summary: `Get payment instruments`,
  path: '/companies/{companyId}/people/{personId}/payment-instruments',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId, 'payment-instruments'],
}

export const internalBorrowerPaymentInstrumentCreate: OperationData<Types.InternalBorrowerPaymentInstrumentCreate> = {
  method: 'post',
  name: 'internalBorrowerPaymentInstrumentCreate',
  summary: `Create payment instrument`,
  path: '/companies/{companyId}/people/{personId}/payment-instruments',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId, 'payment-instruments'],
}

export const internalBorrowerPaymentInstrumentGet: OperationData<Types.InternalBorrowerPaymentInstrumentGet> = {
  method: 'get',
  name: 'internalBorrowerPaymentInstrumentGet',
  summary: `Get payment instrument by ID`,
  path: '/companies/{companyId}/people/{personId}/payment-instruments/{paymentInstrumentId}',
  queryKey: ({ companyId, personId, paymentInstrumentId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
  ],
}

export const internalBorrowerPaymentInstrumentUpdate: OperationData<Types.InternalBorrowerPaymentInstrumentUpdate> = {
  method: 'put',
  name: 'internalBorrowerPaymentInstrumentUpdate',
  summary: `Update payment instrument`,
  path: '/companies/{companyId}/people/{personId}/payment-instruments/{paymentInstrumentId}',
  queryKey: ({ companyId, personId, paymentInstrumentId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
  ],
}

export const internalBorrowerPaymentInstrumentDelete: OperationData<Types.InternalBorrowerPaymentInstrumentDelete> = {
  method: 'delete',
  name: 'internalBorrowerPaymentInstrumentDelete',
  summary: `Delete payment instrument`,
  path: '/companies/{companyId}/people/{personId}/payment-instruments/{paymentInstrumentId}',
  queryKey: ({ companyId, personId, paymentInstrumentId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
  ],
}

export const internalAccountLinkPost: OperationData<Types.InternalAccountLinkPost> = {
  method: 'post',
  name: 'internalAccountLinkPost',
  summary: `Create account link`,
  path: '/companies/{companyId}/people/{personId}/payment-instruments/{paymentInstrumentId}/account-link',
  queryKey: ({ companyId, personId, paymentInstrumentId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'account-link',
  ],
}

export const internalAccountLinkDelete: OperationData<Types.InternalAccountLinkDelete> = {
  method: 'delete',
  name: 'internalAccountLinkDelete',
  summary: `Delete account link`,
  path: '/companies/{companyId}/people/{personId}/payment-instruments/{paymentInstrumentId}/account-link',
  queryKey: ({ companyId, personId, paymentInstrumentId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'account-link',
  ],
}

export const internalBalancesGet: OperationData<Types.InternalBalancesGet> = {
  method: 'get',
  name: 'internalBalancesGet',
  summary: `Get balance data`,
  path: '/companies/{companyId}/people/{personId}/payment-instruments/{paymentInstrumentId}/balance',
  queryKey: ({ companyId, personId, paymentInstrumentId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'balance',
  ],
}

export const internalBalancesPost: OperationData<Types.InternalBalancesPost> = {
  method: 'post',
  name: 'internalBalancesPost',
  summary: `Refresh balance data`,
  path: '/companies/{companyId}/people/{personId}/payment-instruments/{paymentInstrumentId}/balance',
  queryKey: ({ companyId, personId, paymentInstrumentId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'balance',
  ],
}

export const internalLoansGet: OperationData<Types.InternalLoansGet> = {
  method: 'get',
  name: 'internalLoansGet',
  summary: `Get loans`,
  path: '/companies/{companyId}/people/{personId}/loans',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId, 'loans'],
}

export const internalLoansAdd: OperationData<Types.InternalLoansAdd> = {
  method: 'post',
  name: 'internalLoansAdd',
  summary: `Create loan`,
  path: '/companies/{companyId}/people/{personId}/loans',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId, 'loans'],
}

export const internalLoanGet: OperationData<Types.InternalLoanGet> = {
  method: 'get',
  name: 'internalLoanGet',
  summary: `Get loan by ID`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}',
  queryKey: ({ companyId, personId, loanId }) => ['companies', companyId, 'people', personId, 'loans', loanId],
}

export const internalLoanUpdate: OperationData<Types.InternalLoanUpdate> = {
  method: 'put',
  name: 'internalLoanUpdate',
  summary: `Update loan`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}',
  queryKey: ({ companyId, personId, loanId }) => ['companies', companyId, 'people', personId, 'loans', loanId],
}

export const internalSyncPayoffStatus: OperationData<Types.InternalSyncPayoffStatus> = {
  method: 'post',
  name: 'internalSyncPayoffStatus',
  summary: `Update loan`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/sync-payoff-status',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'sync-payoff-status',
  ],
}

export const loggingDebugger: OperationData<Types.LoggingDebugger> = {
  method: 'post',
  name: 'loggingDebugger',
  summary: `Debug loan`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/logging-debugger',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'logging-debugger',
  ],
}

export const ledgerDebugger: OperationData<Types.LedgerDebugger> = {
  method: 'get',
  name: 'ledgerDebugger',
  summary: `Debug a loan's ledger`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/ledger-debugger',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'ledger-debugger',
  ],
}

export const internalLoanRatesDelete: OperationData<Types.InternalLoanRatesDelete> = {
  method: 'delete',
  name: 'internalLoanRatesDelete',
  summary: `Remove loan rate`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/rates/{rateId}',
  queryKey: ({ companyId, personId, loanId, rateId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'rates',
    rateId,
  ],
}

export const internalForecastInterest: OperationData<Types.InternalForecastInterest> = {
  method: 'get',
  name: 'internalForecastInterest',
  summary: `Get interest forecast`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/forecast-interest',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'forecast-interest',
  ],
}

export const internalLoanRatesGet: OperationData<Types.InternalLoanRatesGet> = {
  method: 'get',
  name: 'internalLoanRatesGet',
  summary: `Get loan rates`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/rates',
  queryKey: ({ companyId, personId, loanId }) => ['companies', companyId, 'people', personId, 'loans', loanId, 'rates'],
}

export const internalLoanRatesAdd: OperationData<Types.InternalLoanRatesAdd> = {
  method: 'post',
  name: 'internalLoanRatesAdd',
  summary: `Add loan rate`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/rates',
  queryKey: ({ companyId, personId, loanId }) => ['companies', companyId, 'people', personId, 'loans', loanId, 'rates'],
}

export const internalGetLoanPeriods: OperationData<Types.InternalGetLoanPeriods> = {
  method: 'get',
  name: 'internalGetLoanPeriods',
  summary: `Get loan periods`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/periods',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'periods',
  ],
}

export const internalUpdateLoanPeriod: OperationData<Types.InternalUpdateLoanPeriod> = {
  method: 'put',
  name: 'internalUpdateLoanPeriod',
  summary: `Update loan period`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/periods',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'periods',
  ],
}

export const internalCreateNextLoanPeriod: OperationData<Types.InternalCreateNextLoanPeriod> = {
  method: 'post',
  name: 'internalCreateNextLoanPeriod',
  summary: `Create loan period`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/periods',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'periods',
  ],
}

export const internalLoanExpectedPaymentsGet: OperationData<Types.InternalLoanExpectedPaymentsGet> = {
  method: 'get',
  name: 'internalLoanExpectedPaymentsGet',
  summary: `Get expected payments`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/expected-payments',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'expected-payments',
  ],
}

export const internalLoanExpectedPaymentsPost: OperationData<Types.InternalLoanExpectedPaymentsPost> = {
  method: 'post',
  name: 'internalLoanExpectedPaymentsPost',
  summary: `Change due dates schedule`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/expected-payments',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'expected-payments',
  ],
}

export const internalLoanExpectedPaymentsGetOne: OperationData<Types.InternalLoanExpectedPaymentsGetOne> = {
  method: 'get',
  name: 'internalLoanExpectedPaymentsGetOne',
  summary: `Get expected payment by ID`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/expected-payments/{expectedPaymentId}',
  queryKey: ({ companyId, personId, loanId, expectedPaymentId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'expected-payments',
    expectedPaymentId,
  ],
}

export const internalLoanAutopayGet: OperationData<Types.InternalLoanAutopayGet> = {
  method: 'get',
  name: 'internalLoanAutopayGet',
  summary: `Get Autopay plan`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/autopay',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'autopay',
  ],
}

export const internalLoanAutopayPut: OperationData<Types.InternalLoanAutopayPut> = {
  method: 'put',
  name: 'internalLoanAutopayPut',
  summary: `Update Autopay plan`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/autopay',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'autopay',
  ],
}

export const internalLoanAutopayPost: OperationData<Types.InternalLoanAutopayPost> = {
  method: 'post',
  name: 'internalLoanAutopayPost',
  summary: `Create Autopay plan`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/autopay',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'autopay',
  ],
}

export const internalLoanAutopayDelete: OperationData<Types.InternalLoanAutopayDelete> = {
  method: 'delete',
  name: 'internalLoanAutopayDelete',
  summary: `Cancel Autopay plan`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/autopay',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'autopay',
  ],
}

export const internalLoanAutopayCancelPayments: OperationData<Types.InternalLoanAutopayCancelPayments> = {
  method: 'post',
  name: 'internalLoanAutopayCancelPayments',
  summary: `Cancel autopay expected payments`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/autopay/cancel-payments',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'autopay',
    'cancel-payments',
  ],
}

export const internalLoanAutopayReschedulePayments: OperationData<Types.InternalLoanAutopayReschedulePayments> = {
  method: 'post',
  name: 'internalLoanAutopayReschedulePayments',
  summary: `Cancel autopay expected payments`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/autopay/reschedule-payments',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'autopay',
    'reschedule-payments',
  ],
}

export const internalLoanAutopayCreateNextAutopay: OperationData<Types.InternalLoanAutopayCreateNextAutopay> = {
  method: 'post',
  name: 'internalLoanAutopayCreateNextAutopay',
  summary: `Create the next autopay for LOC`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/autopay/create-next-autopay',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'autopay',
    'create-next-autopay',
  ],
}

export const internalLoanAutopayUpdateExpectedPaymentAmount: OperationData<Types.InternalLoanAutopayUpdateExpectedPaymentAmount> =
  {
    method: 'put',
    name: 'internalLoanAutopayUpdateExpectedPaymentAmount',
    summary: `Update an autopay expected payment amount`,
    path: '/companies/{companyId}/people/{personId}/loans/{loanId}/autopay/{autopayExpectedPaymentId}/update-amount',
    queryKey: ({ companyId, personId, loanId, autopayExpectedPaymentId }) => [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'autopay',
      autopayExpectedPaymentId,
      'update-amount',
    ],
  }

export const internalLoanBalancesGet: OperationData<Types.InternalLoanBalancesGet> = {
  method: 'get',
  name: 'internalLoanBalancesGet',
  summary: `Get loan balances`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/balances',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'balances',
  ],
}

export const internalLoanFutureBalancesGet: OperationData<Types.InternalLoanFutureBalancesGet> = {
  method: 'get',
  name: 'internalLoanFutureBalancesGet',
  summary: `Get loan future balances`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/future_balances',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'future_balances',
  ],
}

export const internalPromoProgramsExercise: OperationData<Types.InternalPromoProgramsExercise> = {
  method: 'post',
  name: 'internalPromoProgramsExercise',
  summary: `Daily check (and exercising) promo program`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/promo-programs/exercise',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    'exercise',
  ],
}

export const internalPromoProgramsReverse: OperationData<Types.InternalPromoProgramsReverse> = {
  method: 'post',
  name: 'internalPromoProgramsReverse',
  summary: `Reverse promo program`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}/reverse',
  queryKey: ({ companyId, personId, loanId, promoProgramId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
    'reverse',
  ],
}

export const internalPromoProgramsGetAll: OperationData<Types.InternalPromoProgramsGetAll> = {
  method: 'get',
  name: 'internalPromoProgramsGetAll',
  summary: `Get promo programs`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/promo-programs',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
  ],
}

export const internalPromoProgramsGet: OperationData<Types.InternalPromoProgramsGet> = {
  method: 'get',
  name: 'internalPromoProgramsGet',
  summary: `Get promo program by ID`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}',
  queryKey: ({ companyId, personId, loanId, promoProgramId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
  ],
}

export const internalLoanTypeIndexRateGetAll: OperationData<Types.InternalLoanTypeIndexRateGetAll> = {
  method: 'get',
  name: 'internalLoanTypeIndexRateGetAll',
  summary: `Get index interest rates`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}/index-rates',
  queryKey: ({ companyId, loanTypeId }) => ['companies', companyId, 'loan-types', loanTypeId, 'index-rates'],
}

export const internalLoanTypeIndexRateAdd: OperationData<Types.InternalLoanTypeIndexRateAdd> = {
  method: 'post',
  name: 'internalLoanTypeIndexRateAdd',
  summary: `Add index interest rate`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}/index-rates',
  queryKey: ({ companyId, loanTypeId }) => ['companies', companyId, 'loan-types', loanTypeId, 'index-rates'],
}

export const internalLoanTypeIndexRateGet: OperationData<Types.InternalLoanTypeIndexRateGet> = {
  method: 'get',
  name: 'internalLoanTypeIndexRateGet',
  summary: `Get index interest rate by ID`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}/index-rates/{indexRateId}',
  queryKey: ({ companyId, loanTypeId, indexRateId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'index-rates',
    indexRateId,
  ],
}

export const internalLoanTypeIndexRateUpdate: OperationData<Types.InternalLoanTypeIndexRateUpdate> = {
  method: 'put',
  name: 'internalLoanTypeIndexRateUpdate',
  summary: `Update index interest rate`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}/index-rates/{indexRateId}',
  queryKey: ({ companyId, loanTypeId, indexRateId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'index-rates',
    indexRateId,
  ],
}

export const internalLoanTypeIndexRateDelete: OperationData<Types.InternalLoanTypeIndexRateDelete> = {
  method: 'delete',
  name: 'internalLoanTypeIndexRateDelete',
  summary: `Cancel index interest rate`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}/index-rates/{indexRateId}',
  queryKey: ({ companyId, loanTypeId, indexRateId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'index-rates',
    indexRateId,
  ],
}

export const internalObligationsGetAll: OperationData<Types.InternalObligationsGetAll> = {
  method: 'get',
  name: 'internalObligationsGetAll',
  summary: `Get obligations`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/obligations',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'obligations',
  ],
}

export const internalObligationCreate: OperationData<Types.InternalObligationCreate> = {
  method: 'post',
  name: 'internalObligationCreate',
  summary: `Create obligation`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/obligations',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'obligations',
  ],
}

export const getLoan: OperationData<Types.GetLoan> = {
  method: 'get',
  name: 'getLoan',
  summary: `Get loan without person ID`,
  path: '/companies/{companyId}/loans/{loanId}',
  queryKey: ({ companyId, loanId }) => ['companies', companyId, 'loans', loanId],
}

export const getCompanyLoanObligations: OperationData<Types.GetCompanyLoanObligations> = {
  method: 'get',
  name: 'getCompanyLoanObligations',
  summary: `Get obligations without person ID`,
  path: '/companies/{companyId}/loans/{loanId}/obligations',
  queryKey: ({ companyId, loanId }) => ['companies', companyId, 'loans', loanId, 'obligations'],
}

export const obligationsRefreshPreviewForTimestamp: OperationData<Types.ObligationsRefreshPreviewForTimestamp> = {
  method: 'get',
  name: 'obligationsRefreshPreviewForTimestamp',
  summary: `Refresh obligations preview`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/obligations/refresh-preview',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'obligations',
    'refresh-preview',
  ],
}

export const internalObligationsRefresh: OperationData<Types.InternalObligationsRefresh> = {
  method: 'post',
  name: 'internalObligationsRefresh',
  summary: `Refresh obligations`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/obligations/refresh',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'obligations',
    'refresh',
  ],
}

export const internalLoanAgentDashboardToolsGet: OperationData<Types.InternalLoanAgentDashboardToolsGet> = {
  method: 'get',
  name: 'internalLoanAgentDashboardToolsGet',
  summary: `Get agent dashboard tools`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/agent-dashboard-tools',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'agent-dashboard-tools',
  ],
}

export const internalLoanCustomPaymentPlanRefresh: OperationData<Types.InternalLoanCustomPaymentPlanRefresh> = {
  method: 'post',
  name: 'internalLoanCustomPaymentPlanRefresh',
  summary: `Refresh custom payment plan`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/custom-payment-plans/refresh',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'custom-payment-plans',
    'refresh',
  ],
}

export const obligationsRefresh: OperationData<Types.ObligationsRefresh> = {
  method: 'post',
  name: 'obligationsRefresh',
  summary: `Refresh obligations`,
  path: '/people/{personId}/loans/{loanId}/obligations/refresh',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'obligations', 'refresh'],
}

export const internalLoanTransactionsGetAll: OperationData<Types.InternalLoanTransactionsGetAll> = {
  method: 'get',
  name: 'internalLoanTransactionsGetAll',
  summary: `Get transactions`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
  ],
}

export const internalTransactionsCreate: OperationData<Types.InternalTransactionsCreate> = {
  method: 'post',
  name: 'internalTransactionsCreate',
  summary: `Create transaction`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
  ],
}

export const internalTransactionsGetById: OperationData<Types.InternalTransactionsGetById> = {
  method: 'get',
  name: 'internalTransactionsGetById',
  summary: `Get transaction by ID`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}',
  queryKey: ({ companyId, personId, loanId, transactionId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
  ],
}

export const internalTransactionsUpdate: OperationData<Types.InternalTransactionsUpdate> = {
  method: 'put',
  name: 'internalTransactionsUpdate',
  summary: `Update transaction`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}',
  queryKey: ({ companyId, personId, loanId, transactionId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
  ],
}

export const internalTransactionCancel: OperationData<Types.InternalTransactionCancel> = {
  method: 'post',
  name: 'internalTransactionCancel',
  summary: `Cancel transaction`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}/cancel',
  queryKey: ({ companyId, personId, loanId, transactionId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'cancel',
  ],
}

export const internalTransactionsDeleteChildren: OperationData<Types.InternalTransactionsDeleteChildren> = {
  method: 'post',
  name: 'internalTransactionsDeleteChildren',
  summary: `Delete child virtual transactions`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}/delete-children',
  queryKey: ({ companyId, personId, loanId, transactionId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'delete-children',
  ],
}

export const internalTransactionsZeroSplits: OperationData<Types.InternalTransactionsZeroSplits> = {
  method: 'post',
  name: 'internalTransactionsZeroSplits',
  summary: `Zero out transaction splits`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}/zero-splits',
  queryKey: ({ companyId, personId, loanId, transactionId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'zero-splits',
  ],
}

export const internalTransactionReverse: OperationData<Types.InternalTransactionReverse> = {
  method: 'post',
  name: 'internalTransactionReverse',
  summary: `Reverse transaction`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}/reverse',
  queryKey: ({ companyId, personId, loanId, transactionId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'reverse',
  ],
}

export const internalGetChargebacks: OperationData<Types.InternalGetChargebacks> = {
  method: 'get',
  name: 'internalGetChargebacks',
  summary: `Get transaction chargebacks`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}/chargebacks',
  queryKey: ({ companyId, personId, loanId, transactionId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
  ],
}

export const internalTransactionChargeback: OperationData<Types.InternalTransactionChargeback> = {
  method: 'post',
  name: 'internalTransactionChargeback',
  summary: `Create transaction chargeback`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}/chargebacks',
  queryKey: ({ companyId, personId, loanId, transactionId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
  ],
}

export const internalGetChargebackById: OperationData<Types.InternalGetChargebackById> = {
  method: 'get',
  name: 'internalGetChargebackById',
  summary: `Get chargeback by ID`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}/chargebacks/{chargebackId}',
  queryKey: ({ companyId, personId, loanId, transactionId, chargebackId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
    chargebackId,
  ],
}

export const internalUpdateChargeback: OperationData<Types.InternalUpdateChargeback> = {
  method: 'put',
  name: 'internalUpdateChargeback',
  summary: `Update chargeback`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/transactions/{transactionId}/chargebacks/{chargebackId}',
  queryKey: ({ companyId, personId, loanId, transactionId, chargebackId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
    chargebackId,
  ],
}

export const loanTypeDynamicFeeGetAll: OperationData<Types.LoanTypeDynamicFeeGetAll> = {
  method: 'get',
  name: 'loanTypeDynamicFeeGetAll',
  summary: `Get dynamic fees by loan type`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}/dynamic-fee-types',
  queryKey: ({ companyId, loanTypeId }) => ['companies', companyId, 'loan-types', loanTypeId, 'dynamic-fee-types'],
}

export const loanTypeDynamicFeeTypeAdd: OperationData<Types.LoanTypeDynamicFeeTypeAdd> = {
  method: 'post',
  name: 'loanTypeDynamicFeeTypeAdd',
  summary: `Create dynamic fee type`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}/dynamic-fee-types',
  queryKey: ({ companyId, loanTypeId }) => ['companies', companyId, 'loan-types', loanTypeId, 'dynamic-fee-types'],
}

export const internalLoanChargeOff: OperationData<Types.InternalLoanChargeOff> = {
  method: 'post',
  name: 'internalLoanChargeOff',
  summary: `Charge-off loan`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/charge-off',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'charge-off',
  ],
}

export const internalLoanDueDateDefer: OperationData<Types.InternalLoanDueDateDefer> = {
  method: 'post',
  name: 'internalLoanDueDateDefer',
  summary: `Defer single due date`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/defer-due-date',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'defer-due-date',
  ],
}

export const internalLoanScheduleDefer: OperationData<Types.InternalLoanScheduleDefer> = {
  method: 'post',
  name: 'internalLoanScheduleDefer',
  summary: `Defer schedule`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/defer-schedule',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'defer-schedule',
  ],
}

export const internalLoanChangeTermsScra: OperationData<Types.InternalLoanChangeTermsScra> = {
  method: 'post',
  name: 'internalLoanChangeTermsScra',
  summary: `Change loan terms SCRA`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/payment-plan-scra',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'payment-plan-scra',
  ],
}

export const internalLoanAccelerate: OperationData<Types.InternalLoanAccelerate> = {
  method: 'post',
  name: 'internalLoanAccelerate',
  summary: `Accelerate loan`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/accelerate',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'accelerate',
  ],
}

export const internalLoanRefresh: OperationData<Types.InternalLoanRefresh> = {
  method: 'post',
  name: 'internalLoanRefresh',
  summary: `Refresh loan`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/refresh',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'refresh',
  ],
}

export const internalLoanScheduleOptionsGet: OperationData<Types.InternalLoanScheduleOptionsGet> = {
  method: 'get',
  name: 'internalLoanScheduleOptionsGet',
  summary: `Get loan schedule options`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/schedule-options',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'schedule-options',
  ],
}

export const loanTypeDynamicFeeTypeGet: OperationData<Types.LoanTypeDynamicFeeTypeGet> = {
  method: 'get',
  name: 'loanTypeDynamicFeeTypeGet',
  summary: `Get dynamic fee type by ID`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}/dynamic-fee-types/{dynamicFeeTypeId}',
  queryKey: ({ companyId, loanTypeId, dynamicFeeTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'dynamic-fee-types',
    dynamicFeeTypeId,
  ],
}

export const internalLoanLateFeeCharge: OperationData<Types.InternalLoanLateFeeCharge> = {
  method: 'post',
  name: 'internalLoanLateFeeCharge',
  summary: `Charge late fee`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/charge-late-fee',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'charge-late-fee',
  ],
}

export const internalLoanServiceFeeCharge: OperationData<Types.InternalLoanServiceFeeCharge> = {
  method: 'post',
  name: 'internalLoanServiceFeeCharge',
  summary: `Charge service fee`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/charge-service-fee',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'charge-service-fee',
  ],
}

export const internalLoanNsfFeesCharge: OperationData<Types.InternalLoanNsfFeesCharge> = {
  method: 'post',
  name: 'internalLoanNsfFeesCharge',
  summary: `Charge service fee`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/charge-nsf-fees',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'charge-nsf-fees',
  ],
}

export const internalFeeTypesGet: OperationData<Types.InternalFeeTypesGet> = {
  method: 'get',
  name: 'internalFeeTypesGet',
  summary: `Get fee types`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}/fee-types',
  queryKey: ({ companyId, loanTypeId }) => ['companies', companyId, 'loan-types', loanTypeId, 'fee-types'],
}

export const internalDynamicFeeGet: OperationData<Types.InternalDynamicFeeGet> = {
  method: 'get',
  name: 'internalDynamicFeeGet',
  summary: `Get dynamic fee by ID`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/fees/{loanFeeId}',
  queryKey: ({ companyId, personId, loanId, loanFeeId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'fees',
    loanFeeId,
  ],
}

export const internalDynamicFeeUpdate: OperationData<Types.InternalDynamicFeeUpdate> = {
  method: 'put',
  name: 'internalDynamicFeeUpdate',
  summary: `Update dynamic fee`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/fees/{loanFeeId}',
  queryKey: ({ companyId, personId, loanId, loanFeeId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'fees',
    loanFeeId,
  ],
}

export const internalMarkFeeBooked: OperationData<Types.InternalMarkFeeBooked> = {
  method: 'post',
  name: 'internalMarkFeeBooked',
  summary: `Mark fee booked`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/fees/{loanFeeId}/mark-fee-booked',
  queryKey: ({ companyId, personId, loanId, loanFeeId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'fees',
    loanFeeId,
    'mark-fee-booked',
  ],
}

export const internalFeesGet: OperationData<Types.InternalFeesGet> = {
  method: 'get',
  name: 'internalFeesGet',
  summary: `Get loan fees`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/fees',
  queryKey: ({ companyId, personId, loanId }) => ['companies', companyId, 'people', personId, 'loans', loanId, 'fees'],
}

export const internalFeesPost: OperationData<Types.InternalFeesPost> = {
  method: 'post',
  name: 'internalFeesPost',
  summary: `Create dynamic fee`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/fees',
  queryKey: ({ companyId, personId, loanId }) => ['companies', companyId, 'people', personId, 'loans', loanId, 'fees'],
}

export const internalFeesCancelOne: OperationData<Types.InternalFeesCancelOne> = {
  method: 'post',
  name: 'internalFeesCancelOne',
  summary: `Cancel dynamic fee`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/fees/{loanFeeId}/cancel',
  queryKey: ({ companyId, personId, loanId, loanFeeId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'fees',
    loanFeeId,
    'cancel',
  ],
}

export const internalFeesCancel: OperationData<Types.InternalFeesCancel> = {
  method: 'post',
  name: 'internalFeesCancel',
  summary: `Cancel dynamic fees`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/cancel-fees',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'cancel-fees',
  ],
}

export const internalLoanAccrue: OperationData<Types.InternalLoanAccrue> = {
  method: 'post',
  name: 'internalLoanAccrue',
  summary: `Accrue daily interest`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/accrue',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'accrue',
  ],
}

export const loanPayoff: OperationData<Types.LoanPayoff> = {
  method: 'post',
  name: 'loanPayoff',
  summary: `Update loan status to paidOff status based on the outstanding balance`,
  path: '/people/{personId}/loans/{loanId}/payoff',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'payoff'],
}

export const internalLoanPayoff: OperationData<Types.InternalLoanPayoff> = {
  method: 'post',
  name: 'internalLoanPayoff',
  summary: `Update loan status to paidOff status based on the outstanding balance`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/payoff',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'payoff',
  ],
}

export const internalLoanPayoffReverse: OperationData<Types.InternalLoanPayoffReverse> = {
  method: 'post',
  name: 'internalLoanPayoffReverse',
  summary: `Update loan status to a non paidOff status based on the outstanding balance`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/reverse-payoff',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'reverse-payoff',
  ],
}

export const loanPayoffReverse: OperationData<Types.LoanPayoffReverse> = {
  method: 'post',
  name: 'loanPayoffReverse',
  summary: `Update loan status to a non paidOff status based on the outstanding balance`,
  path: '/people/{personId}/loans/{loanId}/reverse-payoff',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'reverse-payoff'],
}

export const internalLoanExpectedPaymentsRefresh: OperationData<Types.InternalLoanExpectedPaymentsRefresh> = {
  method: 'post',
  name: 'internalLoanExpectedPaymentsRefresh',
  summary: `Refresh expected payments`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/expected-payments/refresh',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'expected-payments',
    'refresh',
  ],
}

export const loanExpectedPaymentsRefresh: OperationData<Types.LoanExpectedPaymentsRefresh> = {
  method: 'post',
  name: 'loanExpectedPaymentsRefresh',
  summary: `Refresh expected payments`,
  path: '/people/{personId}/loans/{loanId}/expected-payments/refresh',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'expected-payments', 'refresh'],
}

export const internalLoanExpectedPaymentOverride: OperationData<Types.InternalLoanExpectedPaymentOverride> = {
  method: 'put',
  name: 'internalLoanExpectedPaymentOverride',
  summary: `Update an expected payment. Currently used internally for only a few narrow use cases:
  - updating the expected payment in the case of overpayment adjustment
  - updating the expected payment to zero in the case of fee capitalization`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/expected-payments/override',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'expected-payments',
    'override',
  ],
}

export const internalFeeAdjustForLoc: OperationData<Types.InternalFeeAdjustForLoc> = {
  method: 'post',
  name: 'internalFeeAdjustForLoc',
  summary: `Adjust fees for LOC`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/adjust-fees',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'adjust-fees',
  ],
}

export const internalFinalizeMinPaymentsForLoc: OperationData<Types.InternalFinalizeMinPaymentsForLoc> = {
  method: 'post',
  name: 'internalFinalizeMinPaymentsForLoc',
  summary: `Finalise min payments for LOC`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/min-payment',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'min-payment',
  ],
}

export const internalLoanExpectedPaymentsOverLimit: OperationData<Types.InternalLoanExpectedPaymentsOverLimit> = {
  method: 'post',
  name: 'internalLoanExpectedPaymentsOverLimit',
  summary: `Create OverLimitPayment`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/expected-payments/over-limit',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'expected-payments',
    'over-limit',
  ],
}

export const emitScheduledInteractionEvents: OperationData<Types.EmitScheduledInteractionEvents> = {
  method: 'post',
  name: 'emitScheduledInteractionEvents',
  summary: `Emit events for scheduled interactions`,
  path: '/rpc/companies/{companyId}/emit-scheduled-interaction-events',
  queryKey: ({ companyId }) => ['rpc', 'companies', companyId, 'emit-scheduled-interaction-events'],
}

export const emitExpiredCaseSnoozeEvents: OperationData<Types.EmitExpiredCaseSnoozeEvents> = {
  method: 'post',
  name: 'emitExpiredCaseSnoozeEvents',
  summary: `Emit events for snoozed cases`,
  path: '/rpc/companies/{companyId}/emit-expired-case-snooze-events',
  queryKey: ({ companyId }) => ['rpc', 'companies', companyId, 'emit-expired-case-snooze-events'],
}

export const emitExpiredCaseDniEvents: OperationData<Types.EmitExpiredCaseDniEvents> = {
  method: 'post',
  name: 'emitExpiredCaseDniEvents',
  summary: `Emit events for expired DNIs`,
  path: '/rpc/companies/{companyId}/emit-expired-case-dni-events',
  queryKey: ({ companyId }) => ['rpc', 'companies', companyId, 'emit-expired-case-dni-events'],
}

export const servicesGetAll: OperationData<Types.ServicesGetAll> = {
  method: 'get',
  name: 'servicesGetAll',
  summary: `Get all monitoring services`,
  path: '/companies/{companyId}/monitoring/services',
  queryKey: ({ companyId }) => ['companies', companyId, 'monitoring', 'services'],
}

export const serviceCreate: OperationData<Types.ServiceCreate> = {
  method: 'post',
  name: 'serviceCreate',
  summary: `Create a monitoring service.`,
  path: '/companies/{companyId}/monitoring/services',
  queryKey: ({ companyId }) => ['companies', companyId, 'monitoring', 'services'],
}

export const servicesGet: OperationData<Types.ServicesGet> = {
  method: 'get',
  name: 'servicesGet',
  summary: `Get a specific monitoring service`,
  path: '/companies/{companyId}/monitoring/services/{serviceId}',
  queryKey: ({ companyId, serviceId }) => ['companies', companyId, 'monitoring', 'services', serviceId],
}

export const dueStatuses: OperationData<Types.DueStatuses> = {
  method: 'get',
  name: 'dueStatuses',
  summary: `Get due monitoring statuses. For internal use only!`,
  path: '/companies/{companyId}/monitoring/services/{serviceId}/due-statuses',
  queryKey: ({ companyId, serviceId }) => ['companies', companyId, 'monitoring', 'services', serviceId, 'due-statuses'],
}

export const allStatuses: OperationData<Types.AllStatuses> = {
  method: 'get',
  name: 'allStatuses',
  summary: `Get all monitoring statuses irrespective of company or person,
for internal use only!`,
  path: '/monitoring-status',
  queryKey: ({}) => ['monitoring-status'],
}

export const updateStatuses: OperationData<Types.UpdateStatuses> = {
  method: 'patch',
  name: 'updateStatuses',
  summary: `Update multiple monitoring statuses irrespective of company or person,
for internal use only!`,
  path: '/monitoring-status',
  queryKey: ({}) => ['monitoring-status'],
}

export const internalSnoozeCase: OperationData<Types.InternalSnoozeCase> = {
  method: 'post',
  name: 'internalSnoozeCase',
  summary: `Snooze case`,
  path: '/companies/{companyId}/people/{personId}/cases/{caseId}/snooze',
  queryKey: ({ companyId, personId, caseId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'cases',
    caseId,
    'snooze',
  ],
}

export const getAddressesInFipsCode: OperationData<Types.GetAddressesInFipsCode> = {
  method: 'get',
  name: 'getAddressesInFipsCode',
  summary: `Get all addresses within a given fips code for a given company,
for internal use only!`,
  path: '/uscounties/{state}/{fipsCode}/addressesinCounty',
  queryKey: ({ state, fipsCode }) => ['uscounties', state, fipsCode, 'addressesinCounty'],
}

export const internalStatusGetAll: OperationData<Types.InternalStatusGetAll> = {
  method: 'get',
  name: 'internalStatusGetAll',
  summary: `Get all monitoring statuses`,
  path: '/companies/{companyId}/people/{personId}/monitoring-status',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId, 'monitoring-status'],
}

export const internalStatusUpdate: OperationData<Types.InternalStatusUpdate> = {
  method: 'put',
  name: 'internalStatusUpdate',
  summary: `Update a specific monitoring status`,
  path: '/companies/{companyId}/people/{personId}/monitoring-status/{statusId}',
  queryKey: ({ companyId, personId, statusId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'monitoring-status',
    statusId,
  ],
}

export const statusGetAll: OperationData<Types.StatusGetAll> = {
  method: 'get',
  name: 'statusGetAll',
  summary: `Get all monitoring statuses`,
  path: '/people/{personId}/monitoring-status',
  queryKey: ({ personId }) => ['people', personId, 'monitoring-status'],
}

export const statusCreate: OperationData<Types.StatusCreate> = {
  method: 'post',
  name: 'statusCreate',
  summary: `Create a monitoring status.`,
  path: '/people/{personId}/monitoring-status',
  queryKey: ({ personId }) => ['people', personId, 'monitoring-status'],
}

export const statusGet: OperationData<Types.StatusGet> = {
  method: 'get',
  name: 'statusGet',
  summary: `Get a specific monitoring status`,
  path: '/people/{personId}/monitoring-status/{statusId}',
  queryKey: ({ personId, statusId }) => ['people', personId, 'monitoring-status', statusId],
}

export const statusUpdate: OperationData<Types.StatusUpdate> = {
  method: 'put',
  name: 'statusUpdate',
  summary: `Update a specific monitoring status`,
  path: '/people/{personId}/monitoring-status/{statusId}',
  queryKey: ({ personId, statusId }) => ['people', personId, 'monitoring-status', statusId],
}

export const getAllDisasters: OperationData<Types.GetAllDisasters> = {
  method: 'get',
  name: 'getAllDisasters',
  summary: `Get all FEMA Disasters.`,
  path: '/disasters',
  queryKey: ({}) => ['disasters'],
}

export const createDisaster: OperationData<Types.CreateDisaster> = {
  method: 'post',
  name: 'createDisaster',
  summary: `Create a FEMA Disaster.`,
  path: '/disasters',
  queryKey: ({}) => ['disasters'],
}

export const getDisasterImpactedAreasCsv: OperationData<Types.GetDisasterImpactedAreasCsv> = {
  method: 'get',
  name: 'getDisasterImpactedAreasCsv',
  summary: `Get disaster impacted area information in CSV format.`,
  path: '/disasters/{disasterNumber}/impacted-areas.csv',
  queryKey: ({ disasterNumber }) => ['disasters', disasterNumber, 'impacted-areas.csv'],
}

export const findNewUpdatedDisasters: OperationData<Types.FindNewUpdatedDisasters> = {
  method: 'post',
  name: 'findNewUpdatedDisasters',
  summary: `Update FEMA Disasters and returns new ones that should be created.`,
  path: '/disasters/new-updated',
  queryKey: ({}) => ['disasters', 'new-updated'],
}

export const companyRoleGetAll: OperationData<Types.CompanyRoleGetAll> = {
  method: 'get',
  name: 'companyRoleGetAll',
  summary: `Retrieve all company roles`,
  path: '/companies/{companyId}/roles',
  queryKey: ({ companyId }) => ['companies', companyId, 'roles'],
}

export const companyRoleAdd: OperationData<Types.CompanyRoleAdd> = {
  method: 'post',
  name: 'companyRoleAdd',
  summary: `Add a company role`,
  path: '/companies/{companyId}/roles',
  queryKey: ({ companyId }) => ['companies', companyId, 'roles'],
}

export const companyRoleGet: OperationData<Types.CompanyRoleGet> = {
  method: 'get',
  name: 'companyRoleGet',
  summary: `Retrieve one company role`,
  path: '/companies/{companyId}/roles/{roleId}',
  queryKey: ({ companyId, roleId }) => ['companies', companyId, 'roles', roleId],
}

export const companyRoleUpdate: OperationData<Types.CompanyRoleUpdate> = {
  method: 'put',
  name: 'companyRoleUpdate',
  summary: `Update a company role`,
  path: '/companies/{companyId}/roles/{roleId}',
  queryKey: ({ companyId, roleId }) => ['companies', companyId, 'roles', roleId],
}

export const companyRoleDelete: OperationData<Types.CompanyRoleDelete> = {
  method: 'delete',
  name: 'companyRoleDelete',
  summary: `Delete a company role`,
  path: '/companies/{companyId}/roles/{roleId}',
  queryKey: ({ companyId, roleId }) => ['companies', companyId, 'roles', roleId],
}

export const companyRolePermissionsElevate: OperationData<Types.CompanyRolePermissionsElevate> = {
  method: 'post',
  name: 'companyRolePermissionsElevate',
  summary: `Elevate permissions`,
  path: '/companies/{companyId}/roles/{roleId}/permissions',
  queryKey: ({ companyId, roleId }) => ['companies', companyId, 'roles', roleId, 'permissions'],
}

export const companyRolePermissionsRevoke: OperationData<Types.CompanyRolePermissionsRevoke> = {
  method: 'delete',
  name: 'companyRolePermissionsRevoke',
  summary: `Revoke permissions`,
  path: '/companies/{companyId}/roles/{roleId}/permissions',
  queryKey: ({ companyId, roleId }) => ['companies', companyId, 'roles', roleId, 'permissions'],
}

export const companyRolePermissionsRevokeOne: OperationData<Types.CompanyRolePermissionsRevokeOne> = {
  method: 'delete',
  name: 'companyRolePermissionsRevokeOne',
  summary: `Revoke a single permission from a role`,
  path: '/companies/{companyId}/roles/{roleId}/permissions/{permissionId}',
  queryKey: ({ companyId, roleId, permissionId }) => [
    'companies',
    companyId,
    'roles',
    roleId,
    'permissions',
    permissionId,
  ],
}

export const companyPermissionsGet: OperationData<Types.CompanyPermissionsGet> = {
  method: 'get',
  name: 'companyPermissionsGet',
  summary: `Get a company's available permissions`,
  path: '/companies/{companyId}/permissions',
  queryKey: ({ companyId }) => ['companies', companyId, 'permissions'],
}

export const internalUsersGet: OperationData<Types.InternalUsersGet> = {
  method: 'get',
  name: 'internalUsersGet',
  summary: `Get a company's users`,
  path: '/companies/{companyId}/users',
  queryKey: ({ companyId }) => ['companies', companyId, 'users'],
}

export const internalUserCreate: OperationData<Types.InternalUserCreate> = {
  method: 'post',
  name: 'internalUserCreate',
  summary: `Create company user`,
  path: '/companies/{companyId}/users',
  queryKey: ({ companyId }) => ['companies', companyId, 'users'],
}

export const internalUserGet: OperationData<Types.InternalUserGet> = {
  method: 'get',
  name: 'internalUserGet',
  summary: `Get company user`,
  path: '/companies/{companyId}/users/{userId}',
  queryKey: ({ companyId, userId }) => ['companies', companyId, 'users', userId],
}

export const internalUserUpdate: OperationData<Types.InternalUserUpdate> = {
  method: 'put',
  name: 'internalUserUpdate',
  summary: `Update company user`,
  path: '/companies/{companyId}/users/{userId}',
  queryKey: ({ companyId, userId }) => ['companies', companyId, 'users', userId],
}

export const internalUserGetPermissions: OperationData<Types.InternalUserGetPermissions> = {
  method: 'get',
  name: 'internalUserGetPermissions',
  summary: `Get user permissions`,
  path: '/companies/{companyId}/users/{userId}/permissions',
  queryKey: ({ companyId, userId }) => ['companies', companyId, 'users', userId, 'permissions'],
}

export const internalUserRoleGet: OperationData<Types.InternalUserRoleGet> = {
  method: 'get',
  name: 'internalUserRoleGet',
  summary: `Get roles by user`,
  path: '/companies/{companyId}/users/{userId}/roles',
  queryKey: ({ companyId, userId }) => ['companies', companyId, 'users', userId, 'roles'],
}

export const internalUserRoleUpdate: OperationData<Types.InternalUserRoleUpdate> = {
  method: 'put',
  name: 'internalUserRoleUpdate',
  summary: `Add role to user`,
  path: '/companies/{companyId}/users/{userId}/roles/{roleId}',
  queryKey: ({ companyId, userId, roleId }) => ['companies', companyId, 'users', userId, 'roles', roleId],
}

export const internalUserRoleDelete: OperationData<Types.InternalUserRoleDelete> = {
  method: 'delete',
  name: 'internalUserRoleDelete',
  summary: `Remove role from user`,
  path: '/companies/{companyId}/users/{userId}/roles/{roleId}',
  queryKey: ({ companyId, userId, roleId }) => ['companies', companyId, 'users', userId, 'roles', roleId],
}

export const investorGetAll: OperationData<Types.InvestorGetAll> = {
  method: 'get',
  name: 'investorGetAll',
  summary: `Get investors`,
  path: '/companies/{companyId}/investors',
  queryKey: ({ companyId }) => ['companies', companyId, 'investors'],
}

export const investorCreate: OperationData<Types.InvestorCreate> = {
  method: 'post',
  name: 'investorCreate',
  summary: `Create investor`,
  path: '/companies/{companyId}/investors',
  queryKey: ({ companyId }) => ['companies', companyId, 'investors'],
}

export const internalLoanInvestorsGetAll: OperationData<Types.InternalLoanInvestorsGetAll> = {
  method: 'get',
  name: 'internalLoanInvestorsGetAll',
  summary: `Get loan investors`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/investors',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'investors',
  ],
}

export const investorGet: OperationData<Types.InvestorGet> = {
  method: 'get',
  name: 'investorGet',
  summary: `Get investor by ID`,
  path: '/companies/{companyId}/investors/{investorId}',
  queryKey: ({ companyId, investorId }) => ['companies', companyId, 'investors', investorId],
}

export const investorUpdate: OperationData<Types.InvestorUpdate> = {
  method: 'put',
  name: 'investorUpdate',
  summary: `Update investor`,
  path: '/companies/{companyId}/investors/{investorId}',
  queryKey: ({ companyId, investorId }) => ['companies', companyId, 'investors', investorId],
}

export const settlementInstrumentGetAll: OperationData<Types.SettlementInstrumentGetAll> = {
  method: 'get',
  name: 'settlementInstrumentGetAll',
  summary: `Get settlement instruments`,
  path: '/companies/{companyId}/investors/{investorId}/settlement-instruments',
  queryKey: ({ companyId, investorId }) => ['companies', companyId, 'investors', investorId, 'settlement-instruments'],
}

export const settlementInstrumentCreate: OperationData<Types.SettlementInstrumentCreate> = {
  method: 'post',
  name: 'settlementInstrumentCreate',
  summary: `Create settlement instrument`,
  path: '/companies/{companyId}/investors/{investorId}/settlement-instruments',
  queryKey: ({ companyId, investorId }) => ['companies', companyId, 'investors', investorId, 'settlement-instruments'],
}

export const settlementInstrumentGet: OperationData<Types.SettlementInstrumentGet> = {
  method: 'get',
  name: 'settlementInstrumentGet',
  summary: `Get settlement instrument by ID`,
  path: '/companies/{companyId}/investors/{investorId}/settlement-instruments/{settlementInstrumentId}',
  queryKey: ({ companyId, investorId, settlementInstrumentId }) => [
    'companies',
    companyId,
    'investors',
    investorId,
    'settlement-instruments',
    settlementInstrumentId,
  ],
}

export const settlementInstrumentUpdate: OperationData<Types.SettlementInstrumentUpdate> = {
  method: 'put',
  name: 'settlementInstrumentUpdate',
  summary: `Update settlement instrument`,
  path: '/companies/{companyId}/investors/{investorId}/settlement-instruments/{settlementInstrumentId}',
  queryKey: ({ companyId, investorId, settlementInstrumentId }) => [
    'companies',
    companyId,
    'investors',
    investorId,
    'settlement-instruments',
    settlementInstrumentId,
  ],
}

export const fundingInstrumentGetAll: OperationData<Types.FundingInstrumentGetAll> = {
  method: 'get',
  name: 'fundingInstrumentGetAll',
  summary: `Get funding instruments`,
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/funding-instruments',
  queryKey: ({ companyId, paymentProcessorId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'funding-instruments',
  ],
}

export const fundingInstrumentCreate: OperationData<Types.FundingInstrumentCreate> = {
  method: 'post',
  name: 'fundingInstrumentCreate',
  summary: `Create funding instrument`,
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/funding-instruments',
  queryKey: ({ companyId, paymentProcessorId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'funding-instruments',
  ],
}

export const fundingInstrumentGet: OperationData<Types.FundingInstrumentGet> = {
  method: 'get',
  name: 'fundingInstrumentGet',
  summary: `Get funding instrument by ID`,
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/funding-instruments/{fundingInstrumentId}',
  queryKey: ({ companyId, paymentProcessorId, fundingInstrumentId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'funding-instruments',
    fundingInstrumentId,
  ],
}

export const fundingInstrumentUpdate: OperationData<Types.FundingInstrumentUpdate> = {
  method: 'put',
  name: 'fundingInstrumentUpdate',
  summary: `Update funding instrument`,
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/funding-instruments/{fundingInstrumentId}',
  queryKey: ({ companyId, paymentProcessorId, fundingInstrumentId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'funding-instruments',
    fundingInstrumentId,
  ],
}

export const getRecentFundingAccountBalances: OperationData<Types.GetRecentFundingAccountBalances> = {
  method: 'get',
  name: 'getRecentFundingAccountBalances',
  summary: `Fetch balances for the funding instrument`,
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/funding-instruments/{fundingInstrumentId}/balance',
  queryKey: ({ companyId, paymentProcessorId, fundingInstrumentId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'funding-instruments',
    fundingInstrumentId,
    'balance',
  ],
}

export const fundingInstrumentCreateBalance: OperationData<Types.FundingInstrumentCreateBalance> = {
  method: 'post',
  name: 'fundingInstrumentCreateBalance',
  summary: `Add a new balance to the funding instrument`,
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/funding-instruments/{fundingInstrumentId}/balance',
  queryKey: ({ companyId, paymentProcessorId, fundingInstrumentId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'funding-instruments',
    fundingInstrumentId,
    'balance',
  ],
}

export const getAllTransferSettlementsForAllCompanies: OperationData<Types.GetAllTransferSettlementsForAllCompanies> = {
  method: 'get',
  name: 'getAllTransferSettlementsForAllCompanies',
  summary: `Get all the transfer settlements for all companies`,
  path: '/settlements',
  queryKey: ({}) => ['settlements'],
}

export const getAllTransferSettlements: OperationData<Types.GetAllTransferSettlements> = {
  method: 'get',
  name: 'getAllTransferSettlements',
  summary: `Get all the transfer settlements`,
  path: '/companies/{companyId}/settlements',
  queryKey: ({ companyId }) => ['companies', companyId, 'settlements'],
}

export const createTransferSettlements: OperationData<Types.CreateTransferSettlements> = {
  method: 'post',
  name: 'createTransferSettlements',
  summary: `Create a new transfer settlement`,
  path: '/companies/{companyId}/settlements',
  queryKey: ({ companyId }) => ['companies', companyId, 'settlements'],
}

export const getTransferSettlement: OperationData<Types.GetTransferSettlement> = {
  method: 'get',
  name: 'getTransferSettlement',
  summary: `Get a transfer settlement by ID`,
  path: '/companies/{companyId}/settlements/{transferSettlementId}',
  queryKey: ({ companyId, transferSettlementId }) => ['companies', companyId, 'settlements', transferSettlementId],
}

export const updateTransferSettlement: OperationData<Types.UpdateTransferSettlement> = {
  method: 'put',
  name: 'updateTransferSettlement',
  summary: `Update a transfer settlement by ID`,
  path: '/companies/{companyId}/settlements/{transferSettlementId}',
  queryKey: ({ companyId, transferSettlementId }) => ['companies', companyId, 'settlements', transferSettlementId],
}

export const updateTransfersExpectingFunding: OperationData<Types.UpdateTransfersExpectingFunding> = {
  method: 'put',
  name: 'updateTransfersExpectingFunding',
  summary: `Update the transfers expecting funding`,
  path: '/companies/{companyId}/loans/{loanId}/transfers',
  queryKey: ({ companyId, loanId }) => ['companies', companyId, 'loans', loanId, 'transfers'],
}

export const endSession: OperationData<Types.EndSession> = {
  method: 'post',
  name: 'endSession',
  summary: `End a user's session`,
  path: '/auth/endsession',
  queryKey: ({}) => ['auth', 'endsession'],
}

export const renewJwtToken: OperationData<Types.RenewJwtToken> = {
  method: 'put',
  name: 'renewJwtToken',
  summary: `Exchange current token for a new one`,
  path: '/auth/token',
  queryKey: ({}) => ['auth', 'token'],
}

export const validateAndSendToken: OperationData<Types.ValidateAndSendToken> = {
  method: 'post',
  name: 'validateAndSendToken',
  summary: `Exchange username and password for JWT token`,
  path: '/auth/token',
  queryKey: ({}) => ['auth', 'token'],
}

export const logout: OperationData<Types.Logout> = {
  method: 'delete',
  name: 'logout',
  summary: `End login session, invalidating current login token.`,
  path: '/auth/token',
  queryKey: ({}) => ['auth', 'token'],
}

export const shortLivedToken: OperationData<Types.ShortLivedToken> = {
  method: 'post',
  name: 'shortLivedToken',
  summary: `Create a short-lived token`,
  path: '/auth/token/short-lived',
  queryKey: ({}) => ['auth', 'token', 'short-lived'],
}

export const passwordPost: OperationData<Types.PasswordPost> = {
  method: 'post',
  name: 'passwordPost',
  summary: `Change password`,
  path: '/auth/password',
  queryKey: ({}) => ['auth', 'password'],
}

export const userPermissions_get: OperationData<Types.UserPermissions_get> = {
  method: 'get',
  name: 'userPermissions_get',
  summary: `Get all permissions for the requesting user`,
  path: '/auth/permissions',
  queryKey: ({}) => ['auth', 'permissions'],
}

export const idpInitiated: OperationData<Types.IdpInitiated> = {
  method: 'post',
  name: 'idpInitiated',
  summary: `SAML callback endpoint`,
  path: '/companies/{companyId}/auth/saml/callback',
  queryKey: ({ companyId }) => ['companies', companyId, 'auth', 'saml', 'callback'],
}

export const spInitiated: OperationData<Types.SpInitiated> = {
  method: 'get',
  name: 'spInitiated',
  summary: `Redirect to the SAML login endpoint`,
  path: '/companies/{companyId}/auth/saml/login',
  queryKey: ({ companyId }) => ['companies', companyId, 'auth', 'saml', 'login'],
}

export const spMetadataXml: OperationData<Types.SpMetadataXml> = {
  method: 'get',
  name: 'spMetadataXml',
  summary: `Display Peach SP Metadata`,
  path: '/companies/{companyId}/auth/saml/metadata.xml',
  queryKey: ({ companyId }) => ['companies', companyId, 'auth', 'saml', 'metadata.xml'],
}

export const idpSetupHelp: OperationData<Types.IdpSetupHelp> = {
  method: 'get',
  name: 'idpSetupHelp',
  summary: `Display IdP setup help`,
  path: '/companies/{companyId}/auth/saml/help',
  queryKey: ({ companyId }) => ['companies', companyId, 'auth', 'saml', 'help'],
}

export const userInfoGet: OperationData<Types.UserInfoGet> = {
  method: 'get',
  name: 'userInfoGet',
  summary: `Get logged-in user's info.`,
  path: '/auth/user',
  queryKey: ({}) => ['auth', 'user'],
}

export const plaidAuthLinkToken: OperationData<Types.PlaidAuthLinkToken> = {
  method: 'post',
  name: 'plaidAuthLinkToken',
  summary: `Create a Plaid link token`,
  path: '/plaid/auth/link-token',
  queryKey: ({}) => ['plaid', 'auth', 'link-token'],
}

export const codeGetAll: OperationData<Types.CodeGetAll> = {
  method: 'get',
  name: 'codeGetAll',
  summary: `Get codes`,
  path: '/code',
  queryKey: ({}) => ['code'],
}

export const codeCreate: OperationData<Types.CodeCreate> = {
  method: 'post',
  name: 'codeCreate',
  summary: `Create code`,
  path: '/code',
  queryKey: ({}) => ['code'],
}

export const exportCodes: OperationData<Types.ExportCodes> = {
  method: 'get',
  name: 'exportCodes',
  summary: `Download all rule codes in JSON format`,
  path: '/code/export',
  queryKey: ({}) => ['code', 'export'],
}

export const importCodes: OperationData<Types.ImportCodes> = {
  method: 'post',
  name: 'importCodes',
  summary: `Update codes from exported JSON file`,
  path: '/code/import',
  queryKey: ({}) => ['code', 'import'],
}

export const internalImportCodes: OperationData<Types.InternalImportCodes> = {
  method: 'post',
  name: 'internalImportCodes',
  summary: `Update codes from exported JSON file`,
  path: '/companies/{companyId}/compliance/code/import',
  queryKey: ({ companyId }) => ['companies', companyId, 'compliance', 'code', 'import'],
}

export const internalUpdateComplianceRule: OperationData<Types.InternalUpdateComplianceRule> = {
  method: 'put',
  name: 'internalUpdateComplianceRule',
  summary: `Update a compliance rule.`,
  path: '/companies/{companyId}/compliancerule/{complianceRuleId}',
  queryKey: ({ companyId, complianceRuleId }) => ['companies', companyId, 'compliancerule', complianceRuleId],
}

export const codeGet: OperationData<Types.CodeGet> = {
  method: 'get',
  name: 'codeGet',
  summary: `Get code by ID`,
  path: '/code/{codeId}',
  queryKey: ({ codeId }) => ['code', codeId],
}

export const codeUpdate: OperationData<Types.CodeUpdate> = {
  method: 'put',
  name: 'codeUpdate',
  summary: `Update code`,
  path: '/code/{codeId}',
  queryKey: ({ codeId }) => ['code', codeId],
}

export const codeExecute: OperationData<Types.CodeExecute> = {
  method: 'post',
  name: 'codeExecute',
  summary: `Execute code`,
  path: '/code/{codeId}',
  queryKey: ({ codeId }) => ['code', codeId],
}

export const transactionsGetInternal: OperationData<Types.TransactionsGetInternal> = {
  method: 'get',
  name: 'transactionsGetInternal',
  summary: `Get all transactions`,
  path: '/transactions',
  queryKey: ({}) => ['transactions'],
}

export const transactionsUpdateInternal: OperationData<Types.TransactionsUpdateInternal> = {
  method: 'post',
  name: 'transactionsUpdateInternal',
  summary: `Update processed transactions`,
  path: '/transactions',
  queryKey: ({}) => ['transactions'],
}

export const transactionsGetInitiatedAchInternal: OperationData<Types.TransactionsGetInitiatedAchInternal> = {
  method: 'get',
  name: 'transactionsGetInitiatedAchInternal',
  summary: `Get initiated ACH transactions.`,
  path: '/transactions/initiated',
  queryKey: ({}) => ['transactions', 'initiated'],
}

export const transactionsUpdateInitiatedAchInternal: OperationData<Types.TransactionsUpdateInitiatedAchInternal> = {
  method: 'post',
  name: 'transactionsUpdateInitiatedAchInternal',
  summary: `Update processed ACH transactions.`,
  path: '/transactions/initiated',
  queryKey: ({}) => ['transactions', 'initiated'],
}

export const transactionsCreateRefundServiceCreditInternal: OperationData<Types.TransactionsCreateRefundServiceCreditInternal> =
  {
    method: 'post',
    name: 'transactionsCreateRefundServiceCreditInternal',
    summary: `Create service credit of type refund for LOC purchase`,
    path: '/transactions/refund',
    queryKey: ({}) => ['transactions', 'refund'],
  }

export const microdepositsGetScheduledInternal: OperationData<Types.MicrodepositsGetScheduledInternal> = {
  method: 'get',
  name: 'microdepositsGetScheduledInternal',
  summary: `Get scheduled microdeposits`,
  path: '/transactions/microdeposits',
  queryKey: ({}) => ['transactions', 'microdeposits'],
}

export const transactionsUpdateMicrodepositsInternal: OperationData<Types.TransactionsUpdateMicrodepositsInternal> = {
  method: 'post',
  name: 'transactionsUpdateMicrodepositsInternal',
  summary: `Update processed microdeposits.`,
  path: '/transactions/microdeposits',
  queryKey: ({}) => ['transactions', 'microdeposits'],
}

export const microdepositsSendReminders: OperationData<Types.MicrodepositsSendReminders> = {
  method: 'post',
  name: 'microdepositsSendReminders',
  summary: `Send microdeposit reminders.`,
  path: '/transactions/microdeposits/reminders',
  queryKey: ({}) => ['transactions', 'microdeposits', 'reminders'],
}

export const internalTransactionsGetLoanIds: OperationData<Types.InternalTransactionsGetLoanIds> = {
  method: 'post',
  name: 'internalTransactionsGetLoanIds',
  summary: `Get the loans associated with a list of transaction. Returns a map from transaction public ID to loan public ID.
Null values represent transactions with no associated loan.`,
  path: '/transactions/loan-ids',
  queryKey: ({}) => ['transactions', 'loan-ids'],
}

export const transactionsGetRawInternalHandler: OperationData<Types.TransactionsGetRawInternalHandler> = {
  method: 'get',
  name: 'transactionsGetRawInternalHandler',
  summary: `Get all scheduled transactions`,
  path: '/companies/{companyId}/loan-types/{loanTypeId}/transactions',
  queryKey: ({ companyId, loanTypeId }) => ['companies', companyId, 'loan-types', loanTypeId, 'transactions'],
}

export const getReconciliationTransactionsByCompany: OperationData<Types.GetReconciliationTransactionsByCompany> = {
  method: 'get',
  name: 'getReconciliationTransactionsByCompany',
  summary: `Generic endpoint for getting transactions related to a company. Currently used for getting reconciliation transactions.`,
  path: '/companies/{companyId}/transactions',
  queryKey: ({ companyId }) => ['companies', companyId, 'transactions'],
}

export const createReconciliation: OperationData<Types.CreateReconciliation> = {
  method: 'post',
  name: 'createReconciliation',
  summary: `Create transactions related to settlements and investors, such as a reconciliation transaction.`,
  path: '/companies/{companyId}/transactions',
  queryKey: ({ companyId }) => ['companies', companyId, 'transactions'],
}

export const companyTransactionsGetTransitional: OperationData<Types.CompanyTransactionsGetTransitional> = {
  method: 'get',
  name: 'companyTransactionsGetTransitional',
  summary: `Transactions that may be due for automatic transitioning from pending`,
  path: '/companies/{companyId}/transactions/auto-transition',
  queryKey: ({ companyId }) => ['companies', companyId, 'transactions', 'auto-transition'],
}

export const transactionsGetAllExpiredHoldDays: OperationData<Types.TransactionsGetAllExpiredHoldDays> = {
  method: 'get',
  name: 'transactionsGetAllExpiredHoldDays',
  summary: `Transactions that have an expired hold_days_end_date and are either initiated or pending status.`,
  path: '/transactions/expired-hold-days',
  queryKey: ({}) => ['transactions', 'expired-hold-days'],
}

export const getRoutingNumber: OperationData<Types.GetRoutingNumber> = {
  method: 'get',
  name: 'getRoutingNumber',
  summary: `Get routing number details`,
  path: '/routing-numbers/{routingNumber}',
  queryKey: ({ routingNumber }) => ['routing-numbers', routingNumber],
}

export const getBinNumber: OperationData<Types.GetBinNumber> = {
  method: 'post',
  name: 'getBinNumber',
  summary: `Get BIN details from card number`,
  path: '/bin-numbers',
  queryKey: ({}) => ['bin-numbers'],
}

export const autopayGetAllInternalCompany: OperationData<Types.AutopayGetAllInternalCompany> = {
  method: 'get',
  name: 'autopayGetAllInternalCompany',
  summary: `Get all active autopays`,
  path: '/companies/{companyId}/autopays',
  queryKey: ({ companyId }) => ['companies', companyId, 'autopays'],
}

export const autopayGetAllInternal: OperationData<Types.AutopayGetAllInternal> = {
  method: 'get',
  name: 'autopayGetAllInternal',
  summary: `Get all active autopays`,
  path: '/autopays',
  queryKey: ({}) => ['autopays'],
}

export const getRepaymentEngineInfo: OperationData<Types.GetRepaymentEngineInfo> = {
  method: 'get',
  name: 'getRepaymentEngineInfo',
  summary: `Get all RPE fields for a given loan`,
  path: '/companies/{companyId}/loans/{loanId}/repayment-engine-info',
  queryKey: ({ companyId, loanId }) => ['companies', companyId, 'loans', loanId, 'repayment-engine-info'],
}

export const hasher: OperationData<Types.Hasher> = {
  method: 'post',
  name: 'hasher',
  summary: `The hasher`,
  path: '/internal/hasher',
  queryKey: ({}) => ['internal', 'hasher'],
}

export const getPeriods: OperationData<Types.GetPeriods> = {
  method: 'get',
  name: 'getPeriods',
  summary: `Get loan periods for all companies`,
  path: '/loan-periods',
  queryKey: ({}) => ['loan-periods'],
}

export const createStatement: OperationData<Types.CreateStatement> = {
  method: 'post',
  name: 'createStatement',
  summary: `Create a statement`,
  path: '/loan-periods/{periodId}/statement',
  queryKey: ({ periodId }) => ['loan-periods', periodId, 'statement'],
}

export const internalSaveAutopay: OperationData<Types.InternalSaveAutopay> = {
  method: 'post',
  name: 'internalSaveAutopay',
  summary: `Save statement autopay`,
  path: '/people/{personId}/loans/{loanId}/statements/{statementId}/save-autopay',
  queryKey: ({ personId, loanId, statementId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'statements',
    statementId,
    'save-autopay',
  ],
}

export const internalGenerateStatement: OperationData<Types.InternalGenerateStatement> = {
  method: 'post',
  name: 'internalGenerateStatement',
  summary: `Generate statement`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/periods/{periodId}/generate-statement',
  queryKey: ({ companyId, personId, loanId, periodId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'periods',
    periodId,
    'generate-statement',
  ],
}

export const revokeOrReinstateGrace: OperationData<Types.RevokeOrReinstateGrace> = {
  method: 'post',
  name: 'revokeOrReinstateGrace',
  summary: `Revoke or reinstate grace period`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/revoke-or-reinstate-grace',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'revoke-or-reinstate-grace',
  ],
}

export const internalComputeBalancesForGrace: OperationData<Types.InternalComputeBalancesForGrace> = {
  method: 'post',
  name: 'internalComputeBalancesForGrace',
  summary: `Revoke or reinstate grace period`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/compute-balances-for-grace',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'compute-balances-for-grace',
  ],
}

export const internalConsentGet: OperationData<Types.InternalConsentGet> = {
  method: 'get',
  name: 'internalConsentGet',
  summary: `Get consents`,
  path: '/companies/{companyId}/people/{personId}/consents',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId, 'consents'],
}

export const internalConsentUpdate: OperationData<Types.InternalConsentUpdate> = {
  method: 'put',
  name: 'internalConsentUpdate',
  summary: `Update consents`,
  path: '/companies/{companyId}/people/{personId}/consents',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId, 'consents'],
}

export const transactionsCreateVirtual: OperationData<Types.TransactionsCreateVirtual> = {
  method: 'post',
  name: 'transactionsCreateVirtual',
  summary: `Create virtual transaction`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/virtual-transactions',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'virtual-transactions',
  ],
}

export const internalLoanRefundsGet: OperationData<Types.InternalLoanRefundsGet> = {
  method: 'get',
  name: 'internalLoanRefundsGet',
  summary: `Get refunds`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/refunds',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'refunds',
  ],
}

export const internalLoanRefundsPost: OperationData<Types.InternalLoanRefundsPost> = {
  method: 'post',
  name: 'internalLoanRefundsPost',
  summary: `Create refund`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/refunds',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'refunds',
  ],
}

export const internalLoanRefundsGetOne: OperationData<Types.InternalLoanRefundsGetOne> = {
  method: 'get',
  name: 'internalLoanRefundsGetOne',
  summary: `Get refund by id`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/refunds/{loanRefundId}',
  queryKey: ({ companyId, personId, loanId, loanRefundId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'refunds',
    loanRefundId,
  ],
}

export const internalInstallmentRefundUpdate: OperationData<Types.InternalInstallmentRefundUpdate> = {
  method: 'put',
  name: 'internalInstallmentRefundUpdate',
  summary: `Update refund`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/refunds/{loanRefundId}',
  queryKey: ({ companyId, personId, loanId, loanRefundId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'refunds',
    loanRefundId,
  ],
}

export const internalLoanRefundsCancel: OperationData<Types.InternalLoanRefundsCancel> = {
  method: 'post',
  name: 'internalLoanRefundsCancel',
  summary: `Cancel refund`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/refunds/{loanRefundId}/cancel',
  queryKey: ({ companyId, personId, loanId, loanRefundId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'refunds',
    loanRefundId,
    'cancel',
  ],
}

export const internalLoanRefundsV2Post: OperationData<Types.InternalLoanRefundsV2Post> = {
  method: 'post',
  name: 'internalLoanRefundsV2Post',
  summary: `Create refund v2`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/refunds-v2',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'refunds-v2',
  ],
}

export const internalLoanActivate: OperationData<Types.InternalLoanActivate> = {
  method: 'post',
  name: 'internalLoanActivate',
  summary: `Activate loan`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/activate',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'activate',
  ],
}

export const loanUpdateMigrationStatus: OperationData<Types.LoanUpdateMigrationStatus> = {
  method: 'put',
  name: 'loanUpdateMigrationStatus',
  summary: `Update migration status`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/migration-status',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'migration-status',
  ],
}

export const expandUrl: OperationData<Types.ExpandUrl> = {
  method: 'get',
  name: 'expandUrl',
  summary: `Short URL expander`,
  path: '/expand-url/{shortId}',
  queryKey: ({ shortId }) => ['expand-url', shortId],
}

export const internalGetStatements: OperationData<Types.InternalGetStatements> = {
  method: 'get',
  name: 'internalGetStatements',
  summary: `Get statements`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/statements',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'statements',
  ],
}

export const deleteStatement: OperationData<Types.DeleteStatement> = {
  method: 'delete',
  name: 'deleteStatement',
  summary: `Delete statement`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/statements/{statementId}',
  queryKey: ({ companyId, personId, loanId, statementId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'statements',
    statementId,
  ],
}

export const internalLoanDrawLocIdGet: OperationData<Types.InternalLoanDrawLocIdGet> = {
  method: 'get',
  name: 'internalLoanDrawLocIdGet',
  summary: `Get Draw Line of Credit ID`,
  path: '/loans/draws/{drawId}/line-of-credit-id',
  queryKey: ({ drawId }) => ['loans', 'draws', drawId, 'line-of-credit-id'],
}

export const internalLoanDrawsGet: OperationData<Types.InternalLoanDrawsGet> = {
  method: 'get',
  name: 'internalLoanDrawsGet',
  summary: `Get draws`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws',
  queryKey: ({ companyId, personId, loanId }) => ['companies', companyId, 'people', personId, 'loans', loanId, 'draws'],
}

export const internalLoanDrawsPost: OperationData<Types.InternalLoanDrawsPost> = {
  method: 'post',
  name: 'internalLoanDrawsPost',
  summary: `Create draw`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws',
  queryKey: ({ companyId, personId, loanId }) => ['companies', companyId, 'people', personId, 'loans', loanId, 'draws'],
}

export const internalDrawActivate: OperationData<Types.InternalDrawActivate> = {
  method: 'post',
  name: 'internalDrawActivate',
  summary: `Activate draw`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws/{drawId}/activate',
  queryKey: ({ companyId, personId, loanId, drawId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'activate',
  ],
}

export const internalLoanDrawsGetWithoutBorrower: OperationData<Types.InternalLoanDrawsGetWithoutBorrower> = {
  method: 'get',
  name: 'internalLoanDrawsGetWithoutBorrower',
  summary: `Get draws`,
  path: '/companies/{companyId}/loans/{loanId}/draws',
  queryKey: ({ companyId, loanId }) => ['companies', companyId, 'loans', loanId, 'draws'],
}

export const internalCloseLocIfInactive: OperationData<Types.InternalCloseLocIfInactive> = {
  method: 'post',
  name: 'internalCloseLocIfInactive',
  summary: `Close inactive line`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/close-inactive-line',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'close-inactive-line',
  ],
}

export const internalDrawChargeVariableDrawFee: OperationData<Types.InternalDrawChargeVariableDrawFee> = {
  method: 'post',
  name: 'internalDrawChargeVariableDrawFee',
  summary: `Charge variable draw fee`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws/{drawId}/charge-variable-draw-fee',
  queryKey: ({ companyId, personId, loanId, drawId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'charge-variable-draw-fee',
  ],
}

export const internalClearMinPayments: OperationData<Types.InternalClearMinPayments> = {
  method: 'post',
  name: 'internalClearMinPayments',
  summary: `Refresh draw min payments during a replay`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/clear-min-payments',
  queryKey: ({ companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'clear-min-payments',
  ],
}

export const refreshAmortizedDraw: OperationData<Types.RefreshAmortizedDraw> = {
  method: 'post',
  name: 'refreshAmortizedDraw',
  summary: `Refresh payments after purchase`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws/{drawId}/refresh',
  queryKey: ({ companyId, personId, loanId, drawId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'refresh',
  ],
}

export const internalDrawPurchasesGet: OperationData<Types.InternalDrawPurchasesGet> = {
  method: 'get',
  name: 'internalDrawPurchasesGet',
  summary: `Get purchases`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws/{drawId}/purchases',
  queryKey: ({ companyId, personId, loanId, drawId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
  ],
}

export const internalDrawPurchasesCreate: OperationData<Types.InternalDrawPurchasesCreate> = {
  method: 'post',
  name: 'internalDrawPurchasesCreate',
  summary: `Create purchase`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws/{drawId}/purchases',
  queryKey: ({ companyId, personId, loanId, drawId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
  ],
}

export const internalDrawPurchaseGetOne: OperationData<Types.InternalDrawPurchaseGetOne> = {
  method: 'get',
  name: 'internalDrawPurchaseGetOne',
  summary: `Get purchase by ID`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}',
  queryKey: ({ companyId, personId, loanId, drawId, purchaseId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
  ],
}

export const internalDrawPurchaseUpdate: OperationData<Types.InternalDrawPurchaseUpdate> = {
  method: 'put',
  name: 'internalDrawPurchaseUpdate',
  summary: `Update purchase`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}',
  queryKey: ({ companyId, personId, loanId, drawId, purchaseId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
  ],
}

export const locPurchaseGetOne: OperationData<Types.LocPurchaseGetOne> = {
  method: 'get',
  name: 'locPurchaseGetOne',
  summary: `Get purchase by ID without draw ID`,
  path: '/people/{personId}/loans/{loanId}/purchases/{purchaseId}',
  queryKey: ({ personId, loanId, purchaseId }) => ['people', personId, 'loans', loanId, 'purchases', purchaseId],
}

export const internalDrawPurchaseChargeFees: OperationData<Types.InternalDrawPurchaseChargeFees> = {
  method: 'post',
  name: 'internalDrawPurchaseChargeFees',
  summary: `Charge fees if necessary`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}/charge-fees',
  queryKey: ({ companyId, personId, loanId, drawId, purchaseId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
    'charge-fees',
  ],
}

export const internalGetCaseEscalation: OperationData<Types.InternalGetCaseEscalation> = {
  method: 'get',
  name: 'internalGetCaseEscalation',
  summary: `List case escalations`,
  path: '/companies/{companyId}/cases/{caseId}/escalations',
  queryKey: ({ companyId, caseId }) => ['companies', companyId, 'cases', caseId, 'escalations'],
}

export const internalCreateCaseEscalation: OperationData<Types.InternalCreateCaseEscalation> = {
  method: 'post',
  name: 'internalCreateCaseEscalation',
  summary: `Escalate or de-escalate a case`,
  path: '/companies/{companyId}/cases/{caseId}/escalations',
  queryKey: ({ companyId, caseId }) => ['companies', companyId, 'cases', caseId, 'escalations'],
}

export const loanStateGet: OperationData<Types.LoanStateGet> = {
  method: 'get',
  name: 'loanStateGet',
  summary: `Get loan state`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/state',
  queryKey: ({ companyId, personId, loanId }) => ['companies', companyId, 'people', personId, 'loans', loanId, 'state'],
}

export const loanStatePost: OperationData<Types.LoanStatePost> = {
  method: 'post',
  name: 'loanStatePost',
  summary: `Update loan state`,
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/state',
  queryKey: ({ companyId, personId, loanId }) => ['companies', companyId, 'people', personId, 'loans', loanId, 'state'],
}

export const companyRotateTokenizationKey: OperationData<Types.CompanyRotateTokenizationKey> = {
  method: 'post',
  name: 'companyRotateTokenizationKey',
  summary: `Rotate a company's encryption keys`,
  path: '/companies/{companyId}/rotate-tokenization-key',
  queryKey: ({ companyId }) => ['companies', companyId, 'rotate-tokenization-key'],
}

export const cleanPaymentInstruments: OperationData<Types.CleanPaymentInstruments> = {
  method: 'post',
  name: 'cleanPaymentInstruments',
  summary: `Clean out tokens of card based payment instruments that have not been used in the past 2 years`,
  path: '/companies/{companyId}/payment-instruments/clean',
  queryKey: ({ companyId }) => ['companies', companyId, 'payment-instruments', 'clean'],
}

export const testSpawnTask: OperationData<Types.TestSpawnTask> = {
  method: 'get',
  name: 'testSpawnTask',
  summary: `Test operation`,
  path: '/internal/operations/test',
  queryKey: ({}) => ['internal', 'operations', 'test'],
}

export const chargebackReportPost: OperationData<Types.ChargebackReportPost> = {
  method: 'post',
  name: 'chargebackReportPost',
  summary: `Process chargeback reports`,
  path: '/sendgrid/webhooks/chargeback-report',
  queryKey: ({}) => ['sendgrid', 'webhooks', 'chargeback-report'],
}

export const internalOperationStatusGet: OperationData<Types.InternalOperationStatusGet> = {
  method: 'get',
  name: 'internalOperationStatusGet',
  summary: `Get operation status`,
  path: '/internal/operations/{operationId}',
  queryKey: ({ operationId }) => ['internal', 'operations', operationId],
}

export const getMissingSnapshots: OperationData<Types.GetMissingSnapshots> = {
  method: 'get',
  name: 'getMissingSnapshots',
  summary: `Get missing snapshots`,
  path: '/companies/{companyId}/snapshots/missing',
  queryKey: ({ companyId }) => ['companies', companyId, 'snapshots', 'missing'],
}

export const internalGetPaymentProcessors: OperationData<Types.InternalGetPaymentProcessors> = {
  method: 'get',
  name: 'internalGetPaymentProcessors',
  summary: `Get payment processors`,
  path: '/companies/{companyId}/payment-processors',
  queryKey: ({ companyId }) => ['companies', companyId, 'payment-processors'],
}

export const internalCreatePaymentProcessor: OperationData<Types.InternalCreatePaymentProcessor> = {
  method: 'post',
  name: 'internalCreatePaymentProcessor',
  summary: `Create payment processor`,
  path: '/companies/{companyId}/payment-processors',
  queryKey: ({ companyId }) => ['companies', companyId, 'payment-processors'],
}

export const internalGetOnePaymentProcessor: OperationData<Types.InternalGetOnePaymentProcessor> = {
  method: 'get',
  name: 'internalGetOnePaymentProcessor',
  summary: `Get payment processor`,
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}',
  queryKey: ({ companyId, paymentProcessorId }) => ['companies', companyId, 'payment-processors', paymentProcessorId],
}

export const internalUpdatePaymentProcessor: OperationData<Types.InternalUpdatePaymentProcessor> = {
  method: 'put',
  name: 'internalUpdatePaymentProcessor',
  summary: `Update payment processor`,
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}',
  queryKey: ({ companyId, paymentProcessorId }) => ['companies', companyId, 'payment-processors', paymentProcessorId],
}

export const internalDeletePaymentProcessor: OperationData<Types.InternalDeletePaymentProcessor> = {
  method: 'delete',
  name: 'internalDeletePaymentProcessor',
  summary: `Delete payment processor`,
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}',
  queryKey: ({ companyId, paymentProcessorId }) => ['companies', companyId, 'payment-processors', paymentProcessorId],
}

export const internalGetAchFiles: OperationData<Types.InternalGetAchFiles> = {
  method: 'get',
  name: 'internalGetAchFiles',
  summary: `Get ACH file monitoring records`,
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/ach-files',
  queryKey: ({ companyId, paymentProcessorId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'ach-files',
  ],
}

export const internalCreateAchFile: OperationData<Types.InternalCreateAchFile> = {
  method: 'post',
  name: 'internalCreateAchFile',
  summary: `Create ACH file monitoring record`,
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/ach-files',
  queryKey: ({ companyId, paymentProcessorId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'ach-files',
  ],
}

export const internalGetOneAchFile: OperationData<Types.InternalGetOneAchFile> = {
  method: 'get',
  name: 'internalGetOneAchFile',
  summary: `Get ACH file upload monitoring record`,
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/ach-files/{achFileId}',
  queryKey: ({ companyId, paymentProcessorId, achFileId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'ach-files',
    achFileId,
  ],
}

export const internalUpdateAchFile: OperationData<Types.InternalUpdateAchFile> = {
  method: 'put',
  name: 'internalUpdateAchFile',
  summary: `Update ACH file upload monitoring record`,
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/ach-files/{achFileId}',
  queryKey: ({ companyId, paymentProcessorId, achFileId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'ach-files',
    achFileId,
  ],
}

export const getLoanData: OperationData<Types.GetLoanData> = {
  method: 'get',
  name: 'getLoanData',
  summary: `Get data needed for loan ingest processor.`,
  path: '/companies/{companyId}/loans/{loanId}/loan-ingest',
  queryKey: ({ companyId, loanId }) => ['companies', companyId, 'loans', loanId, 'loan-ingest'],
}

export const getSsoToken: OperationData<Types.GetSsoToken> = {
  method: 'get',
  name: 'getSsoToken',
  summary: `Generate Zendesk SSO JWT`,
  path: '/zendesk/sso-token',
  queryKey: ({}) => ['zendesk', 'sso-token'],
}

export const setSsoSharedSecret: OperationData<Types.SetSsoSharedSecret> = {
  method: 'post',
  name: 'setSsoSharedSecret',
  summary: `Set the Zendesk SSO shared secret for this company.`,
  path: '/companies/{companyId}/zendesk/sso-shared-secret',
  queryKey: ({ companyId }) => ['companies', companyId, 'zendesk', 'sso-shared-secret'],
}

export const publishMessage: OperationData<Types.PublishMessage> = {
  method: 'post',
  name: 'publishMessage',
  summary: `Publish a message to a pubsub queue,
for internal use only!`,
  path: '/publish-message',
  queryKey: ({}) => ['publish-message'],
}
