import { useEffect, useState } from 'react'

export default function UnhandledExceptions({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [error, setError] = useState<string | null>(null)

  const handleUnhandledException = (ev: PromiseRejectionEvent) => setError(ev.reason)

  useEffect(() => {
    window.addEventListener('unhandledrejection', handleUnhandledException)
    return () => window.removeEventListener('unhandledrejection', handleUnhandledException)
  }, [])

  if (error) throw error

  return <>{children}</>
}
