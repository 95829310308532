import { LOADING, getStoredToken } from 'core/actions/constants'
import { APIendpoint } from 'core/actions/helpers/tokenHelpers'
import { POSTwithToken } from 'core/api/legacyApi'

export const GENERATE_TOKEN_SUCCESS = 'GENERATE_TOKEN_SUCCESS'
export const GENERATE_TOKEN_ERROR = 'GENERATE_TOKEN_ERROR'

export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR'

export const changePassword = ({ key, password }) => {
  return async (dispatch) => {
    if (key) {
      dispatch({ type: LOADING, key, status: 'loading' })
    }
    const username = localStorage.getItem('username')

    const body = { username, password }

    const endpoint = `${APIendpoint}/auth/password`
    const response = await fetch(endpoint, POSTwithToken(getStoredToken(), body))
    const isSuccess = response.status >= 200 && response.status <= 299
    const badRequest = response.status === 400

    if (isSuccess) {
      dispatch({ type: UPDATE_PASSWORD_SUCCESS })
    }

    if (badRequest) {
      dispatch({ type: UPDATE_PASSWORD_ERROR })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error',
      })
    }
  }
}
