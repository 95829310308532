import { FC, useEffect, useState } from 'react'

import { AuthTypes } from 'noauth/pages/Login/LoginWrapper/LoginInfoProvider'
import qs from 'qs'
import { useMount } from 'react-use'

import { AnimatedInput } from 'core/components'
import { useWrite } from 'core/hooks'
import { ExpectedError } from 'core/system/ErrorLogging'

import { requestOneTimeCode } from './loginApi'
import LoginFormCard from './LoginFormCard'
import { minDelay } from './loginHelpers'
import LoginSubmitButton from './LoginSubmitButton'
import { useLoginInfo } from './LoginWrapper'
import OneTimeCodeModal from './OneTimeCode/OneTimeCodeModal'
import { formatOtcError } from './OneTimeCode/otcHelpers'

type CodeLoginProps = {
  onLogin: ({
    username,
    password,
    selectedAuthType,
  }: {
    username: string
    password: string
    selectedAuthType: AuthTypes.OneTimeCodeEmail | AuthTypes.OneTimeCodeText
  }) => void
}

const CodeLogin: FC<CodeLoginProps> = ({ onLogin }) => {
  const { companyId, userType, authType, forceAuthType } = useLoginInfo()
  const [username, setUsername] = useState(
    () => (qs.parse(window.location.search, { ignoreQueryPrefix: true })?.value as string) ?? '',
  )

  /* 
  oneTimeCodeEmail => 'email' is the default OTC channel
  oneTimeCodeText => 'text' is the default channel
  */
  const initialChannel = authType === 'oneTimeCodeEmail' ? 'email' : 'text'
  const authValueLabel = authType === 'oneTimeCodeEmail' ? 'Email' : 'Phone number'

  const onRequestCodeToChannel = async (channel: 'email' | 'text') =>
    requestOneTimeCode({ username, companyId, userType, channel, force: forceAuthType })

  const [requestCode, requesting, resp, error, clear] = useWrite(async () => {
    if (!username) throw new ExpectedError(`${authValueLabel} is required`)

    return minDelay(onRequestCodeToChannel(initialChannel), 750)
  })

  useEffect(() => {
    clear()
  }, [username, clear])

  // Remove the `value` query param from URL if it exists and update the browser history
  useMount(() => {
    const url = new URL(window.location.href)
    if (url.searchParams.has('value')) {
      url.searchParams.delete('value')
      window.history.pushState({}, '', url)
    }
  })

  return (
    <LoginFormCard>
      <AnimatedInput
        label={authValueLabel}
        maskedType={authType === 'oneTimeCodeEmail' ? undefined : 'tel'}
        name={authType}
        onChange={(event) => {
          // BE should be case insensitive,
          // but also setting to lowercase for aesthetic reasons for the user
          setUsername(event.target.value.toLowerCase())
        }}
        placeholder=' '
        showError={error && !username}
        type='text'
        value={username}
        disabled={requesting}
        onEnter={() => username && !requesting && requestCode()}
      />

      <LoginSubmitButton
        onSubmit={requestCode}
        sending={requesting}
        errorMessage={formatOtcError(error)}
        subtitle="We'll send you a verification code"
        disabled={!!resp}
      />

      {resp && (
        <OneTimeCodeModal
          onOneTimeCode={(code, authType) => onLogin({ username, password: code, selectedAuthType: authType })}
          userType={userType}
          onRequestCodeToChannel={onRequestCodeToChannel}
          onClose={clear}
          initialChannel={initialChannel}
        />
      )}
    </LoginFormCard>
  )
}

export default CodeLogin
