import * as PopoverPrimitive from '@radix-ui/react-popover'
import styled from 'styled-components'

import { variables } from 'core/styles'

export const Popover = PopoverPrimitive.Root
export const PopoverAnchor = PopoverPrimitive.Anchor
export const PopoverTrigger = PopoverPrimitive.Trigger
export const PopoverContent = styled(PopoverPrimitive.Content)`
  z-index: 10;
  padding: 16px;
  background-color: ${variables.colorWhite};
  border-radius: 8px;
  box-shadow: ${variables.peachyShadowSmallDown};
`
export const PopoverClose = PopoverPrimitive.Close
