// Third party ------------------------
import { useState } from 'react'

import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'

// Proprietary ------------------------

import Icon from 'core/components/Icon'
import customStyles from 'core/components/legacy/Select/customStyles'
import useOnMount from 'core/hooks/useOnMount'
import variables from 'core/styles/variables'

// Components -------------------------

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon color={variables.colorBlack90} fontSize='24px' name='arrow_drop_down' />
      </components.DropdownIndicator>
    )
  )
}

const InlineDropdown = ({
  className,
  defaultValue,
  isDisabled,
  formatOptionLabel,
  getSelectionValue,
  isControlled,
  isCreatable,
  maxMenuHeight,
  menuPlacement,
  multiSelect,
  onChange,
  options,
  placeholder,
  value,
  wrap,
}) => {
  const [selectedOption, setSelectedOption] = useState(null)

  useOnMount(() => {
    if (defaultValue) {
      const op = options && options.find((o) => o.value === defaultValue)
      setSelectedOption({
        label: op ? op.label : defaultValue,
        value: defaultValue,
      })
    }
  })

  // Methods and helpers --------------
  const _handleSelectChange = (selectedOption) => {
    if (!selectedOption) {
      const op = options && options.find((o) => o.value === defaultValue && o.value !== undefined)
      setSelectedOption({
        label: op ? op.label : defaultValue,
        value: defaultValue,
      })
    } else {
      setSelectedOption(selectedOption)
    }

    onChange && onChange(selectedOption)
    getSelectionValue && getSelectionValue(selectedOption?.value)
  }

  if (isCreatable) {
    return (
      <CreatableSelect
        className={className}
        classNamePrefix='inline'
        components={{ DropdownIndicator }}
        creatable
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        isClearable={false}
        isControlled={isControlled}
        isMulti={multiSelect}
        isSearchable
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
        onChange={_handleSelectChange}
        options={options}
        placeholder={placeholder}
        styles={customStyles}
        value={isControlled ? value : selectedOption}
        controlledValue={isControlled ? selectedOption : undefined}
        wrap={wrap}
      />
    )
  }

  return (
    <Select
      className={className}
      classNamePrefix='inline'
      components={{ DropdownIndicator }}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      formatOptionLabel={formatOptionLabel}
      isControlled={isControlled}
      isMulti={multiSelect}
      isSearchable={false}
      maxMenuHeight={maxMenuHeight}
      menuPlacement={menuPlacement}
      onChange={_handleSelectChange}
      options={options}
      placeholder={placeholder}
      styles={customStyles}
      value={isControlled ? value : selectedOption}
      controlledValue={isControlled ? selectedOption : undefined}
      wrap={wrap}
    />
  )
}

InlineDropdown.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  isCreatable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  formatOptionLabel: PropTypes.func,
  getSelectionValue: PropTypes.func,
  isControlled: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
  menuPlacement: PropTypes.string,
  multiSelect: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  wrap: PropTypes.bool,
}

InlineDropdown.defaultProps = {
  onChange: () => {},
}

export default InlineDropdown
