export const featureFlags = {
  additinalContactsRewrite: 'additinalContactsRewrite',
  borrowerDisableSmsLoginOption: 'borrowerDisableSmsLoginOption',
  callNotes: 'callNotes',
  caseDetailsRewrite: 'caseDetailsRewrite',
  caseEvents: 'caseEvents',
  chargeFeeTool: 'chargeFeeTool',
  crmCallHold: 'crmCallHold',
  crmCaseAssignment: 'crmCaseAssignment',
  dashboardTool: 'dashboardTool',
  draftEmailRewrite: 'draftEmailRewrite',
  draftMailRewrite: 'draftMailRewrite',
  draftTextRewrite: 'draftTextRewrite',
  drawFundsShowButton: 'drawFundsShowButton',
  enableTemplateEditing: 'enableTemplateEditing',
  interactionRewrite: 'interactionRewrite',
  issueRefundTool: 'issueRefundTool',
  loanLabels: 'loanLabels',
  manageAutopayRewrite: 'manageAutopayRewrite',
  manageCardStatus: 'manageCardStatus',
  manageRefundsTool: 'manageRefundsTool',
  objectChangedEvents: 'objectChangedEvents',
  pastMailRewrite: 'pastMailRewrite',
  paymentPlan: 'paymentPlan',
  promiseToPay: 'promiseToPay',
  summarizeCase: 'summarizeCase',
  supercaseEvents: 'supercaseEvents',
  telephonyRewrite: 'telephonyRewrite',
  telephonyRewriteInbound: 'telephonyRewriteInbound',
}
