import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from 'core/components/lib/Button'
import TitleWithEducationTip from 'core/components/lib/TitleWithEducationTip'

const Wrapper = styled.div`
  padding: 24px;
`

const Content = styled.div`
  margin: 0 0 32px;
`

const Actions = styled.div`
  text-align: right;

  button {
    display: inline-block;

    &:nth-child(2) {
      margin-left: 16px;
    }
  }
`

/** @deprecated use Dialog instead */
const LegacyDialog = ({
  title,
  confirmLabel,
  onConfirm,
  cancelLabel,
  onCancel,
  disabled,
  helpContent,
  helpTitle,
  children,
}) => {
  return (
    <Wrapper>
      {(title || helpContent) && <TitleWithEducationTip helpText={helpContent} helpTitle={helpTitle} title={title} />}
      <Content>{children}</Content>
      <Actions>
        {confirmLabel && cancelLabel ?
          <>
            <Button onClick={onCancel}>{cancelLabel}</Button>
            <Button onClick={onConfirm} secondary readOnly={disabled}>
              {confirmLabel}
            </Button>
          </>
        : <Button onClick={onConfirm} secondary>
            {confirmLabel}
          </Button>
        }
      </Actions>
    </Wrapper>
  )
}

LegacyDialog.propTypes = {
  title: PropTypes.string,
  confirmLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  cancelLabel: PropTypes.string,
  onCancel: PropTypes.func,
  /** If true, the confirm button is disabled. */
  disabled: PropTypes.bool,
  helpContent: PropTypes.node,
  helpTitle: PropTypes.string,
  children: PropTypes.node,
}

export default LegacyDialog
