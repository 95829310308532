import { FC, PropsWithChildren } from 'react'

import { Close } from '@radix-ui/react-dialog'

import Button from 'core/components/lib/Button'
import Dialog, { DialogActions, DialogText } from 'core/components/lib/Dialog/Dialog'

export type AlertDialogProps = PropsWithChildren<{
  title: string
  content: string
  isOpen?: boolean
  onOpenChange?: (value: boolean) => void
}>

const AlertDialog: FC<AlertDialogProps> = ({ children, title, content, isOpen, onOpenChange }) => (
  <Dialog
    isOpen={isOpen}
    onOpenChange={onOpenChange}
    title={title}
    content={
      <>
        <DialogText>{content}</DialogText>
        <DialogActions>
          <Close asChild>
            <Button>Okay</Button>
          </Close>
        </DialogActions>
      </>
    }
  >
    {children}
  </Dialog>
)

export default AlertDialog
