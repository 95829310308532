import { Component, ComponentType, ErrorInfo, FC, PropsWithChildren } from 'react'
import React from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import { Scope } from '@sentry/react'
import { QueryErrorResetBoundary } from '@tanstack/react-query'
import { v4 as uuid4 } from 'uuid'

import ErrorLogging from 'core/system/ErrorLogging'
import { useUserType } from 'core/SystemProvider/UserTypeProvider'
import { PeachError, isError } from 'core/types'

import { FallbackProps } from './defaultErrorBoundaries'
import DefaultErrorFallback from './DefaultErrorFallback'
import DefaultReportErrorFallback, { ReportErrorFallbackProps } from './DefaultReportErrorFallback'
import DefaultTimeoutErrorFallback, { TimeoutErrorProps } from './DefaultTimeoutErroFallback'
import MaintenanceFallback from './MaintenanceFallback'

const enableSupportMessaging = import.meta.env.VITE_SHOW_CONTACT_SUPPORT_ON_RENDER_ERROR === 'true'

type ErrorBoundaryProps = PropsWithChildren<{
  className?: string
  ident?: string
  isAgent: boolean
  isAppRoot?: boolean
  onReset?: () => void
  ErrorFallback?: ComponentType<FallbackProps>
  TimeoutErrorFallback?: ComponentType<TimeoutErrorProps>
  ReportErrorFallback?: ComponentType<ReportErrorFallbackProps>
}>

type ErrorBoundaryState = {
  error?: unknown
  errorType?: 'fallback' | 'maintenance' | 'permissions' | 'report' | 'timeout'
  debugId?: string
  errorAt?: string
}

const initialState: ErrorBoundaryState = {
  error: undefined,
  errorType: undefined,
  debugId: undefined,
  errorAt: undefined,
}

// Error handler; captures logs depending on error status and renders fallbacks
class ErrorBoundaryHandler extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static defaultProps = {
    ident: 'Unknown',
    isAppRoot: false,
  }

  state = initialState

  static getDerivedStateFromError(error: unknown): ErrorBoundaryState {
    return {
      error,
      errorType:
        !isError(error) ? 'report'
        : error.status === 503 ? 'maintenance'
        : error.status === 403 ? 'permissions'
        : error.status === 504 ? 'timeout'
        : error.status < 500 ? 'fallback'
        : 'report',
      debugId: uuid4(),
      errorAt: new Date().toISOString(),
    }
  }

  componentDidCatch(error: unknown, errorInfo: ErrorInfo): void {
    const { ident } = this.props
    const { errorType, debugId } = this.state

    if (errorType === 'fallback' || errorType === 'report') {
      ErrorLogging.withScope((scope: Scope) => {
        scope.setTag('debugId', debugId)
        scope.setTag('ident', ident)
        ErrorLogging.captureException(error, {
          contexts: { react: errorInfo.componentStack },
        })
      })
      datadogLogs.logger.error('ErrorBoundary', { debugId, ident, error })
    }
  }

  reset = () => {
    if (this.props.onReset) this.props.onReset()
    this.setState(initialState)
  }

  render() {
    const { error, errorType, debugId, errorAt } = this.state
    const {
      className,
      children,
      isAppRoot,
      isAgent,
      TimeoutErrorFallback = DefaultTimeoutErrorFallback,
      ReportErrorFallback = DefaultReportErrorFallback,
      ErrorFallback = DefaultErrorFallback,
    } = this.props

    if (error) {
      if (errorType === 'maintenance') {
        if (isAppRoot) {
          return <MaintenanceFallback className={className} />
        } else {
          throw error
        }
      }

      if (errorType === 'timeout') {
        return (
          <TimeoutErrorFallback
            className={className}
            reset={this.reset}
            operation={isError(error) ? error?.operation : undefined}
          />
        )
      }

      if (isAgent && errorType === 'report' && enableSupportMessaging) {
        return (
          <ReportErrorFallback
            className={className}
            errorAt={errorAt ?? ''}
            debugId={debugId ?? ''}
            peachRequestId={(error as PeachError)?.peachRequestId ?? ''}
          />
        )
      }

      return <ErrorFallback className={className} error={error} />
    }

    return children
  }
}

// Wraps ErrorBoundaryHandler with QueryErrorResetBoundary for automatic query retries
// Passes isAgent boolean for fallback rendering logic
const ErrorBoundary: FC<Omit<ErrorBoundaryProps, 'isAgent'>> = ({ onReset, ...rest }) => {
  // this must be imported directly to avoid circular dependency
  const userType = useUserType()
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundaryHandler
          isAgent={userType === 'agent'}
          onReset={() => {
            if (onReset) onReset()
            reset()
          }}
          {...rest}
        />
      )}
    </QueryErrorResetBoundary>
  )
}

export default ErrorBoundary

// Higher order component; useful in situations where it might require adding an
// additional component to effectively isolate errors from a component
export function withErrorBoundary<T extends {}>(Component: FC<T>, errorBoundaryProps?: ErrorBoundaryProps) {
  const displayName = Component.displayName || Component.name || 'Unknown'
  const { onReset, ...errorBoundaryPropsRest } = errorBoundaryProps ?? {}
  const WrappedComponent: FC<T> = (props) => (
    <ErrorBoundary ident={displayName} onReset={onReset} {...(errorBoundaryPropsRest ?? {})}>
      <Component {...props} />
    </ErrorBoundary>
  )
  WrappedComponent.displayName = `withErrorBoundary${displayName}`
  return WrappedComponent
}
