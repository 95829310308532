import React from 'react'

import styled from 'styled-components'

import formatAddress from 'core/helpers/formatAddress'
import { useCompanyInfo } from 'core/hooks'
import variables from 'core/styles/variables'

const Wrapper = styled.div``

const CompanyInfoLine = styled.div`
  padding-bottom: 16px;
  font-size: 16px;
  color: ${variables.colorBlack70};
  white-space: pre-wrap;
`

const FooterCompanyInfo = () => {
  const { legalName, brandName, name, support } = useCompanyInfo()

  const displayName = legalName || brandName || name

  const addr = support?.mailingAddress

  const displayAddress = addr && formatAddress(addr, { twoLines: true })

  return (
    <Wrapper>
      {displayName && <CompanyInfoLine>{displayName}</CompanyInfoLine>}

      {displayAddress && <CompanyInfoLine>{displayAddress}</CompanyInfoLine>}
    </Wrapper>
  )
}

export default FooterCompanyInfo
