import { FC } from 'react'

import Button from 'core/components/lib/Button'

import { BoxContent, ErrorBox, ErrorTitle, FallbackProps } from './defaultErrorBoundaries'

export type TimeoutErrorProps = FallbackProps & {
  operation?: string
  reset: () => void
}

const DefaultTimeoutErrorFallback: FC<TimeoutErrorProps> = ({ className, operation, reset }) => (
  <ErrorBox className={className} $type='warning'>
    <ErrorTitle>Timed out</ErrorTitle>
    <BoxContent>
      {operation ? `This request has timed out: ${operation}. ` : 'A request has timed out. '}
      Would you like to{' '}
      <Button inline onClick={reset}>
        try again
      </Button>{' '}
      or{' '}
      <Button inline onClick={() => window.location.reload()}>
        reload the page
      </Button>
      ?
    </BoxContent>
  </ErrorBox>
)

export default DefaultTimeoutErrorFallback
