import { useMemo } from 'react'

import App from 'app/App'

import { Spinner, PeachOverrides } from 'core/components'
import useRead from 'core/hooks/useRead'

import { parseBoostrapInfo, formatTheme } from './customizationsHelpers'
import EnvProviders from './env/EnvProviders'
import fetchBootstrapData from './fetchBootstrapData'
import SystemProviders from './SystemProviders'

const BootstrapManager = () => {
  const [boostrap, loading, error] = useRead(fetchBootstrapData)

  const { appDomainType, authType, authValueType, colors, company, companyId, ssoUrl, timezone, userType } =
    parseBoostrapInfo(boostrap)

  const companyTheme = useMemo(() => formatTheme(colors || {}), [colors])

  if (error && error.status === 503) throw error
  if (!loading && !appDomainType) throw Error('appDomainType is undefined')
  if (!loading && !company) throw Error('company is undefined')

  const content =
    loading ?
      <Spinner height='100vh' />
    : <SystemProviders
        appDomainType={appDomainType}
        userType={userType}
        companyId={companyId}
        company={company}
        timezone={timezone}
      >
        <App
          appDomainType={appDomainType}
          userType={userType}
          companyId={companyId}
          authType={authType}
          authValueType={authValueType}
          companyTheme={companyTheme}
          ssoUrl={ssoUrl}
        />
        <PeachOverrides />
      </SystemProviders>

  return <EnvProviders>{content}</EnvProviders>
}

export default BootstrapManager
