import mtz from '../utils/mtz'

const makeFirstWeekdayAfter = (context = {}) => {
  const { timezone } = context

  const firstWeekdayAfter = (date, inclusive) => {
    const inputDate = mtz(date, timezone)
    const dateToCheck = inclusive ? inputDate.clone() : inputDate.clone().add(1, 'day')
    while (dateToCheck.isoWeekday() > 5) {
      dateToCheck.add(1, 'day')
    }

    return dateToCheck.format('Y-MM-DD')
  }

  return firstWeekdayAfter
}

export default makeFirstWeekdayAfter
