import { FC } from 'react'

import styled from 'styled-components'

import Textarea, { TextareaProps } from './Textarea'

const TextareaAutoHeight: FC<Omit<TextareaProps, 'height'> & { maxRows?: number }> = ({
  value,
  rows = 3,
  maxRows,
  ...props
}) => (
  <Box $rows={rows} $maxRows={maxRows}>
    <StyledTextarea value={value} {...props} />
    <GrowBox>{value}&#8203;</GrowBox>
  </Box>
)

export default TextareaAutoHeight

export const Box = styled.div<{ $rows: number; $maxRows?: number }>`
  position: relative;
  width: 100%;
  min-height: ${(p) => p.$rows * 24 + 18}px;
  ${({ $rows, $maxRows }) => (!!$maxRows && $maxRows >= $rows ? `max-height: ${$maxRows * 24 + 18}px;` : '')}
  font-size: 16px;
`

const StyledTextarea = styled(Textarea)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: inherit;
`

const GrowBox = styled.div`
  padding: 8px;
  white-space: pre-wrap;
  visibility: hidden;
  border: 1px solid transparent;
`
