// Third party --------------
import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

// Rest ---------------------
import capitalize from 'core/helpers/capitalizeFirstLetter'
import { formatPhone } from 'core/helpers/phoneNumberFormatter'
import variables from 'core/styles/variables'

const Label = styled.div`
  font-weight: normal;
  color: ${(p) => (p.isPlaceholder ? variables.colorBlack60 : 'inherit')};
`
const Sublabel = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${variables.colorBlack60};
  text-transform: capitalize;
`
const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const ContactsOptionLabel = (props) => {
  const {
    affiliation,
    displayLabel: displayLabelFromContact,
    isPlaceholder,
    isUserInputtedPhoneNumber,
    label,
    name,
    sublabel,
    value,
  } = props

  const formattedValue =
    value ?
      value.includes('+1') ?
        value
      : `+1${value}`
    : null
  const phone = formatPhone(formattedValue)

  const isSelf = affiliation === 'self'

  const newDisplayLabel =
    isUserInputtedPhoneNumber ? phone || 'Enter number or select contact'
    : isSelf ? `${capitalize(label)} ${phone}`
    : `${name ? `${name} ` : ''}${phone}`

  return (
    <Option>
      <Label isPlaceholder={isPlaceholder}>{displayLabelFromContact || newDisplayLabel}</Label>
      {sublabel && <Sublabel>{sublabel}</Sublabel>}
    </Option>
  )
}

ContactsOptionLabel.propTypes = {
  affiliation: PropTypes.string,
  displayLabel: PropTypes.string,
  group: PropTypes.string,
  isPlaceholder: PropTypes.bool,
  isUserInputtedPhoneNumber: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  sublabel: PropTypes.string,
  value: PropTypes.string,
}

export default ContactsOptionLabel
