import React, { Children, PropsWithChildren, ReactChild } from 'react'

import Icon from 'core/components/Icon'
import Tooltip from 'core/components/lib/Tooltip'
import { R } from 'core/helpers'
import variables from 'core/styles/variables'

import {
  StyledTable,
  StyledTableButtonWrapper,
  StyledTableCell,
  StyledTableHeaderCell,
  StyledTableHeaderContent,
  StyledTableRow,
  StyledTableValue,
  StyledTableWrapper,
} from './styles'
import { TableLoadingOverlay, TableTextOverlay } from './TableOverlay'

type TableProps<T extends string> = PropsWithChildren<{
  bordered?: boolean
  columns?: {
    label: ReactChild
    tooltip?: string
    key?: T
    sortable?: boolean
  }[]
  entitiesLabel?: string
  errorMessage?: string
  hideHeader?: boolean
  isError?: boolean
  isLoading?: boolean
  onSort?: (key: T | `-${T}`) => void
  sortBy?: T | `-${T}`
}>

const Table = <T extends string>({
  bordered = false,
  children,
  columns = [],
  entitiesLabel = 'results',
  errorMessage,
  hideHeader = false,
  isError = false,
  isLoading = false,
  onSort,
  sortBy,
}: TableProps<T>) => {
  const isEmpty = React.Children.count(children) === 0

  return (
    <StyledTableWrapper $bordered={bordered}>
      <StyledTable>
        {!hideHeader && (
          <thead>
            <tr>
              {R.map.indexed(columns ?? [], ({ key, label, tooltip, sortable }, index) => {
                const sorted = key === sortBy || '-' + key === sortBy

                return (
                  <StyledTableHeaderCell
                    onClick={sortable && key ? () => onSort?.(key === sortBy ? `-${key}` : key) : undefined}
                    key={`${key}-${index}`}
                    $sorted={sorted}
                  >
                    <StyledTableHeaderContent>
                      {label}
                      {sortable && (
                        <Icon
                          color={sorted ? variables.colorBlack90 : variables.colorBlack40}
                          name={sorted && sortBy?.startsWith('-') ? 'arrow_drop_down' : 'arrow_drop_up'}
                          fontSize='24px'
                          margin='0 8px 0 0'
                        />
                      )}
                      {tooltip && <Tooltip content={tooltip} iconMargin='0 0 0 8px' />}
                    </StyledTableHeaderContent>
                  </StyledTableHeaderCell>
                )
              })}
            </tr>
          </thead>
        )}
        <tbody>
          {isEmpty ?
            <StyledTableRow $bordered>
              <StyledTableCell>&nbsp;</StyledTableCell>
            </StyledTableRow>
          : children}
        </tbody>
      </StyledTable>

      {isLoading ?
        <TableLoadingOverlay />
      : isError ?
        <TableTextOverlay>{errorMessage ?? `Error loading ${entitiesLabel}`}</TableTextOverlay>
      : isEmpty ?
        <TableTextOverlay>No {entitiesLabel} found</TableTextOverlay>
      : null}
    </StyledTableWrapper>
  )
}

Table.Row = ({
  children,
  className,
  bordered = true,
  summary = false,
  highlight,
  onClick,
}: PropsWithChildren<{
  className?: string
  bordered?: boolean
  summary?: boolean
  highlight?: boolean
  onClick?: React.MouseEventHandler<HTMLTableRowElement>
}>) => (
  <StyledTableRow
    className={className}
    onClick={onClick}
    $highlight={highlight ?? !!onClick}
    $bordered={bordered}
    $summary={summary}
  >
    {Children.map(
      children,
      (child, index) =>
        child !== null && (
          <StyledTableCell
            $paddingTop={summary ? '12px' : undefined}
            $paddingBottom={summary ? '12px' : undefined}
            key={`wrapped-cell-${index}`}
          >
            {child}
          </StyledTableCell>
        ),
    )}
  </StyledTableRow>
)

Table.ValueWithEmphasis = ({
  children,
  textAlign = 'left',
}: {
  children: React.ReactNode
  textAlign?: 'left' | 'center' | 'right'
}) => (
  <StyledTableValue $deemphasized={!children || Number(children) === 0} $textAlign={textAlign}>
    {children ?? '—'}
  </StyledTableValue>
)

Table.CellActions = StyledTableButtonWrapper

export default Table
