import { OperationData } from 'core/types/makeMutationHook'
import {
  AddCaseAssociation,
  CreateInteractionNote,
  Employee,
  InternalEmployeeGetById,
  InternalEmployeesGet,
  LoanRefundsCancel,
  RemoveCaseAssociation,
  ResponseEmployee,
  ResponseEmployeesPaging,
  UpdateInteractionNote,
  ListUnterminatedTasks,
} from 'core/types/types'

import {
  addCaseAssociation as addCaseAssociationBase,
  createInteractionNote as createInteractionNoteBase,
  loanRefundsCancel as loanRefundsCancelBase,
  removeCaseAssociation as removeCaseAssociationBase,
  updateInteractionNote as updateInteractionNoteBase,
  listUnterminatedTasks as listUnterminatedTasksBase,
  internalEmployeesGet as internalEmployeesGetBase,
  internalEmployeeGetById as internalEmployeeGetByIdBase,
} from './generated/operations'

export * from './generated/operations'

export const addCaseAssociation = addCaseAssociationBase as OperationData<AddCaseAssociation>

export const removeCaseAssociation = removeCaseAssociationBase as OperationData<RemoveCaseAssociation>

export const updateInteractionNote = updateInteractionNoteBase as OperationData<UpdateInteractionNote>

export const createInteractionNote = createInteractionNoteBase as OperationData<CreateInteractionNote>

export const listUnterminatedTasks = listUnterminatedTasksBase as OperationData<ListUnterminatedTasks>

export const loanRefundsCancel = loanRefundsCancelBase as OperationData<LoanRefundsCancel>

export const internalEmployeesGet = internalEmployeesGetBase as OperationData<
  Omit<InternalEmployeesGet, 'response'> & {
    response: Omit<ResponseEmployeesPaging, 'data'> & {
      data?: Employee[]
    }
  }
>

export const internalEmployeeGetById = internalEmployeeGetByIdBase as OperationData<
  Omit<InternalEmployeeGetById, 'response'> & {
    response: Omit<ResponseEmployee, 'data'> & {
      data?: Employee[]
    }
  }
>
