import React, { createContext, useContext, useMemo } from 'react'

import PropTypes from 'prop-types'

import makeTime from './makeTime'

const TimeContext = createContext(makeTime())

/** Use this as an ancestor to anywhere you want to use the
 * corresponding hook useTime() to get all the helpers in the
 * library. This is a convenient way to establish context for
 * all date and time operations in your React components,
 * where the context depends on dynamic or loaded information.
 * All props except `children` are params that go into the
 * `context` object:
 * - timezone: IANA string of timezone, defaults to local
 *
 * More params may be added to context in the future, but
 * always with a default behavior when not specified.
 *
 * Example usage in a React component:
 * const App = () => {
 *   const timezone = getTimezoneYourSpecialWay()
 *
 *   return (
 *     <TimeProvider timezone={timezone}>
 *       <AllYourAmazingComponents />
 *     </TimeProvider>
 *   )
 * }
 *
 * Then you can do something like this in a descendant component:
 * const { format, formatRelative } = useTime()
 *
 * Note that if you need access to the helpers in this library
 * outside of React components or without using TimeProvider as
 * an ancestor, you can instead call makeTime(context), where
 * you provide the context directly. Also note that if useTime()
 * happens to be used without TimeProvider as an ancestor,
 * it will return the helper functions with the default context
 * (e.g. timezone set to local).
 */
export const TimeProvider = ({ children, timezone }) => {
  const time = useMemo(() => {
    return makeTime({ timezone })
  }, [timezone])

  return <TimeContext.Provider value={time}>{children}</TimeContext.Provider>
}

TimeProvider.propTypes = {
  children: PropTypes.node,
  timezone: PropTypes.string,
}

/** A React hook that returns all helpers in the time library,
 * where the context will have to be established by TimeProvider
 * higher up in the React tree.
 * `context` is an object with the following params:
 * - timezone: IANA string of timezone, defaults to local
 *
 * More params may be added to context in the future, but
 * always with a default behavior when not specified.
 *
 * Example usage in a React component:
 * const { format, formatRelative } = useTime()
 *
 * Note that if you need access to the helpers in this library
 * outside of React components or without using TimeProvider as
 * an ancestor, you can instead call makeTime(context), where
 * you provide the context directly. Also note that if useTime()
 * happens to be used without TimeProvider as an ancestor,
 * it will return the helper functions with the default context
 * (e.g. timezone set to local).
 */
export const useTime = () => useContext(TimeContext)
