/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const getDocumentUploadLinks: OperationData<Types.GetDocumentUploadLinks> = {
  method: 'get',
  name: 'getDocumentUploadLinks',
  summary: `Get document upload links`,
  path: '/people/{personId}/document-upload-links',
  queryKey: ({ personId }) => ['people', personId, 'document-upload-links'],
}

export const createDocumentUploadLink: OperationData<Types.CreateDocumentUploadLink> = {
  method: 'post',
  name: 'createDocumentUploadLink',
  summary: `Create document upload link`,
  path: '/people/{personId}/document-upload-links',
  queryKey: ({ personId }) => ['people', personId, 'document-upload-links'],
}

export const getDocumentUploadLink: OperationData<Types.GetDocumentUploadLink> = {
  method: 'get',
  name: 'getDocumentUploadLink',
  summary: `Get doc upload link by ID`,
  path: '/people/{personId}/document-upload-links/{documentUploadLinkId}',
  queryKey: ({ personId, documentUploadLinkId }) => ['people', personId, 'document-upload-links', documentUploadLinkId],
}

export const listPersonDocuments: OperationData<Types.ListPersonDocuments> = {
  method: 'get',
  name: 'listPersonDocuments',
  summary: `Get document descriptors`,
  path: '/people/{personId}/documents',
  queryKey: ({ personId }) => ['people', personId, 'documents'],
}

export const createPersonDocument: OperationData<Types.CreatePersonDocument> = {
  method: 'post',
  name: 'createPersonDocument',
  summary: `Create new document descriptor`,
  path: '/people/{personId}/documents',
  queryKey: ({ personId }) => ['people', personId, 'documents'],
}

export const listCompanyDocuments: OperationData<Types.ListCompanyDocuments> = {
  method: 'get',
  name: 'listCompanyDocuments',
  summary: `Get document descriptors`,
  path: '/companies/{companyId}/documents',
  queryKey: ({ companyId }) => ['companies', companyId, 'documents'],
}

export const createCompanyDocument: OperationData<Types.CreateCompanyDocument> = {
  method: 'post',
  name: 'createCompanyDocument',
  summary: `Create new document descriptor`,
  path: '/companies/{companyId}/documents',
  queryKey: ({ companyId }) => ['companies', companyId, 'documents'],
}

export const getPersonDocument: OperationData<Types.GetPersonDocument> = {
  method: 'get',
  name: 'getPersonDocument',
  summary: `Get document descriptor by ID`,
  path: '/people/{personId}/documents/{documentDescriptorId}',
  queryKey: ({ personId, documentDescriptorId }) => ['people', personId, 'documents', documentDescriptorId],
}

export const updatePersonDocument: OperationData<Types.UpdatePersonDocument> = {
  method: 'put',
  name: 'updatePersonDocument',
  summary: `Update document descriptor`,
  path: '/people/{personId}/documents/{documentDescriptorId}',
  queryKey: ({ personId, documentDescriptorId }) => ['people', personId, 'documents', documentDescriptorId],
}

export const deleteDocument: OperationData<Types.DeleteDocument> = {
  method: 'delete',
  name: 'deleteDocument',
  summary: `Delete document`,
  path: '/people/{personId}/documents/{documentDescriptorId}',
  queryKey: ({ personId, documentDescriptorId }) => ['people', personId, 'documents', documentDescriptorId],
}

export const getCompanyDocument: OperationData<Types.GetCompanyDocument> = {
  method: 'get',
  name: 'getCompanyDocument',
  summary: `Get document descriptor by ID`,
  path: '/companies/{companyId}/documents/{documentDescriptorId}',
  queryKey: ({ companyId, documentDescriptorId }) => ['companies', companyId, 'documents', documentDescriptorId],
}

export const updateCompanyDocument: OperationData<Types.UpdateCompanyDocument> = {
  method: 'put',
  name: 'updateCompanyDocument',
  summary: `Update document descriptor`,
  path: '/companies/{companyId}/documents/{documentDescriptorId}',
  queryKey: ({ companyId, documentDescriptorId }) => ['companies', companyId, 'documents', documentDescriptorId],
}

export const downloadPersonDocumentContent: OperationData<Types.DownloadPersonDocumentContent> = {
  method: 'get',
  name: 'downloadPersonDocumentContent',
  summary: `Download document content`,
  path: '/people/{personId}/documents/{documentDescriptorId}/content',
  queryKey: ({ personId, documentDescriptorId }) => ['people', personId, 'documents', documentDescriptorId, 'content'],
}

export const uploadPersonDocumentContent: OperationData<Types.UploadPersonDocumentContent> = {
  method: 'post',
  name: 'uploadPersonDocumentContent',
  summary: `Upload document content`,
  path: '/people/{personId}/documents/{documentDescriptorId}/content',
  queryKey: ({ personId, documentDescriptorId }) => ['people', personId, 'documents', documentDescriptorId, 'content'],
}

export const downloadCompanyDocumentContent: OperationData<Types.DownloadCompanyDocumentContent> = {
  method: 'get',
  name: 'downloadCompanyDocumentContent',
  summary: `Download document content`,
  path: '/companies/{companyId}/documents/{documentDescriptorId}/content',
  queryKey: ({ companyId, documentDescriptorId }) => [
    'companies',
    companyId,
    'documents',
    documentDescriptorId,
    'content',
  ],
}

export const uploadCompanyDocumentContent: OperationData<Types.UploadCompanyDocumentContent> = {
  method: 'post',
  name: 'uploadCompanyDocumentContent',
  summary: `Upload document content`,
  path: '/companies/{companyId}/documents/{documentDescriptorId}/content',
  queryKey: ({ companyId, documentDescriptorId }) => [
    'companies',
    companyId,
    'documents',
    documentDescriptorId,
    'content',
  ],
}

export const convertPersonDocument: OperationData<Types.ConvertPersonDocument> = {
  method: 'post',
  name: 'convertPersonDocument',
  summary: `Convert document to the specified format`,
  path: '/people/{personId}/documents/{documentDescriptorId}/convert',
  queryKey: ({ personId, documentDescriptorId }) => ['people', personId, 'documents', documentDescriptorId, 'convert'],
}

export const convertCompanyDocument: OperationData<Types.ConvertCompanyDocument> = {
  method: 'post',
  name: 'convertCompanyDocument',
  summary: `Convert document to the specified format`,
  path: '/companies/{companyId}/documents/{documentDescriptorId}/convert',
  queryKey: ({ companyId, documentDescriptorId }) => [
    'companies',
    companyId,
    'documents',
    documentDescriptorId,
    'convert',
  ],
}

export const signPersonPdfDocument: OperationData<Types.SignPersonPdfDocument> = {
  method: 'post',
  name: 'signPersonPdfDocument',
  summary: `Digitally sign document`,
  path: '/people/{personId}/documents/{documentDescriptorId}/sign',
  queryKey: ({ personId, documentDescriptorId }) => ['people', personId, 'documents', documentDescriptorId, 'sign'],
}

export const signCompanyPdfDocument: OperationData<Types.SignCompanyPdfDocument> = {
  method: 'post',
  name: 'signCompanyPdfDocument',
  summary: `Digitally sign document`,
  path: '/companies/{companyId}/documents/{documentDescriptorId}/sign',
  queryKey: ({ companyId, documentDescriptorId }) => [
    'companies',
    companyId,
    'documents',
    documentDescriptorId,
    'sign',
  ],
}

export const createDocumentViaLink: OperationData<Types.CreateDocumentViaLink> = {
  method: 'post',
  name: 'createDocumentViaLink',
  summary: `Create new document via link`,
  path: '/document-uploads/{documentUploadLinkKey}/documents',
  queryKey: ({ documentUploadLinkKey }) => ['document-uploads', documentUploadLinkKey, 'documents'],
}

export const uploadDocumentContentViaLink: OperationData<Types.UploadDocumentContentViaLink> = {
  method: 'post',
  name: 'uploadDocumentContentViaLink',
  summary: `Upload document content via link`,
  path: '/document-uploads/{documentUploadLinkKey}/documents/{documentDescriptorId}/content',
  queryKey: ({ documentUploadLinkKey, documentDescriptorId }) => [
    'document-uploads',
    documentUploadLinkKey,
    'documents',
    documentDescriptorId,
    'content',
  ],
}
