import { FC, MouseEvent, ReactNode } from 'react'

import { Link as RouterLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import variables from 'core/styles/variables'

const linkStyles = css`
  font-size: inherit;
  color: ${variables.colorBluePrimary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const StyledRouterLink = styled(RouterLink)`
  ${linkStyles};
`

const StyledExternalLink = styled.a`
  ${linkStyles};
`

const StyledButton = styled.button`
  display: inline;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;

  ${linkStyles};
`

const DisabledLink = styled.span``

export type LinkProps = {
  href?: string
  to?: string
  disabled?: boolean
  onClick?: (e?: MouseEvent) => void
  children: ReactNode
  // "...rest" is set as raw props on the component; not an object called "rest"
  // Ideally this should be separate components for an anchor tag and button tag
  // Then we could extend the props from HTMLAnchorElement and HTMLButtonElement
  [key: string]: any
}

const Link: FC<LinkProps> = ({ children, href, to, disabled = false, onClick, ...rest }) =>
  to && !disabled ?
    <StyledRouterLink role='link' to={to} {...rest} onClick={onClick}>
      {children}
    </StyledRouterLink>
  : href && !disabled ?
    <StyledExternalLink href={href} target='_blank' rel='noopener noreferrer' role='link' onClick={onClick} {...rest}>
      {children}
    </StyledExternalLink>
  : onClick && !disabled ?
    <StyledButton onClick={onClick} {...rest}>
      {children}
    </StyledButton>
  : <DisabledLink {...rest}>{children}</DisabledLink>

export default Link
