const BORROWER_LOANS_LOADING = 'BORROWER_LOANS_LOADING'
const BORROWER_LOANS_SUCCESS = 'BORROWER_LOANS_SUCCESS'
const BORROWER_LOANS_ERROR = 'BORROWER_LOANS_ERROR'
const BORROWER_LOAN_LOADING = 'BORROWER_LOAN_LOADING'
const BORROWER_LOAN_SUCCESS = 'BORROWER_LOAN_SUCCESS'
const BORROWER_LOAN_ERROR = 'BORROWER_LOAN_ERROR'

const borrowerLoansLoading = () => {
  return { type: BORROWER_LOANS_LOADING }
}

const borrowerLoansSuccess = () => {
  return { type: BORROWER_LOANS_SUCCESS }
}

const borrowerLoansError = () => {
  return { type: BORROWER_LOANS_ERROR }
}

export {
  BORROWER_LOANS_LOADING,
  BORROWER_LOANS_SUCCESS,
  BORROWER_LOANS_ERROR,
  BORROWER_LOAN_LOADING,
  BORROWER_LOAN_SUCCESS,
  BORROWER_LOAN_ERROR,
  borrowerLoansLoading,
  borrowerLoansSuccess,
  borrowerLoansError,
}
