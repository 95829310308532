import React, { useEffect, useState } from 'react'

import useDraft from 'contexts/DraftContext/useDraft'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'
import styled from 'styled-components'

import { Modal } from 'core/components/legacy'
import { InfoCard } from 'core/components/lib/InfoCard'
import SubmitSection from 'core/components/lib/SubmitSection'

const Message = styled.div`
  padding: 0 24px;
`

const RouteLeaveGuard = ({ when, navigate }) => {
  const [showModal, setShowModal] = useState(false)
  const [lastLocation, setLastLocation] = useState(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const { clearDrafts, alert } = useDraft()

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation) {
      setShowModal(true)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }
  const handleConfirmNavigationClick = () => {
    setShowModal(false)
    setConfirmedNavigation(true)
    clearDrafts()
  }
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname)
      setConfirmedNavigation(false)
      setLastLocation(null)
    }
  }, [confirmedNavigation, lastLocation, navigate])

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {showModal && (
        <Modal width={560} onClose={() => setShowModal(false)}>
          <InfoCard key='route-leave-guard' title={alert.title}>
            <Message>{alert.message}</Message>
            <SubmitSection
              submitPrimaryText='Confirm'
              onSubmitPrimary={handleConfirmNavigationClick}
              cancelText='Cancel'
              onCancel={() => setShowModal(false)}
            />
          </InfoCard>
        </Modal>
      )}
    </>
  )
}

RouteLeaveGuard.propTypes = {
  when: PropTypes.bool,
  navigate: PropTypes.func,
}

export default RouteLeaveGuard
