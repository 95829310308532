import styled from 'styled-components'

import { variables } from 'core/styles'

import Modal from './Modal'

const Body = styled.div`
  margin: 24px 24px 0;
  color: ${variables.colorBlack70};
`
const Heading = styled.h2`
  margin: 24px 24px 0;

  & + ${Body} {
    margin-top: 12px;
  }
`
const Buttons = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: flex-end;
  margin: ${(p) => p.margin ?? '40px 24px 24px 24px'};
`

/** @deprecated use ConfirmDialog instead */
function ConfirmModal({
  canClose = false,
  children,
  onClose,
}: {
  canClose?: boolean
  children: React.ReactNode
  onClose: () => void
}) {
  return (
    <Modal canClose={canClose} onClose={onClose} width={480}>
      {children}
    </Modal>
  )
}

ConfirmModal.Heading = Heading
ConfirmModal.Body = Body
ConfirmModal.Buttons = Buttons

export default ConfirmModal
