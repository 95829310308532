import _ from 'lodash'

import * as R from 'core/helpers/remeda'

const parseError = (error: unknown, fallback: string = 'Unknown error', companyId?: string) => {
  if (R.isObject(error) && error.status === 403) {
    const permissions =
      'data' in error && R.isArray(error.data) ?
        R.pipe(
          error.data,
          R.filter(
            (tuple): tuple is [string, string] =>
              R.isArray(tuple) && tuple.length === 2 && tuple[0] === companyId && R.isString(tuple[1]),
          ),
          R.map(R.prop(1)),
        )
      : []

    return permissions.length > 0 ?
        `You do not have the ${R.join(permissions, ', ')} permission(s).`
      : 'You do not have the required permissions.'
  }

  return error === undefined || error === null || (_.isEmpty(error) && !(error instanceof Error)) ?
      undefined
    : _.capitalize(
        R.isString(error) ? error
        : typeof error !== 'object' ? fallback
        : 'detail' in error && typeof error.detail === 'string' ? error.detail
        : 'message' in error && typeof error.message === 'string' ? error.message
        : fallback,
      )
}

export default parseError
