import { FC, PropsWithChildren, useState } from 'react'

import * as Tabs from '@radix-ui/react-tabs'
import { FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components'

import { Button, Divider } from 'core/components'
import { Popover, PopoverContent, PopoverTrigger } from 'core/components/lib/Popover'
import { R } from 'core/helpers'
import { useFlags, useWatchForm } from 'core/hooks'
import { variables } from 'core/styles'

import APIMocksSection from './APIMocksSection'
import overrideOptions from './overrideOptions'
import Section from './Section'
import { overrides, setOverrides, StoredOverrides } from './storage'

type PeachOverridesProps = PropsWithChildren<{}>

const PeachOverrides: FC<PeachOverridesProps> = ({ children }) => {
  const currentFlags = useFlags()

  const [isOpen, onOpenChange] = useState(false)

  const form = useForm<StoredOverrides>({
    defaultValues: R.mapValues(overrideOptions, R.prop('defaultValue')),
    values: overrides,
    resetOptions: {
      keepDefaultValues: true,
    },
  })

  useWatchForm(
    form,
    (values, { name, type }) => {
      if (!name || type !== 'change') return

      const override = overrideOptions[name]

      if (override.resets) {
        for (const name of override.resets) {
          form.resetField(name)
        }
      }

      if (name === 'enableFeatureFlagMocking') {
        for (const [name, value] of R.toPairs.strict(currentFlags)) {
          form.setValue(name, values.enableFeatureFlagMocking ? value : false)
        }
      }
    },
    [currentFlags],
  )

  return (
    <Popover open={isOpen} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>

      <StyledPopoverContent align='end' sideOffset={6} asChild>
        <form onSubmit={form.handleSubmit(setOverrides)}>
          <FormProvider {...form}>
            <Tabs.Root defaultValue='overrides'>
              <Tabs.TabsList>
                <StyledTabButton value='overrides'>Overrides</StyledTabButton>
                <StyledTabButton value='flags'>Feature flags</StyledTabButton>
                <StyledTabButton value='apiMocks'>API mocks</StyledTabButton>
              </Tabs.TabsList>

              <Divider full />

              <StyledTabPanel value='overrides'>
                <Column>
                  <Section form={form} label='API Endpoint' section='apiEndpoint' />
                  <Divider full />
                  <Section form={form} label='App Type & Company' section='appTypeAndCompany' />
                </Column>
                <Column>
                  <Section form={form} label='Misc. Overrides' section='misc' />
                </Column>
              </StyledTabPanel>

              <StyledTabPanel value='flags'>
                <Column>
                  <Section form={form} label='Feature flags' section='featureFlags' />
                </Column>
              </StyledTabPanel>

              <StyledTabPanel value='apiMocks'>
                <Column overflow='visible'>
                  <Section form={form} label='Options' section='apiMockOptions' />
                  <APIMocksSection />
                </Column>
              </StyledTabPanel>

              <Box>
                <Button onClick={() => form.reset()}>Reset</Button>
                <Button secondary type='submit'>
                  Save
                </Button>
              </Box>
            </Tabs.Root>
          </FormProvider>
        </form>
      </StyledPopoverContent>
    </Popover>
  )
}

export default PeachOverrides

const StyledPopoverContent = styled(PopoverContent)`
  width: 500px;
  border: 1px solid ${variables.overlay};
`

const StyledTabButton = styled(Tabs.TabsTrigger)`
  padding: 8px 16px;
  font-weight: 500;
  cursor: pointer;
  background: none;
  border: none;

  &:hover {
    box-shadow:
      inset 0 -1px 0 0 ${variables.colorBlack20},
      0 1px 0 0 ${variables.colorBlack20};
  }

  &[data-state='active'] {
    color: ${variables.colorBluePrimary};
    box-shadow:
      inset 0 -1px 0 0 ${variables.colorBluePrimary},
      0 1px 0 0 ${variables.colorBluePrimary};
  }
`

const StyledTabPanel = styled(Tabs.Content)`
  display: flex;
  gap: 16px;
  padding: 8px 0;

  &[hidden] {
    display: none;
  }
`

const Column = styled.div<{ overflow?: string }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  height: 320px;
  overflow-x: ${(p) => p.overflow ?? 'auto'};
`

const Box = styled.div`
  display: flex;
  gap: 32px;
  justify-content: end;
`
