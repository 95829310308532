import { FC, useEffect, useState } from 'react'

import {
  ELAPSED_TIME_TO_RETRY,
  UPLOAD_FAIL,
  UPLOAD_SUCCESS,
  UPLOAD_TAKING_LONG,
  useFileUpload,
} from 'contexts/FileUploadContext'
import styled from 'styled-components'

import Icon from 'core/components/Icon'
import Button from 'core/components/lib/Button'
import { InfoCard } from 'core/components/lib/InfoCard'
import Spinner from 'core/components/lib/Spinner'
import { useLoadingStatus, useUserType } from 'core/hooks'
import variables from 'core/styles/variables'

const helpText =
  'Please note that we only accept the following uploaded file types: .jpg,.jpeg, .png, or .pdf, that the file size may not exceed 10 megabytes, and that the file name cannot be greater than 50 characters.'

type FileUploadProps = {
  onSuccess?: () => void
}

const FileUpload: FC<FileUploadProps> = ({ onSuccess }) => {
  const [message, setMessage] = useState<string>()
  const [progressTextColor, setProgressTextColor] = useState<string>()
  const [progressBackgroundColor, setProgressBackgroundColor] = useState<string>()

  // ----------------------------------
  // Loading status: Document descriptor & file upload
  const documentDescriptorsLoadingStatus = useLoadingStatus('CreateDocumentDescriptors')
  const uploadFileLoadingStatus = useLoadingStatus('UploadFiles')
  const isUploading = documentDescriptorsLoadingStatus === 'loading' || uploadFileLoadingStatus === 'loading'
  const hasUploadError = documentDescriptorsLoadingStatus === 'error' || uploadFileLoadingStatus === 'error'

  // ----------------------------------
  // Local variables
  const userType = useUserType()

  const buttonColor = isUploading ? variables.colorBlack50 : variables.colorBluePrimary

  // ----------------------------------
  // File upload context values
  const {
    elapsedTime,
    error: requirementError,
    onCancelUpload,
    onOpenFileSelector,
    onRetryUpload,
    progress,
  } = useFileUpload() as any

  // Loading state
  useEffect(() => {
    if (uploadFileLoadingStatus === 'loading') {
      setMessage(undefined)
      if (elapsedTime > ELAPSED_TIME_TO_RETRY) {
        setMessage(UPLOAD_TAKING_LONG)
        setProgressTextColor(variables.colorOrange)
        setProgressBackgroundColor(variables.colorOrangeLighten)
      } else {
        setProgressTextColor(undefined)
        setProgressBackgroundColor(variables.colorGreenLighten)
      }
    }
  }, [uploadFileLoadingStatus, elapsedTime])

  // Success state
  useEffect(() => {
    if (uploadFileLoadingStatus === 'success') {
      setMessage(UPLOAD_SUCCESS)
      setProgressTextColor(variables.colorGreen)
      setProgressBackgroundColor(variables.colorGreenLighten)
      onSuccess?.()
    }
  }, [onSuccess, uploadFileLoadingStatus])

  // Error state
  useEffect(() => {
    if (requirementError) {
      setMessage(requirementError)
      setProgressTextColor(variables.colorRed)
      setProgressBackgroundColor(variables.colorRedLighten)
    } else if (hasUploadError && uploadFileLoadingStatus !== undefined) {
      setMessage(UPLOAD_FAIL)
      setProgressTextColor(variables.colorRed)
      setProgressBackgroundColor(variables.colorRedLighten)
    }
  }, [hasUploadError, uploadFileLoadingStatus, documentDescriptorsLoadingStatus, requirementError])
  // ----------------------------------

  const onFileUploadClick = () => {
    onOpenFileSelector({
      acceptedFileTypes: '.jpg, .jpeg, .png, .pdf',
      autoUpload: true,
      allowMultiFileUpload: false,
    })
  }

  return (
    <>
      {userType !== 'agent' && (
        <InfoCard
          title='Upload documents'
          helpText={helpText}
          helpTextMargin='0 24px 24px'
          iconMargin='0 56px 0 0'
          removeHelpTextTitle
        >
          <Description>
            To keep your personal information safe, documents will not be accessible after you successfully upload them.
            Our customer care team will review them internally. Upload one .jpg, .jpeg, .pdf or .png document at a time
            with a maximum size of 10MB.
          </Description>
          <NoticeBarContainer>
            {(requirementError || uploadFileLoadingStatus !== undefined) && (
              <NoticeBar>
                <ProgressBar
                  progress={
                    requirementError || hasUploadError ? 100 : Math.floor((progress.loaded / progress.total) * 100)
                  }
                  background={progressBackgroundColor}
                />
                <Message color={progressTextColor}>{message}</Message>
              </NoticeBar>
            )}
          </NoticeBarContainer>
        </InfoCard>
      )}

      <ButtonContainer>
        <Button
          data-testid='button-upload'
          primaryLight
          fullWidth
          borderType='dashed-light'
          color={buttonColor}
          onClick={() => onFileUploadClick()}
        >
          {isUploading ?
            <>
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>{' '}
              Uploading
            </>
          : <>
              <UploadIconContainer>
                <Icon fontSize='inherit' name='upload' />
              </UploadIconContainer>{' '}
              Upload document(s)
            </>
          }
        </Button>
        {(isUploading || hasUploadError) && (
          <RetryAndCancel>
            {(elapsedTime > ELAPSED_TIME_TO_RETRY || hasUploadError) && (
              <Button secondary margin='0 40px 0 0' onClick={onRetryUpload}>
                Retry
              </Button>
            )}
            <Button secondary onClick={onCancelUpload}>
              Cancel upload
            </Button>
          </RetryAndCancel>
        )}
      </ButtonContainer>
    </>
  )
}

export default FileUpload

const Description = styled.div`
  padding: 0 24px 24px;
  color: ${variables.colorBlack90};
`

const ButtonContainer = styled.div`
  position: relative;
  margin: 8px 0 0;
`

const UploadIconContainer = styled.span`
  position: relative;
  top: 8px;
  display: inherit;
  font-size: 24px;
`

const RetryAndCancel = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
`

const NoticeBarContainer = styled.div`
  position: relative;
  margin-top: -12px;
  overflow: hidden;
`

export const NoticeBar = styled.div<{ background?: string }>`
  position: relative;
  bottom: -2px;
  left: -2px;
  box-sizing: border-box;
  width: calc(100% + 4px);
  height: 32px;
  padding: 4px 24px;
  overflow: hidden;
  border-radius: 0 0 8px 8px;
  ${(p) => p.background && `background: ${p.background};`};
`

export const ProgressBar = styled.div<{ progress: number; background?: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${(p) => p.progress}%;
  ${(p) => p.background && `background: ${p.background};`};
`

export const Message = styled.div`
  position: relative;
  color: ${(p) => p.color};
`

const SpinnerContainer = styled.span`
  position: relative;
  top: 4px;
  display: inherit;
  width: 24px;
  height: 19px;
`
