import React from 'react'

import styled from 'styled-components'

const Env = styled.div`
  font-family: monospace;
  line-height: 1.5;
  white-space: pre-wrap;
`

const EnvVariables = () => {
  return <Env>{JSON.stringify(process.env, null, 2)}</Env>
}

export default EnvVariables
