import borrowerLoading from './borrowerLoading'
import communicator from './communicator'
import consents from './consents'
import contacts from './contacts'
import documents from './documents'
import employees from './employees'
import legalRepresentatives from './legalRepresentatives'
import loading from './loading'
import loans from './loans'
import loanTypes from './loanTypes'
import people from './people'
import permissions from './permissions'
import teams from './teams'
import verifications from './verifications'

const rootReducers = {
  borrowerLoading,
  communicator,
  consents,
  contacts,
  documents,
  employees,
  legalRepresentatives,
  loading,
  loanTypes,
  loans,
  people,
  permissions,
  teams,
  verifications,
}

export default rootReducers
