import { checkTimestring } from '../types'
import mtz from '../utils/mtz'

const makeGetHours = (context = {}) => {
  const { timezone } = context

  const getHours = (value) => {
    checkTimestring(value)

    return mtz(value, timezone).hours()
  }

  return getHours
}

export default makeGetHours
