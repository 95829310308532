import _ from 'lodash'

import { Interaction } from 'core/types'

const interactionStatusLabels: Record<
  NonNullable<Interaction['agentOutcome'] | Interaction['statusDetails']>,
  string
> = {
  blockedByComplianceGuard: 'Compliance Guard',
  chatBorrowerInactivity: 'Borrower inactivity',
  chatPosted: 'Sent',
  chatRead: 'Read',
  chatReceivedOutsideOpenHours: 'Received outside open hours',
  emailBlocked: 'Blocked',
  emailBounced: 'Bounced',
  emailClick: 'Clicked',
  emailDelivered: 'Delivered',
  emailDropped: 'Dropped',
  emailFailedToSend: 'Failed to send',
  emailOpened: 'Opened',
  emailReportedAsSpam: 'Marked as spam by recipient',
  emailSent: 'Sent',
  invalidContact: 'Invalid contact',
  letterCreated: 'Created',
  letterDeleted: 'Canceled',
  letterDelivered: 'Delivered',
  letterFailedToCreate: 'Failed to create',
  letterInLocalArea: 'In local area',
  letterInTransit: 'In transit',
  letterProcessedForDelivery: 'Processed for delivery',
  letterRenderedPdf: 'Rendered PDF',
  letterRenderedThumbnails: 'Rendered thumbnails',
  letterRerouted: 'Rerouted',
  letterReturnedToSender: 'Returned to sender',
  textA2PCampaignUnregistered: 'Unregistered text A2P campaign',
  textAccountSuspended: 'Account suspended',
  textCarrierViolation: 'Carrier violation',
  textConvoClosedByAgent: 'Closed by agent',
  textConvoClosedStale: 'System closed due to staleness',
  textConvoTimeoutAutoclose: 'Timed out and autoclosed',
  textConvoUnknownNumberRejected: 'Unknown number rejected',
  textDeviceUnreachable: 'Device unreachable',
  textLandlineOrUnreachableCarrier: 'Landline or unreachable carrier',
  textLenderDisabledInboundTexts: 'Inbound texts not enabled',
  textMessageBlocked: 'Message blocked',
  textMessagePriceExceedsMax: 'Message exceeds max price',
  textMissingSegment: 'Missing segment',
  textNumberDisconnected: 'Number disconnected',
  textQueueOverflow: 'Queue overflow',
  textRegionInvalid: 'Invalid text region',
  textUnknownError: 'Unknown error',
  textUnsubscribedRecipient: 'Unsubscribed recipient',
  voiceConnected: 'Connected',
  voiceDisconnectedWhileQueuedOnHold: 'Disconnected while borrower is queued on hold',
  voiceFailedToInitiate: `Couldn't initiate`,
  voiceLeftVoicemail: 'Left voicemail',
  voiceLeftVoicemailFirstParty: 'Left voicemail with first party',
  voiceLeftVoicemailThirdParty: 'Left voicemail with third party',
  voiceLenderDisabledInboundCalls: 'Lender does not have inbound calls enabled',
  voiceLineBusyNoRing: 'Line busy no ring',
  voiceNumberDisconnected: 'Number disconnected',
  voiceNumberInvalid: 'Invalid voice number',
  voicePickedHungUp: 'Picked and hung up',
  voiceRangNoPickup: 'Rang no pickup and did not reach voicemail',
  voiceReachedVoicemailNoMessageLeft: 'Reached voicemail no message left',
  voiceReceivedNoAgentsAvailable: 'Received during business hours with no available agents',
  voiceReceivedOutsideOpenHours: 'Received outside open hours',
  voiceSpokeWithFirstParty: 'Spoke with first party',
  voiceSpokeWithThirdParty: 'Spoke with third party',
  voiceUnexpectedDisconnect: 'Call unexpectedly disconnected',
}

const getStatusDetailsLabel = (statusDetails: string) =>
  statusDetails in interactionStatusLabels ?
    interactionStatusLabels[statusDetails as keyof typeof interactionStatusLabels]
  : _.startCase(statusDetails)

export default getStatusDetailsLabel
