import { useReducer, useCallback, useEffect } from 'react'

import _ from 'lodash'

import ErrorLogging from 'core/system/ErrorLogging'

import usePrevious from './usePrevious'
import useStableCallback from './useStableCallback'
import useUnmounted from './useUnmounted'

/* useWrite is designed to handle the *typical* state management tasks of *write* operations, 
so nominally PUT, POST and DELETE

it assumes that you want to make an async request, and know if it's 
(A) in flight, (B) has succeeded, or (C) failed.  

it assumes that you only want to have one of these requests in flight at a time. 

if you need to do anything fancier, you are probably better off handling request state from scratch.  


const [send, sending, resp, error, clear] = useWrite(promiseFn)

send: function,  calls the wrapped function.  always resolves even if promiseFn rejects
sending: boolean, true when in flight 
resp: any, whatever the promiseFn resolved to
error: any, whatever the promiseFn rejected with
clear: function, resets to fresh state 

*/

const arrayHasChanged = (arr1, arr2) => {
  if (!_.isArray(arr1)) return
  if (!_.isArray(arr2)) return
  return _.some(arr1, (val, index) => !_.isEqual(val, arr2[index]))
}

const getInitialState = () => {
  return {
    sending: false,
    resp: undefined,
    error: undefined,
  }
}

const reducer = (state, { type, resp, error }) => {
  const { sending } = state

  switch (type) {
    case 'sending':
      return { resp: undefined, sending: true, error: undefined }
    case 'resp':
      return { sending: false, resp: resp || {}, error: undefined }
    case 'error':
      return {
        sending: false,
        resp: undefined,
        error: error || new Error('Unknown Error'),
      }
    case 'clear':
      return !sending ? getInitialState() : state
    default:
      return state
  }
}

const parseArgs = (first, second = {}) => {
  if (_.isFunction(first)) {
    return { sendFn: first, ...second }
  }
  return first
}

const useWrite = (...args) => {
  const { sendFn, throwOnError, resetErrorKeys } = parseArgs(...args)

  const unmounted = useUnmounted()

  const [state, dispatch] = useReducer(reducer, getInitialState)

  const { sending, resp, error } = state

  const send = useStableCallback(async (...args) => {
    if (!sending) {
      dispatch({ type: 'sending' })

      try {
        const resp = await sendFn(...args)

        if (!unmounted.current) dispatch({ type: 'resp', resp })

        return resp
      } catch (error) {
        if (!unmounted.current) dispatch({ type: 'error', error })

        // For a 503, AppRootErrorBoundary will display the maintenance message after
        // catching this error
        if (error.status === 503 || throwOnError) {
          throw error
        } else {
          ErrorLogging.captureException(error)
          console.error('useWrite captured an error', error) // eslint-disable-line
        }
      }
    }
  })

  const clear = useCallback(() => dispatch({ type: 'clear' }), [])

  const prevResetErrorKeys = usePrevious(resetErrorKeys)

  useEffect(() => {
    if (arrayHasChanged(resetErrorKeys, prevResetErrorKeys)) {
      clear()
    }
  }, [resetErrorKeys, prevResetErrorKeys, clear])

  return [send, sending, resp, error, clear]
}

export default useWrite
