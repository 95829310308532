import React, { useMemo } from 'react'

import _ from 'lodash'

// getRects parses a string based shorthand for loader elements
// and generates an array of svg elements
const DEFAULT_GAP = 8
const DEFAULT_HEIGHT = 16

const trim = (str) => `${str}`.trim()

const split = (str, key) => `${str}`.split(key).map(trim)

const parse = (str = '', defaultValue) => {
  const num = parseInt(trim(str), 10)
  return _.isFinite(num) ? num : defaultValue
}

const formatElement = (str, inline, containerWidth) => {
  const [dimsString, ...args] = split(str, '--')
  const pairs = _.map(args, (str = '') => split(str, '='))
  const options = _.fromPairs(pairs)

  const gap = parse(options.gap, DEFAULT_GAP)
  const [widthStr, heightStr] = split(dimsString, 'x')
  const width = widthStr === '100%' ? containerWidth : parse(widthStr, containerWidth)

  const height = parse(heightStr, DEFAULT_HEIGHT)
  const deltaX = inline ? width + gap : 0
  const deltaY = inline ? 0 : height + gap

  return { inline, width, height, deltaX, deltaY }
}

const getElements = (lines, containerWidth) => {
  const elements = []
  _.each(lines, (line) => {
    const stringElements = split(line, ',')
    const count = _.size(stringElements)
    _.each(stringElements, (stringElement, index) => {
      const inline = index < count - 1
      elements.push(formatElement(stringElement, inline, containerWidth))
    })
  })
  return elements
}

const getSkeletonRects = (str, containerWidth) => {
  /* 
  This is approximately a simple implementation of a non-wrapping, ragged-right layout algorithm.

  - each element has properties about the individual items that we want to show. 
  
  - the <ContentLoader> component needs svgs that have their locations specified in *absolute* coordinates, so in order to render then in a reasonable layout without the developer doing annoying math each time, we need to step through them sequentially and keep track of some position information as we go. 

  we track `y`, which is the cumulative height at we have seen so far 
  totaled from the heights and gaps. each (non inline) element adds to this

  we track `x`, which is the total x offset we've seen so far from inline elements

  each inline element adds to x, but as soon as an non inline element is found, x resets to zero
  */
  const lines = _.compact(str.split('\n').map(trim))

  const elements = getElements(lines, containerWidth)

  const reducer = (memo, element, index) => {
    const { rects, y, x } = memo

    const { width, height, inline, deltaX, deltaY } = element

    const rect = <rect key={index} x={x} y={y} rx='3' ry='3' width={width} height={height} />

    const newX = inline ? x + deltaX : 0
    const newY = y + deltaY
    return { rects: rects.concat(rect), x: newX, y: newY }
  }

  return _.reduce(elements, reducer, { rects: [], x: 0, y: 0 })
}

const useSkeletonRects = (str, width) => {
  return useMemo(() => getSkeletonRects(str, width), [str, width])
}

export { getSkeletonRects }

export default useSkeletonRects
