import * as R from 'core/helpers/remeda'

import overrideOptions, { PeachOverrideName, PeachOverrideVariant } from './overrideOptions'

const KEY = 'PEACH_OVERRIDES'

export type StoredOverrides = Record<PeachOverrideName, PeachOverrideVariant['defaultValue']>

export const overridesEnabled = localStorage ? !!localStorage.getItem('ENABLE_PEACH_OVERRIDES') : false

export let overrides: StoredOverrides

try {
  const val = JSON.parse(localStorage.getItem(KEY) ?? '{}')
  overrides = R.isObject(val) ? val : {}
} catch {
  overrides = {} as StoredOverrides
}

export const setOverrides = (values: StoredOverrides) => {
  const newValues = R.pipe(
    values,
    R.toPairs.strict,
    R.filter(([name, value]) => name in overrideOptions && value !== overrideOptions[name].defaultValue),
    R.fromPairs,
  )

  if (R.isEmpty(newValues)) {
    localStorage.removeItem(KEY)
  } else {
    localStorage.setItem(KEY, JSON.stringify(newValues))
  }

  if (
    R.pipe(
      values,
      R.keys,
      R.filter((name) => overrideOptions[name]?.isAuthRequired),
      R.hasAtLeast(1),
    )
  ) {
    localStorage.removeItem('secretToken')
  }

  window.location.reload()
}

export const getOverride = (name: PeachOverrideName) => overrides[name]
