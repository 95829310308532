import mtz from '../utils/mtz'

const makeGetISODay = (context = {}) => {
  const { timezone } = context

  const getISODay = (value) => {
    const m = mtz(value, timezone)
    return m.isoWeekday()
  }

  return getISODay
}

export default makeGetISODay
