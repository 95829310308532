import { getStatusDetailsLabel } from 'core/helpers'
import deCamelize from 'core/helpers/deCamelize'
import { Interaction } from 'core/types'
import { InteractionStatusType } from 'core/types/chat'

export type VoiceInteractionOutcomeOption = {
  label: string
  value: NonNullable<Interaction['agentOutcome']>
  status: Interaction['status']
  statusDetails: Interaction['statusDetails']
  direction: Array<Interaction['direction']>
}

export const voiceInteractionOutcomeOptions: Array<VoiceInteractionOutcomeOption> = [
  {
    label: outcomeLabel('succeeded', 'voiceSpokeWithFirstParty'),
    value: 'voiceSpokeWithFirstParty',
    status: 'succeeded',
    statusDetails: 'voiceConnected',
    direction: ['inbound', 'outbound'],
  },
  {
    label: outcomeLabel('succeeded', 'voiceSpokeWithThirdParty'),
    value: 'voiceSpokeWithThirdParty',
    status: 'succeeded',
    statusDetails: 'voiceConnected',
    direction: ['inbound', 'outbound'],
  },
  {
    label: outcomeLabel('attempted', 'voiceUnexpectedDisconnect'),
    value: 'voiceUnexpectedDisconnect',
    status: 'attempted',
    statusDetails: 'voiceConnected',
    direction: ['inbound', 'outbound'],
  },
  {
    label: outcomeLabel('attempted', 'voiceLeftVoicemail'),
    value: 'voiceLeftVoicemail',
    status: 'attempted',
    statusDetails: 'voiceConnected',
    direction: ['outbound'],
  },
  {
    label: outcomeLabel('attempted', 'voiceLineBusyNoRing'),
    value: 'voiceLineBusyNoRing',
    status: 'attempted',
    statusDetails: 'voiceConnected',
    direction: ['outbound'],
  },
  {
    label: outcomeLabel('attempted', 'voicePickedHungUp'),
    value: 'voicePickedHungUp',
    status: 'attempted',
    statusDetails: 'voiceConnected',
    direction: ['outbound'],
  },
  {
    label: outcomeLabel('attempted', 'voiceRangNoPickup'),
    value: 'voiceRangNoPickup',
    status: 'attempted',
    statusDetails: 'voiceConnected',
    direction: ['outbound'],
  },
  {
    label: outcomeLabel('attempted', 'voiceReachedVoicemailNoMessageLeft'),
    value: 'voiceReachedVoicemailNoMessageLeft',
    status: 'attempted',
    statusDetails: 'voiceConnected',
    direction: ['outbound'],
  },
  {
    label: outcomeLabel('failed', 'voiceFailedToInitiate'),
    value: 'voiceFailedToInitiate',
    status: 'failed',
    statusDetails: 'voiceFailedToInitiate',
    direction: ['outbound'],
  },
  {
    label: outcomeLabel('failed', 'voiceNumberDisconnected'),
    value: 'voiceNumberDisconnected',
    status: 'failed',
    statusDetails: 'voiceNumberDisconnected',
    direction: ['outbound'],
  },
]

function outcomeLabel(status: InteractionStatusType, value: string) {
  return `${deCamelize(status)} - ${getStatusDetailsLabel(value)}`
}
