/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const getTasktypeAvailability: OperationData<Types.GetTasktypeAvailability> = {
  method: 'get',
  name: 'getTasktypeAvailability',
  summary: `Get task availability`,
  path: '/twilio/tasktype-availability',
  queryKey: ({}) => ['twilio', 'tasktype-availability'],
}

export const updateTasktypeAvailability: OperationData<Types.UpdateTasktypeAvailability> = {
  method: 'put',
  name: 'updateTasktypeAvailability',
  summary: `Update task type availability`,
  path: '/twilio/tasktype-availability',
  queryKey: ({}) => ['twilio', 'tasktype-availability'],
}

export const taskHistoryList: OperationData<Types.TaskHistoryList> = {
  method: 'get',
  name: 'taskHistoryList',
  summary: `List Terminated Tasks`,
  path: '/companies/{companyId}/twilio-terminated-tasks',
  queryKey: ({ companyId }) => ['companies', companyId, 'twilio-terminated-tasks'],
}

export const taskHistoryGet: OperationData<Types.TaskHistoryGet> = {
  method: 'get',
  name: 'taskHistoryGet',
  summary: `Get Terminated Task by ID`,
  path: '/companies/{companyId}/twilio-terminated-tasks/{taskHistoryId}',
  queryKey: ({ companyId, taskHistoryId }) => ['companies', companyId, 'twilio-terminated-tasks', taskHistoryId],
}

export const taskHistoryClone: OperationData<Types.TaskHistoryClone> = {
  method: 'post',
  name: 'taskHistoryClone',
  summary: `Clone Terminated Task by ID`,
  path: '/companies/{companyId}/twilio-terminated-tasks/{taskHistoryId}/clone',
  queryKey: ({ companyId, taskHistoryId }) => [
    'companies',
    companyId,
    'twilio-terminated-tasks',
    taskHistoryId,
    'clone',
  ],
}

export const listWorkers: OperationData<Types.ListWorkers> = {
  method: 'get',
  name: 'listWorkers',
  summary: `List Taskrouter Workers`,
  path: '/companies/{companyId}/twilio-workers',
  queryKey: ({ companyId }) => ['companies', companyId, 'twilio-workers'],
}

export const listUnterminatedTasks: OperationData<Types.ListUnterminatedTasks> = {
  method: 'get',
  name: 'listUnterminatedTasks',
  summary: `List Unterminated Tasks`,
  path: '/companies/{companyId}/twilio-unterminated-tasks',
  queryKey: ({ companyId }) => ['companies', companyId, 'twilio-unterminated-tasks'],
}

export const deleteUnterminatedTasks: OperationData<Types.DeleteUnterminatedTasks> = {
  method: 'delete',
  name: 'deleteUnterminatedTasks',
  summary: `Bulk Delete Unterminated Tasks`,
  path: '/companies/{companyId}/twilio-unterminated-tasks',
  queryKey: ({ companyId }) => ['companies', companyId, 'twilio-unterminated-tasks'],
}

export const reflowUnterminatedTasks: OperationData<Types.ReflowUnterminatedTasks> = {
  method: 'post',
  name: 'reflowUnterminatedTasks',
  summary: `Reflow All Unterminated Tasks`,
  path: '/companies/{companyId}/twilio-unterminated-tasks/reflow',
  queryKey: ({ companyId }) => ['companies', companyId, 'twilio-unterminated-tasks', 'reflow'],
}

export const readUnterminatedTask: OperationData<Types.ReadUnterminatedTask> = {
  method: 'get',
  name: 'readUnterminatedTask',
  summary: `Read Unterminated Task by SID`,
  path: '/companies/{companyId}/twilio-unterminated-tasks/{taskSid}',
  queryKey: ({ companyId, taskSid }) => ['companies', companyId, 'twilio-unterminated-tasks', taskSid],
}

export const deleteUnterminatedTask: OperationData<Types.DeleteUnterminatedTask> = {
  method: 'delete',
  name: 'deleteUnterminatedTask',
  summary: `Deletes Unterminated Task by SID`,
  path: '/companies/{companyId}/twilio-unterminated-tasks/{taskSid}',
  queryKey: ({ companyId, taskSid }) => ['companies', companyId, 'twilio-unterminated-tasks', taskSid],
}

export const abandonAcceptedTask: OperationData<Types.AbandonAcceptedTask> = {
  method: 'post',
  name: 'abandonAcceptedTask',
  summary: `Abandon Unterminated Task by SID`,
  path: '/companies/{companyId}/twilio-unterminated-tasks/{taskSid}/abandon',
  queryKey: ({ companyId, taskSid }) => ['companies', companyId, 'twilio-unterminated-tasks', taskSid, 'abandon'],
}

export const getAllQueues: OperationData<Types.GetAllQueues> = {
  method: 'get',
  name: 'getAllQueues',
  summary: `Get information on Twilio task queues`,
  path: '/companies/{companyId}/twilio-queues',
  queryKey: ({ companyId }) => ['companies', companyId, 'twilio-queues'],
}

export const taskWorkerPairingConfigSync: OperationData<Types.TaskWorkerPairingConfigSync> = {
  method: 'post',
  name: 'taskWorkerPairingConfigSync',
  summary: `Sync task-worker pairing configs to Twilio`,
  path: '/companies/{companyId}/task-worker-pairing-configs/sync',
  queryKey: ({ companyId }) => ['companies', companyId, 'task-worker-pairing-configs', 'sync'],
}

export const taskWorkerPairingConfigList: OperationData<Types.TaskWorkerPairingConfigList> = {
  method: 'get',
  name: 'taskWorkerPairingConfigList',
  summary: `List task-worker pairing configs`,
  path: '/companies/{companyId}/task-worker-pairing-configs',
  queryKey: ({ companyId }) => ['companies', companyId, 'task-worker-pairing-configs'],
}

export const taskWorkerPairingConfigCreate: OperationData<Types.TaskWorkerPairingConfigCreate> = {
  method: 'post',
  name: 'taskWorkerPairingConfigCreate',
  summary: `Create task-worker pairing config`,
  path: '/companies/{companyId}/task-worker-pairing-configs',
  queryKey: ({ companyId }) => ['companies', companyId, 'task-worker-pairing-configs'],
}

export const taskWorkerPairingConfigGet: OperationData<Types.TaskWorkerPairingConfigGet> = {
  method: 'get',
  name: 'taskWorkerPairingConfigGet',
  summary: `Get task-worker pairing config`,
  path: '/companies/{companyId}/task-worker-pairing-configs/{taskWorkerPairingConfigId}',
  queryKey: ({ companyId, taskWorkerPairingConfigId }) => [
    'companies',
    companyId,
    'task-worker-pairing-configs',
    taskWorkerPairingConfigId,
  ],
}

export const taskWorkerPairingConfigUpdate: OperationData<Types.TaskWorkerPairingConfigUpdate> = {
  method: 'put',
  name: 'taskWorkerPairingConfigUpdate',
  summary: `Update task-worker pairing config`,
  path: '/companies/{companyId}/task-worker-pairing-configs/{taskWorkerPairingConfigId}',
  queryKey: ({ companyId, taskWorkerPairingConfigId }) => [
    'companies',
    companyId,
    'task-worker-pairing-configs',
    taskWorkerPairingConfigId,
  ],
}

export const taskWorkerPairingConfigDelete: OperationData<Types.TaskWorkerPairingConfigDelete> = {
  method: 'delete',
  name: 'taskWorkerPairingConfigDelete',
  summary: `Delete task-worker pairing config`,
  path: '/companies/{companyId}/task-worker-pairing-configs/{taskWorkerPairingConfigId}',
  queryKey: ({ companyId, taskWorkerPairingConfigId }) => [
    'companies',
    companyId,
    'task-worker-pairing-configs',
    taskWorkerPairingConfigId,
  ],
}

export const taskWorkerPairingConfigDefaultGet: OperationData<Types.TaskWorkerPairingConfigDefaultGet> = {
  method: 'get',
  name: 'taskWorkerPairingConfigDefaultGet',
  summary: `Get default task-worker pairing config`,
  path: '/companies/{companyId}/task-worker-pairing-configs/default',
  queryKey: ({ companyId }) => ['companies', companyId, 'task-worker-pairing-configs', 'default'],
}

export const taskWorkerPairingConfigDefaultUpdate: OperationData<Types.TaskWorkerPairingConfigDefaultUpdate> = {
  method: 'put',
  name: 'taskWorkerPairingConfigDefaultUpdate',
  summary: `Update default task-worker pairing config`,
  path: '/companies/{companyId}/task-worker-pairing-configs/default',
  queryKey: ({ companyId }) => ['companies', companyId, 'task-worker-pairing-configs', 'default'],
}

export const forcePublishTask: OperationData<Types.ForcePublishTask> = {
  method: 'post',
  name: 'forcePublishTask',
  summary: `Force publish a task to Twilio Taskrouter`,
  path: '/twilio/force-publish-task',
  queryKey: ({}) => ['twilio', 'force-publish-task'],
}
