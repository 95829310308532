import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { createLoan } from 'core/actions/loans'
import variables from 'core/styles/variables'

const Input = styled.input`
  padding: 4px;
  margin: 0 16px;
  border: 1px solid ${variables.colorBlack50};
`

const Button = styled.button`
  padding: 4px;
`

export const CreateLoan = ({ createLoan }) => {
  const [personAddressId, setPersonAddressId] = useState('')
  const [loanTypeId, setLoanTypeId] = useState('')

  return (
    <div>
      <h3>Create loan</h3>
      <div>
        <span>personAddressId (get from available contact IDs):</span>
        <Input
          type='text'
          value={personAddressId}
          onChange={(e) => setPersonAddressId(e.target.value)}
          data-testid='personAddressId'
        />
      </div>
      <div>
        <span>loanTypeId:</span>
        <Input
          type='text'
          value={loanTypeId}
          onChange={(e) => setLoanTypeId(e.target.value)}
          data-testid='loanTypeId'
        />
      </div>
      <div>
        <Button
          disabled={personAddressId === '' || loanTypeId === ''}
          onClick={() => createLoan({ loanTypeId, personAddressId })}
          data-testid='submitButton'
        >
          Create loan
        </Button>
      </div>
    </div>
  )
}

CreateLoan.propTypes = {
  /** Provided by mapDispatchToProps */
  createLoan: PropTypes.func,
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => bindActionCreators({ createLoan }, dispatch)

export default connect(null, mapDispatchToProps)(CreateLoan)
