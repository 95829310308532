import { createContext, useContext, useMemo } from 'react'

import { getOverride } from 'core/components/PeachOverrides/storage'
import { FrontendPreauthBootstrap } from 'core/types'

import { useUserType } from './UserTypeProvider'

interface Props {
  company: FrontendPreauthBootstrap['company']
  children: JSX.Element
}

const CustomizationsContext = createContext<FrontendPreauthBootstrap['company']>({})

export const CustomizationsProvider = ({ children, company }: Props) => {
  return <CustomizationsContext.Provider value={company}>{children}</CustomizationsContext.Provider>
}

export const useCompanyCopies = () => {
  const company = useContext(CustomizationsContext)
  return useMemo(() => company?.brandAssets?.copies || {}, [company])
}

export const useCompanyEvidence = () => {
  const company = useContext(CustomizationsContext)
  return useMemo(() => company?.evidence || {}, [company])
}

export const useCompanyLogos = () => {
  const company = useContext(CustomizationsContext)
  return useMemo(() => company?.brandAssets?.logos || {}, [company])
}

export const useCompanyInfo = () => {
  const company = useContext(CustomizationsContext)

  return useMemo(() => {
    const { brandAssets, ...rest } = company || {}
    return rest || {}
  }, [company])
}

export const useCompanyColors = () => {
  const company = useContext(CustomizationsContext)
  return useMemo(() => company?.brandAssets?.colors || {}, [company])
}

export const useCompanyLinks = () => {
  const company = useContext(CustomizationsContext)
  return useMemo(() => company?.links || {}, [company])
}

export const useCompanyPaymentTypes = () => {
  const company = useContext(CustomizationsContext)

  const plaidBalanceCheck = getOverride('plaidBalanceCheck') ?? company?.paymentTypes?.balanceCheck?.plaid

  const plaidVerification = getOverride('plaidVerification') ?? company?.paymentTypes?.achVerification?.plaid

  const microdepositVerification =
    getOverride('microdepositVerification') ?? company?.paymentTypes?.achVerification?.microdeposit

  const userType = useUserType()

  const { paymentTypes, ui } = company ?? {}
  const { achVerification = {}, balanceCheck = {} } = paymentTypes ?? {}

  const hiddenTypes =
    userType === 'agent' ? ui?.managePaymentMethodsPage?.agent : ui?.managePaymentMethodsPage?.borrower

  return {
    ...paymentTypes,

    check: paymentTypes?.check && hiddenTypes?.check !== 'hidden',
    creditCard: paymentTypes?.creditCard && hiddenTypes?.creditCard !== 'hidden',
    debitCard: paymentTypes?.debitCard && hiddenTypes?.debitCard !== 'hidden',
    payroll: paymentTypes?.payroll && hiddenTypes?.payroll !== 'hidden',
    paymentNetwork: paymentTypes?.check && hiddenTypes?.check !== 'hidden',
    wire: paymentTypes?.check && hiddenTypes?.check !== 'hidden',
    moneyOrder: paymentTypes?.check && hiddenTypes?.check !== 'hidden',

    balanceCheck: {
      ...balanceCheck,
      plaid: !!plaidBalanceCheck,
    },
    achVerification: {
      ...achVerification,
      plaid: !!plaidVerification,
      microdeposit: !!microdepositVerification,
    },
  }
}

export const useCompanyUI = () => {
  const company = useContext(CustomizationsContext)
  return useMemo(() => {
    const { ui } = company || {}
    const { loanOptions = {}, accountSettingsPage = {}, accountSummaryPage = {} } = ui || {}
    return { ...ui, loanOptions, accountSettingsPage, accountSummaryPage }
  }, [company])
}

export const useCompanyStatementsInfo = () => {
  /*
  company.statements.useCompanyStatementsInfo
  is the proposed location for statements configuration data
  but is subject to change as of 2021-04-06
  */

  const company = useContext(CustomizationsContext)
  return useMemo(() => {
    const { statements } = company || {}
    return statements || {}
  }, [company])
}
