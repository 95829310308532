import { checkTimeordate } from '../types'
import mtz from '../utils/mtz'

const makeGetDay = (context = {}) => {
  const { timezone }: any = context

  const getDay = (timeordate: string) => {
    checkTimeordate(timeordate)

    return mtz(timeordate, timezone).day()
  }

  return getDay
}

export default makeGetDay
