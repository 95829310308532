import { operations } from 'core/types'
import { MutationOptions, Operation, OperationData } from 'core/types/makeMutationHook'
import { QueryHookArguments } from 'core/types/makeQueryHook'

type GetOp<Data> = Data extends OperationData<infer Op> ? Op : never

const defaultOptions: {
  [K in keyof typeof operations]?: Partial<
    (typeof operations)[K] extends { method: 'get' } ? QueryHookArguments<GetOp<(typeof operations)[K]>>
    : MutationOptions<GetOp<(typeof operations)[K]>>
  >
} = {
  getCaseById: {
    query: { associations: 'all', include: ['dispute', 'purchase'] },
  },

  internalUnboundGetCaseById: {
    query: { associations: 'all', include: ['dispute', 'purchase'] },
  },

  listCasesByBorrower: {
    query: { associations: 'all', include: ['dispute', 'purchase'] },
  },

  loansGet: {
    query: { status: ['all'], includeAdditionalBorrowers: true },
  },

  cancelPromiseToPayPlan: {
    options: {
      onSuccess: (_, { personId, loanId }, { queryClient }) =>
        queryClient.invalidateQueries({ queryKey: ['people', personId, 'loans', loanId, 'promise-to-pay-plans'] }),
    },
  },

  loanCustomPaymentPlanCancel: {
    options: {
      onSuccess: (_, { personId, loanId }, { queryClient }) =>
        queryClient.invalidateQueries({ queryKey: ['people', personId, 'loans', loanId, 'custom-payment-plans'] }),
    },
  },

  loanRefundsCancel: {
    options: {
      onSuccess: (_, { companyId, personId, loanId }, { queryClient }) =>
        queryClient.invalidateQueries({
          queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'refunds'],
        }),
    },
  },

  internalLoanRefundsV2Post: {
    options: {
      onSuccess: (_, { companyId, personId, loanId }, { queryClient }) =>
        queryClient.invalidateQueries({
          queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'refunds'],
        }),
    },
  },

  updateWorkflowWorkitem: {
    options: {
      onSuccess: (_, { companyId, personId, caseId }, { queryClient }) =>
        queryClient.invalidateQueries({
          queryKey: ['companies', companyId, 'people', personId, 'cases', caseId, 'workflow'],
        }),
    },
  },

  updateWorkflowStep: {
    options: {
      onSuccess: (_, { companyId, personId, caseId }, { queryClient }) =>
        queryClient.invalidateQueries({
          queryKey: ['companies', companyId, 'people', personId, 'cases', caseId, 'workflow'],
        }),
    },
  },

  doNotInteractCreate: {
    options: {
      onSuccess: (_, { personId }, { queryClient }) =>
        queryClient.invalidateQueries({ queryKey: ['people', personId, 'do-not-interacts'] }),
    },
  },

  draftPopulationCommit: {
    options: {
      onSuccess: (_, { supercaseId }, { queryClient }) =>
        queryClient.invalidateQueries({ queryKey: ['supercases', supercaseId, 'populations'] }),
    },
  },

  draftPopulationMembersSet: {
    options: {
      onSuccess: (_, { supercaseId }, { queryClient }) =>
        queryClient.invalidateQueries({ queryKey: ['supercases', supercaseId, 'populations', 'draft'] }),
    },
  },

  draftPopulationMembersSetCsv: {
    options: {
      onSuccess: (_, { supercaseId }, { queryClient }) =>
        queryClient.invalidateQueries({ queryKey: ['supercases', supercaseId, 'populations', 'draft'] }),
    },
  },

  snoozeCase: {
    options: {
      onSuccess: (_, { personId }, { queryClient }) =>
        queryClient.invalidateQueries({ queryKey: ['people', personId, 'cases'] }),
    },
  },

  caseEscalationsCreate: {
    options: {
      onSuccess: (_, { personId }, { queryClient }) =>
        queryClient.invalidateQueries({ queryKey: ['people', personId, 'cases'] }),
    },
  },

  interactionAdd: {
    options: {
      onSuccess: (_, { personId }, { queryClient }) => {
        setTimeout(() => queryClient.invalidateQueries({ queryKey: ['people', personId, 'interactions'] }), 0)
      },
    },
  },

  interactionDelete: {
    options: {
      onSuccess: (_, { personId }, { queryClient }) => {
        queryClient.invalidateQueries({ queryKey: ['people', personId, 'interactions'] })
        queryClient.invalidateQueries({ queryKey: ['people', personId, 'interaction-notes'] })
      },
    },
  },

  handleSend: {
    options: {
      onSuccess: (_, { body }, { queryClient }) => {
        if (body?.personId) {
          queryClient.invalidateQueries({ queryKey: ['people', body.personId, 'interactions'] })
        }
      },
    },
  },

  addCaseAssociation: {
    options: {
      onSuccess: (_, { personId, interactionId }, { queryClient }) =>
        queryClient.invalidateQueries({ queryKey: ['people', personId, 'interactions', interactionId, 'cases'] }),
    },
  },

  removeCaseAssociation: {
    options: {
      onSuccess: (_, { personId, interactionId }, { queryClient }) =>
        queryClient.invalidateQueries({ queryKey: ['people', personId, 'interactions', interactionId, 'cases'] }),
    },
  },

  updateInteractionNote: {
    options: {
      onSuccess: (_, { personId }, { queryClient }) =>
        queryClient.invalidateQueries({ queryKey: ['people', personId, 'interaction-notes'] }),
    },
  },

  createInteractionNote: {
    options: {
      onSuccess: (_, { personId }, { queryClient }) =>
        queryClient.invalidateQueries({ queryKey: ['people', personId, 'interaction-notes'] }),
    },
  },

  unboundInteractionUpdate: {
    options: {
      onSuccess: (_, { interactionId, body }, { queryClient }) => {
        if (body?.personId) {
          queryClient.invalidateQueries({ queryKey: ['people', body.personId, 'interactions'] })
        }
        queryClient.invalidateQueries({ queryKey: ['interactions', interactionId] })
      },
    },
  },

  updateIdentity: {
    options: {
      onSuccess: (_, { personId }, { queryClient }) => {
        queryClient.invalidateQueries({ queryKey: ['people', personId, 'identity'] })
      },
    },
  },

  deleteIdentity: {
    options: {
      onSuccess: (_, { personId }, { queryClient }) => {
        queryClient.invalidateQueries({ queryKey: ['people', personId, 'identity'] })
      },
    },
  },
}

const getDefaultOptions = <Op extends Operation>(
  data: OperationData<Op>,
): Partial<typeof data extends { method: 'get' } ? QueryHookArguments<Op> : MutationOptions<Op>> =>
  defaultOptions[data.name]

export default getDefaultOptions
