import React, { FC, PropsWithChildren, ReactNode, useState } from 'react'

import { Content, Root, Trigger } from '@radix-ui/react-collapsible'
import styled from 'styled-components'

import IconButton from 'core/components/lib/IconButton'
import variables from 'core/styles/variables'

type HelpTextProps = PropsWithChildren<{
  className?: string
  text?: ReactNode
  title?: string
  subTitle?: string
  helpTextMargin?: string
  iconMargin?: string
}>

const HelpText: FC<HelpTextProps> = ({ className, children, text, title, helpTextMargin = '0 8px', iconMargin }) => {
  const [isOpen, onOpenChange] = useState(false)

  return text ?
      <Root open={isOpen} onOpenChange={onOpenChange}>
        <Row className={className}>
          {children}

          <Trigger asChild>
            <IconButton name={isOpen ? 'highlight_off' : 'help'} outlined fontSize='24px' margin={iconMargin} />
          </Trigger>
        </Row>

        <Box margin={helpTextMargin}>
          {title && <Title>{title}</Title>}
          <Paragraphs>{text}</Paragraphs>
        </Box>
      </Root>
    : <Row className={className}>{children}</Row>
}

export default HelpText

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 24px;
`

const Box = styled(Content)<{ margin?: string }>`
  position: relative;
  padding: 16px;
  margin: ${(p) => p.margin};
  font-size: 14px;
  color: ${variables.colorBlack70};
  background-color: ${variables.colorBlueLighten};
  border-radius: 8px;

  &::after {
    position: absolute;
    top: -16px;
    right: 20px;
    content: '';
    border-right: 8px solid transparent;
    border-bottom: 16px solid ${variables.colorBlueLighten};
    border-left: 8px solid transparent;
  }
`

const Title = styled.p`
  margin-top: 0;
  font-weight: 500;
`

const Paragraphs = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  white-space: pre-wrap;

  ul {
    padding-left: 16px;
    margin: 8px 0;
  }

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`
