import {
  APIendpoint,
  LOADING,
  getStoredToken,
  GET_AUTOPAY_PREVIEW_ERROR,
  GET_AUTOPAY_PREVIEW_INTERNAL_ERROR,
  GET_EXPECTED_PAYMENTS_PREVIEW_ERROR,
  GET_EXPECTED_PAYMENTS_PREVIEW_INTERNAL_ERROR,
} from 'core/actions/constants'
import { GET, POSTwithToken, DELETE, PUTwithToken } from 'core/api/legacyApi'
import { getGlobalValue } from 'core/badGlobalDoNotUse'
import { handleErrors } from 'core/helpers/handleErrors'

import {
  getTransactions,
  refreshLoanAndRelatedData,
  clearAutopayData,
  GET_EXPECTED_PAYMENTS,
} from './loanAndRelatedData'

export const CREATE_LOAN = 'CREATE_LOAN'
export const GET_NEXT_PAYMENT = 'GET_NEXT_PAYMENT'
export const GET_AUTOPAY_PREVIEW = 'GET_AUTOPAY_PREVIEW'
export const SET_AUTOPAY_SUCCESS = 'SET_AUTOPAY_SUCCESS'
export const UPDATE_AUTOPAY_SUCCESS = 'UPDATE_AUTOPAY_SUCCESS'
export const UPDATE_AUTOPAY_INTERNAL_ERROR = 'UPDATE_AUTOPAY_INTERNAL_ERROR'
export const SET_AUTOPAY_INTERNAL_ERROR = 'SET_AUTOPAY_INTERNAL_ERROR'
export const SET_AUTOPAY_ERROR = 'SET_AUTOPAY_ERROR'
export const GET_EXPECTED_PAYMENTS_PREVIEW = 'GET_EXPECTED_PAYMENTS_PREVIEW'
export const SET_EXPECTED_PAYMENTS = 'SET_EXPECTED_PAYMENTS'
export const SET_EXPECTED_PAYMENTS_ERROR = 'SET_EXPECTED_PAYMENTS_ERROR'
export const SET_EXPECTED_PAYMENTS_INTERNAL_ERROR = 'SET_EXPECTED_PAYMENTS_INTERNAL_ERROR'
export const RESET_FLAG = 'RESET_FLAG'
export const CHANGE_LOAN_NICKNAME = 'CHANGE_LOAN_NICKNAME'
export const GET_NEXT_AUTOPAY = 'GET_NEXT_AUTOPAY'
export const REMOVE_INTEREST_RATE = 'REMOVE_INTEREST_RATE'
export const GET_UNFREEZE_PREVIEW = 'GET_UNFREEZE_PREVIEW'
export const GET_SETUP_PAYMENT_PLAN_PREVIEW = 'GET_SETUP_PAYMENT_PLAN_PREVIEW'
export const CREATE_TRANSACTION = 'CREATE_TRANSACTION'
export const CREATE_TRANSACTION_SERVER_ERROR = 'CREATE_TRANSACTION_SERVER_ERROR'
export const CREATE_TRANSACTION_ERROR = 'CREATE_TRANSACTION_ERROR'
export const CANCEL_TRANSACTION = 'CANCEL_TRANSACTION'
export const DEBIT_TRANSACTION_COMPLETED = 'DEBIT_TRANSACTION_COMPLETED'
export const DEBIT_TRANSACTION_INITIATED = 'DEBIT_TRANSACTION_INITIATED'
export const DEBIT_TRANSACTION_TIMED_OUT = 'DEBIT_TRANSACTION_TIMED_OUT'
export const DEBIT_TRANSACTION_ERROR = 'DEBIT_TRANSACTION_ERROR'

const parseMessage = async (respBody) => {
  try {
    const json = await respBody.json()
    return json?.message
  } catch (e) {
    return undefined
  }
}

const getPersonId = () => getGlobalValue('borrowerPersonId')

const getLoanEndpoint = () => {
  return `${APIendpoint}/people/${getPersonId()}/loans`
}

const _getUpcomingData = (dateCollection, initialSchedule, options) => {
  const { getToday, closestTo } = options

  // collection of dates after today
  const today = getToday()
  const futureDateCollection = dateCollection.filter((date) => date >= today)

  // compare list of dates against today and get the closest
  const closestDateFromToday = closestTo(today, futureDateCollection)

  return initialSchedule.filter((data) => data.date === closestDateFromToday)
}

export const getNextPayment = (loanId, options) => {
  const { getToday, closestTo } = options

  return (dispatch) => {
    const endpoint = `${getLoanEndpoint()}/${loanId}/expected-payments`

    return fetch(endpoint, GET(getStoredToken()))
      .then(handleErrors)
      .then((response) => {
        // get list of dates from reponse
        // returns [] of all dates in ISO format
        const initialSchedule = response.data?.schedule || []
        const dateCollection = initialSchedule
          // TODO:
          // NOTE: commenting out this line for now until the backend
          // is fixed. This way it does not blow up the frontend with
          // empty `data` and undefined `data.date` where needed.
          // .filter(d => d.amount > 0)
          .map((data) => {
            return data.date
          })

        const data = _getUpcomingData(dateCollection, initialSchedule, {
          getToday,
          closestTo,
        })

        // if have mutilple entires on the same day (events, fees, etc)
        const combinedAmount = data.reduce((acc, curr) => {
          acc += curr.amount
          return acc
        }, 0)

        // get data of date closest to today
        dispatch({
          type: GET_NEXT_PAYMENT,
          payload: {
            loanId,
            data: data[0],
            totalAmount: combinedAmount,
          },
        })

        // returns all, in the case we need this data prior to selecting specifc loan
        dispatch({
          type: GET_EXPECTED_PAYMENTS,
          payload: { loanId, data: response.data },
        })
      })
  }
}

export const getExpectedPaymentsPreview = (loanId, paymentFrequency, specificDays) => {
  return async (dispatch) => {
    const endpoint = `${getLoanEndpoint()}/${loanId}/expected-payments`

    const body = {
      previewMode: true,
      dueDatesFrequency: paymentFrequency,
      specificDays: specificDays,
    }

    const response = await fetch(endpoint, POSTwithToken(getStoredToken(), body))
    const responseBody = await handleErrors(response)

    const isSuccess = response.status >= 200 && response.status <= 299
    const badRequest = response.status === 400
    const serverError = response.status === 500

    if (isSuccess) {
      dispatch({
        type: GET_EXPECTED_PAYMENTS_PREVIEW,
        payload: {
          loanId,
          data: responseBody.data,
        },
      })
    }

    // NOTE: setting this up temp - as there's a case it's breaking dev
    // need to decide how to handle 4xx and 5xx errors
    if (badRequest) {
      dispatch({
        type: GET_EXPECTED_PAYMENTS_PREVIEW_ERROR,
        payload: {
          loanId,
          data: 'Something has gone wrong, please try again.',
        },
      })
    }

    if (serverError) {
      dispatch({
        type: GET_EXPECTED_PAYMENTS_PREVIEW_INTERNAL_ERROR,
        payload: {
          loanId,
          data: `Your request couldn't be processed. Please try again later or contact customer support.`,
        },
      })
    }
  }
}

export const setExpectedPayments = (
  loanId,
  dueDatesFrequency,
  specificDays,
  agreementDocumentId = '',
  caseId = null,
) => {
  return async (dispatch) => {
    const endpoint = `${getLoanEndpoint()}/${loanId}/expected-payments`

    let body
    if (dueDatesFrequency.length > 0) {
      body = {
        previewMode: false,
        dueDatesFrequency,
        specificDays,
        agreementDocumentId,
      }
    } else {
      body = {
        previewMode: false,
        specificDays,
        agreementDocumentId,
      }
    }

    if (caseId) {
      body.caseId = caseId
    }

    const response = await fetch(endpoint, POSTwithToken(getStoredToken(), body))
    const responseBody = await handleErrors(response)

    const isSuccess = response.status >= 200 && response.status <= 299
    const badRequest = response.status === 400
    const serverError = response.status === 500

    if (badRequest) {
      const message = await parseMessage(responseBody)
      dispatch({
        type: SET_EXPECTED_PAYMENTS_ERROR,
        payload: {
          loanId,
          data: message || 'Something has gone wrong, please try again.',
        },
      })
    }

    if (serverError) {
      dispatch({
        type: SET_EXPECTED_PAYMENTS_INTERNAL_ERROR,
        payload: {
          loanId,
          data: `Your request couldn't be processed. Please try again later or contact customer support.`,
        },
      })
    }

    if (isSuccess) {
      dispatch({
        type: SET_EXPECTED_PAYMENTS,
        payload: {
          loanId,
          data: responseBody.data,
        },
      })
    }
  }
}

export const getNextAutopay = (loanId, options) => {
  const { closestTo, getToday } = options
  const today = getToday()

  return (dispatch) => {
    const endpoint = `${getLoanEndpoint()}/${loanId}/autopay`

    return fetch(endpoint, GET(getStoredToken()))
      .then(handleErrors)
      .then((response) => {
        if (response.status >= 400) {
          return {}
        } else {
          // get list of dates from reponse
          // returns [] of all dates in ISO format
          const filteredSchedule = response.data.schedule.filter((data) => {
            if (data.date === today) {
              return data.amount > 0.0 && data.status !== 'booked' && data.status !== 'modified'
            } else {
              return data
            }
          })
          const dateCollection = filteredSchedule.map((data) => {
            return data.date
          })

          const data = _getUpcomingData(dateCollection, filteredSchedule, {
            closestTo,
            getToday,
          })

          // get data of date closest to today
          dispatch({
            type: GET_NEXT_AUTOPAY,
            payload: {
              loanId,
              data: data[0],
            },
          })
        }
      })
  }
}

// REFACTOR: combine all autopay methods with optional parameters for body options
export const getAutopayPreview = (
  loanId,
  alignDueDates = false,
  paymentFrequency,
  specificDays,
  paymentInstrumentId,
) => {
  return async (dispatch) => {
    const endpoint = `${getLoanEndpoint()}/${loanId}/autopay`

    const body = {
      previewMode: true,
      paymentFrequency: paymentFrequency,
      paymentInstrumentId: paymentInstrumentId,
    }

    // specificDays + isAlignedToDueDates are mutually exclusive
    if (alignDueDates) {
      body.isAlignedToDueDates = alignDueDates
    } else {
      body.specificDays = specificDays
    }

    const response = await fetch(endpoint, POSTwithToken(getStoredToken(), body))

    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299
    const badRequest = response.status === 400
    const serverError = response.status === 500

    if (isSuccess) {
      dispatch({
        type: GET_AUTOPAY_PREVIEW,
        payload: {
          loanId,
          data: responseBody.data,
        },
      })
    }

    // NOTE: setting this up temp - as there's a case it's breaking dev
    // need to decide how to handle 4xx and 5xx errors
    if (badRequest) {
      dispatch({
        type: GET_AUTOPAY_PREVIEW_ERROR,
        payload: {
          loanId,
          data: 'Something has gone wrong, please try again.',
        },
      })
    }

    if (serverError) {
      dispatch({
        type: GET_AUTOPAY_PREVIEW_INTERNAL_ERROR,
        payload: {
          loanId,
          data: `Your request couldn't be processed. Please try again later or contact customer support.`,
        },
      })
    }
  }
}

export const setAutopay = (
  loanId,
  alignDueDates = false,
  paymentFrequency,
  specificDays,
  paymentInstrumentId,
  agreementDocumentId,
  caseId = null,
) => {
  return (dispatch) => {
    const endpoint = `${getLoanEndpoint()}/${loanId}/autopay`

    const body = {
      previewMode: false,
      isAlignedToDueDates: alignDueDates,
      paymentFrequency: paymentFrequency,
      specificDays: specificDays,
      paymentInstrumentId: paymentInstrumentId,
      agreementDocumentId: agreementDocumentId,
    }

    if (caseId) {
      body.caseId = caseId
    }

    return fetch(endpoint, POSTwithToken(getStoredToken(), body))
      .then(handleErrors)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: SET_AUTOPAY_SUCCESS,
            payload: {
              loanId,
              data: response.data,
            },
          })
        }
        // NOTE: setting this up temp - as there's a case it's breaking dev
        // we'll need decide how to handle 4xx and 5xx errors
        // think about if we're able to use Error Boundaries
        else if (response.status === 500) {
          dispatch({
            type: SET_AUTOPAY_INTERNAL_ERROR,
            payload: {
              loanId,
              data: `Your request couldn't be processed. Please try again later or contact customer support.`,
            },
          })
        } else {
          dispatch({
            type: SET_AUTOPAY_ERROR,
            payload: {
              loanId,
              data: response?.message || 'Something has gone wrong, please try again.',
            },
          })
        }
      })
      .then(() => {
        dispatch(refreshLoanAndRelatedData(loanId))
      })
      .catch(() => {})
  }
}

export const updateAutopay = (loanId, paymentInstrumentId, agreementDocumentId, caseId) => {
  return (dispatch) => {
    const endpoint = `${getLoanEndpoint()}/${loanId}/autopay`

    const body = {
      paymentInstrumentId: paymentInstrumentId,
      agreementDocumentId: agreementDocumentId,
    }

    if (caseId) {
      body.caseId = caseId
    }

    return fetch(endpoint, PUTwithToken(getStoredToken(), body))
      .then(handleErrors)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: UPDATE_AUTOPAY_SUCCESS,
            payload: {
              loanId,
              data: response.data,
            },
          })
        } else if (response.status === 500) {
          dispatch({
            type: UPDATE_AUTOPAY_INTERNAL_ERROR,
            payload: {
              loanId,
              data: `Your request couldn't be processed. Please try again later or contact customer support.`,
            },
          })
        }
      })
      .then(() => {
        dispatch(refreshLoanAndRelatedData(loanId))
      })
  }
}

export const deleteAutopay = (loanId) => {
  return (dispatch) => {
    const endpoint = `${getLoanEndpoint()}/${loanId}/autopay`

    if (getStoredToken()) {
      return fetch(endpoint, DELETE(getStoredToken()))
        .then(handleErrors)
        .then((response) => {
          dispatch(clearAutopayData(loanId))
          return response
        })
    }
  }
}

export const resetFlag = (loanId) => {
  return (dispatch) => {
    dispatch({
      type: RESET_FLAG,
      payload: { loanId },
    })
  }
}

export const changeNickname = (loanId, newNickname) => {
  return (dispatch) => {
    const endpoint = `${getLoanEndpoint()}/${loanId}`

    const body = { nickname: newNickname }

    return fetch(endpoint, PUTwithToken(getStoredToken(), body))
      .then(handleErrors)
      .then((response) => {
        dispatch({
          type: CHANGE_LOAN_NICKNAME,
          payload: {
            loanId,
            data: response.data.nickname,
          },
        })
      })
      .then(() => {
        dispatch(refreshLoanAndRelatedData(loanId))
      })
  }
}

/** NOTE: This is technically a thunk action, but it's not dispatching a
 * redux action at the moment, since the only thing using it so far is another
 * thunk action that just needs to look at the api results and not update
 * anything. If we eventually want to use this in components as a thunk
 * action, we may want to do some refactoring where transactions are stored
 * at the top level of the store similar to cases, for example.
 */
export const getTransactionById = (loanId, transactionId) => {
  return () => {
    const endpoint = `${getLoanEndpoint()}/${loanId}/transactions/${transactionId}`

    return fetch(endpoint, GET(getStoredToken())).then(handleErrors)
  }
}

export const freezeLoan = ({ loanId, caseId, key, sendNotice = true }) => {
  return async (dispatch) => {
    const endpoint = `${getLoanEndpoint()}/${loanId}/freeze`

    const body = { caseId, sendNotice }

    if (key) dispatch({ type: LOADING, key, status: 'loading' })

    const response = await fetch(endpoint, POSTwithToken(getStoredToken(), body))

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: response.ok ? 'success' : 'error',
      })
    }

    dispatch(refreshLoanAndRelatedData(loanId))
  }
}

export const chargeOffLoan = ({ caseId, chargedOffReason, effectiveDate, key, loanId, sendNotice = true }) => {
  return async (dispatch) => {
    dispatch({ type: LOADING, key, status: 'loading' })

    const endpoint = `${getLoanEndpoint()}/${loanId}/charge-off`

    const body = {
      caseId,
      chargedOffReason,
      effectiveDate,
      sendNotice,
    }

    const resp = await fetch(endpoint, POSTwithToken(getStoredToken(), body)).then(handleErrors)

    const status = resp.status === 200 ? 'success' : 'error'

    dispatch({ type: LOADING, key, status })

    dispatch(refreshLoanAndRelatedData(loanId))

    return resp
  }
}

export const accelerateLoan = ({ loanId, caseId, effectiveDate, key, sendNotice = true }) => {
  return async (dispatch) => {
    dispatch({ type: LOADING, key, status: 'loading' })

    const endpoint = `${getLoanEndpoint()}/${loanId}/accelerate`

    const body = { caseId, effectiveDate, sendNotice }

    const response = await fetch(endpoint, POSTwithToken(getStoredToken(), body)).then(handleErrors)

    const status = response.status === 200 ? 'success' : 'error'

    dispatch({ type: LOADING, key, status })

    dispatch(refreshLoanAndRelatedData(loanId))
  }
}

export const createTransaction = ({ loanId, body, key, paymentType, caseId }) => {
  return (dispatch) => {
    dispatch({ type: LOADING, key, status: 'loading' })

    const isCard = paymentType === 'card'

    if (isCard) {
      dispatch({
        type: DEBIT_TRANSACTION_INITIATED,
        payload: {
          loanId,
          step: 'initiated',
        },
      })
    }

    const endpoint = `${getLoanEndpoint()}/${loanId}/transactions?sync=${isCard}`

    if (caseId) {
      body.caseId = caseId
    }

    return fetch(endpoint, POSTwithToken(getStoredToken(), body))
      .then(handleErrors)
      .then((response) => {
        dispatch({
          type: LOADING,
          key,
          status: response.status === 201 ? 'success' : 'error',
        })
        return response
      })
      .then((response) => {
        return new Promise((resolve, reject) => {
          if (response.status === 201) {
            resolve(response)
            dispatch({
              type: CREATE_TRANSACTION,
              payload: {
                loanId,
                data: response.data,
              },
            })
          } else {
            reject(response)
          }
          return response
        })
      })
      .then((json) => {
        if (paymentType === 'card') {
          const { processingComplete } = json
          const { id } = json.data

          return new Promise((resolve, reject) => {
            dispatch(getTransactionById(loanId, id))
              .then(handleErrors)
              .then((res) => {
                const { status, failureDescriptionShort, failureDescriptionLong } = res.data || {}

                if (!processingComplete) {
                  dispatch({
                    type: DEBIT_TRANSACTION_TIMED_OUT,
                    payload: {
                      loanId,
                      step: 'completed',
                      hasTimedOut: true,
                    },
                  })
                } else if ((status === 'succeeded' || status === 'scheduled') && status !== 'initiated') {
                  dispatch(getTransactions({ loanId })).then(() => {
                    resolve(res)
                    dispatch({
                      type: DEBIT_TRANSACTION_COMPLETED,
                      payload: {
                        loanId,
                        step: 'completed',
                        status,
                        failureDescriptionShort,
                        failureDescriptionLong,
                      },
                    })
                  })
                } else if (status === 'failed') {
                  dispatch({
                    type: DEBIT_TRANSACTION_ERROR,
                    payload: {
                      loanId,
                      step: 'completed',
                      status,
                      failureDescriptionShort,
                      failureDescriptionLong,
                    },
                  })
                }
              })
          })
        }
        return json
      })
      .catch(async (response) => {
        if (response.status === 500) {
          dispatch({
            type: CREATE_TRANSACTION_SERVER_ERROR,
            payload: {
              loanId,
              data: 'Something has gone wrong, please contact support.',
            },
          })
        }
        // 402 Payment Required - Used for when payment processing is disabled.
        if (response.status === 402) {
          dispatch({
            type: CREATE_TRANSACTION_SERVER_ERROR,
            payload: {
              loanId,
              data: 'Payment processing is currently disabled, please try again later or contact support.',
            },
          })
        }
        if (response.status === 400) {
          const json = await response.json()

          dispatch({
            type: CREATE_TRANSACTION_ERROR,
            payload: {
              loanId,
              data: json?.message ?? 'Something went wrong. Please refresh and try again.',
            },
          })
        }
      })
  }
}

export const cancelTransaction = (loanId, transactionId) => {
  return (dispatch) => {
    const endpoint = `${getLoanEndpoint()}/${loanId}/transactions/${transactionId}/cancel`

    return fetch(endpoint, POSTwithToken(getStoredToken()))
      .then(handleErrors)
      .then((response) => {
        if (response.status === 204) {
          dispatch({
            type: CANCEL_TRANSACTION,
            payload: {
              loanId,
            },
          })
        }
      })
      .then(() => {
        dispatch(refreshLoanAndRelatedData(loanId))
      })
  }
}

export const reverseChargeOff = ({ loanId, caseId, key }) => {
  return async (dispatch) => {
    dispatch({ type: LOADING, key, status: 'loading' })
    const body = { caseId }
    const endpoint = `${getLoanEndpoint()}/${loanId}/reverse-charge-off`
    const response = await fetch(endpoint, POSTwithToken(getStoredToken(), body))
    const status = response.status === 200 ? 'success' : 'error'
    dispatch({ type: LOADING, key, status })
    dispatch(refreshLoanAndRelatedData(loanId))
  }
}

export const reverseAccelerate = ({ loanId, caseId, key }) => {
  return async (dispatch) => {
    dispatch({ type: LOADING, key, status: 'loading' })
    const body = { caseId }
    const endpoint = `${getLoanEndpoint()}/${loanId}/reverse-accelerate`
    const response = await fetch(endpoint, POSTwithToken(getStoredToken(), body))
    const status = response.status === 200 ? 'success' : 'error'
    dispatch({ type: LOADING, key, status })
    dispatch(refreshLoanAndRelatedData(loanId))
  }
}

/** This action is only meant to be used for testing purposes. */
export const createLoan = ({ personAddressId, loanTypeId }) => {
  return async (dispatch) => {
    const createLoanBody = {
      loanTypeId,
      servicedBy: 'creditor',
      status: 'originated',
      atOrigination: {
        amountFinanced: 10000.0,
        duration: 24,
        originationLicense: 'nationalBank',
        interestRates: [
          {
            days: null,
            rate: 0.1,
          },
        ],
        paymentFrequency: 'monthly',
        personAddressId,
      },
    }

    const response = await fetch(getLoanEndpoint(), POSTwithToken(getStoredToken(), createLoanBody))

    if (response.status >= 400) {
      return
    }

    const parsedResponse = await handleErrors(response)

    const activateEndpoint = `${getLoanEndpoint()}/${parsedResponse.data.id}/activate`

    const activateBody = {
      amortizationAtActivation: 'amortizeAtActivation',
    }

    const activateResponse = await fetch(activateEndpoint, POSTwithToken(getStoredToken(), activateBody))
    handleErrors(activateResponse)

    dispatch({
      type: CREATE_LOAN,
      payload: response.data,
    })
  }
}
