/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const purchaseDisputesListForPurchase: OperationData<Types.PurchaseDisputesListForPurchase> = {
  method: 'get',
  name: 'purchaseDisputesListForPurchase',
  summary: `Get purchase disputes`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}/disputes',
  queryKey: ({ personId, loanId, drawId, purchaseId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
    'disputes',
  ],
}

export const purchaseDisputeCreate: OperationData<Types.PurchaseDisputeCreate> = {
  method: 'post',
  name: 'purchaseDisputeCreate',
  summary: `Create purchase dispute`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}/disputes',
  queryKey: ({ personId, loanId, drawId, purchaseId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
    'disputes',
  ],
}

export const purchaseDisputesListForDraw: OperationData<Types.PurchaseDisputesListForDraw> = {
  method: 'get',
  name: 'purchaseDisputesListForDraw',
  summary: `Get draw purchase disputes`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/disputes',
  queryKey: ({ personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, 'disputes'],
}

export const purchaseDisputesListForLoc: OperationData<Types.PurchaseDisputesListForLoc> = {
  method: 'get',
  name: 'purchaseDisputesListForLoc',
  summary: `Get line of credit purchase disputes`,
  path: '/people/{personId}/loans/{loanId}/disputes',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'disputes'],
}

export const purchaseDisputeGet: OperationData<Types.PurchaseDisputeGet> = {
  method: 'get',
  name: 'purchaseDisputeGet',
  summary: `Get purchase dispute by ID`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}/disputes/{purchaseDisputeId}',
  queryKey: ({ personId, loanId, drawId, purchaseId, purchaseDisputeId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
    'disputes',
    purchaseDisputeId,
  ],
}

export const purchaseDisputeUpdate: OperationData<Types.PurchaseDisputeUpdate> = {
  method: 'put',
  name: 'purchaseDisputeUpdate',
  summary: `Update purchase dispute`,
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}/disputes/{purchaseDisputeId}',
  queryKey: ({ personId, loanId, drawId, purchaseId, purchaseDisputeId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
    'disputes',
    purchaseDisputeId,
  ],
}
