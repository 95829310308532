import { useState } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import Creatable from 'react-select/creatable'
import styled from 'styled-components'

import { DropdownIndicator, MenuList, Option, ValueContainer } from 'core/components/lib/Select/components'
import variables from 'core/styles/variables'

import customStyles from './customStyles'

const _formattedPlaceholder = (placeholder) =>
  typeof placeholder === 'string' ? <PlaceholderSpan>{placeholder}</PlaceholderSpan> : placeholder

const _formattedGroupLabel = (data) => <GroupLabel>{data.label}</GroupLabel>

/** @deprecated use SelectInput instead */
const Select = ({
  classNamePrefix,
  components = {},
  controlledValue,
  creatable,
  defaultValue,
  formatCreateLabel,
  formatOptionLabel,
  getPaymentMethodId,
  getSelectionOption,
  getSelectionValue,
  isClearable,
  isControlled,
  isDisabled,
  isLoading,
  isMulti,
  isOptionDisabled,
  isSearchable,
  maxMenuHeight,
  menuPlacement,
  options,
  placeholder,
  prefix,
  renderNoOption,
  setDayDateSelection,
  small,
  style = {},
}) => {
  const [selectedOption, setSelectedOption] = useState(() => {
    if (typeof defaultValue === 'string' || typeof defaultValue === 'number') {
      const op = options?.find((o) => o.value === defaultValue)
      return op ?
          {
            label: op.label,
            value: defaultValue,
          }
        : null
    }
    return defaultValue
  })

  // TODO: Move these class styles directly in the Select/index.scss file
  // instead of having them floating somewhere else.
  const selectClassnames = classNames({
    select: true,
    small: small,
  })

  // Methods and helpers --------------
  const _handleSelectChange = (selectedOption) => {
    if (!selectedOption) {
      const op = options && options.find((o) => o.value === defaultValue && o.value !== undefined)
      setSelectedOption({
        label: op ? op.label : defaultValue,
        value: defaultValue,
      })
    } else {
      setSelectedOption(selectedOption)
    }

    getSelectionOption && getSelectionOption(selectedOption)
    // REFACTOR: the two methods below seem to be doing the same thing
    getSelectionValue && getSelectionValue(selectedOption?.value)
    setDayDateSelection && setDayDateSelection(selectedOption?.value)
    getPaymentMethodId && getPaymentMethodId(selectedOption?.id)
  }

  const _handleNoOptions = () => {
    return renderNoOption?.() ?? 'No options'
  }

  return creatable ?
      <Creatable
        styles={{ ...customStyles, ...style }}
        classNamePrefix={classNamePrefix ?? 'creatable'}
        components={{ ...components, DropdownIndicator, MenuList, ValueContainer, Option }}
        prefix={prefix}
        formatCreateLabel={formatCreateLabel}
        formatGroupLabel={_formattedGroupLabel}
        formatOptionLabel={formatOptionLabel}
        isClearable={isClearable}
        isDisabled={isDisabled || isLoading}
        isLoading={isLoading}
        isOptionDisabled={isOptionDisabled}
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
        noOptionsMessage={() => _handleNoOptions()}
        onChange={_handleSelectChange}
        options={options}
        placeholder={_formattedPlaceholder(placeholder ?? 'Select an option')}
        value={isControlled ? controlledValue : selectedOption}
      />
    : <ReactSelect
        styles={{ ...customStyles, ...style }}
        className={selectClassnames}
        classNamePrefix={classNamePrefix ?? 'select'}
        components={{ ...components, DropdownIndicator, ValueContainer, Option }}
        prefix={prefix}
        formatGroupLabel={_formattedGroupLabel}
        formatOptionLabel={formatOptionLabel}
        isClearable={isClearable}
        isDisabled={isDisabled || isLoading}
        isLoading={isLoading}
        isOptionDisabled={isOptionDisabled}
        isMulti={isMulti}
        isSearchable={!!isSearchable}
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
        noOptionsMessage={() => _handleNoOptions()}
        onChange={_handleSelectChange}
        options={options}
        placeholder={_formattedPlaceholder(placeholder)}
        value={isControlled ? controlledValue : selectedOption}
        wrap
      />
}

Select.propTypes = {
  classNamePrefix: PropTypes.string,
  components: PropTypes.any,
  controlledValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  creatable: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  formatCreateLabel: PropTypes.func,
  formatOptionLabel: PropTypes.func,
  getPaymentMethodId: PropTypes.func,
  getSelectionOption: PropTypes.func,
  getSelectionValue: PropTypes.func,
  isClearable: PropTypes.bool,
  isControlled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  isSearchable: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
  menuPlacement: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.arrayOf(PropTypes.object)]),
  placeholder: PropTypes.node,
  prefix: PropTypes.node,
  renderNoOption: PropTypes.func,
  setDayDateSelection: PropTypes.func,
  small: PropTypes.bool,
}

Select.defaultProps = {
  controlledValue: null,
}

const PlaceholderSpan = styled.span`
  color: ${variables.colorBlack50};
`
const GroupLabel = styled.div`
  font-size: 12px;
  color: ${variables.colorBlack50};
  text-transform: uppercase;
`

export default Select
