import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { useRect } from 'core/hooks'
import variables from 'core/styles/variables'

import { useLoginInfo } from './LoginWrapper'
import TermsAndConditions from './TermsAndConditions'

const Card = styled.div`
  max-width: 480px;
  padding: ${(p) => (p.isCompact ? '16px' : '28px 40px')};
  margin: auto;
  background: ${variables.colorWhite};
  border-radius: 6px;
  box-shadow: ${variables.peachyShadow};
`

const TitleArea = styled.div`
  padding-bottom: 32px;
`

const Title = styled.div`
  font-size: 28px;
  font-weight: 500;
  color: ${variables.colorBlack90};
`

const Contents = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-top: 32px;

    &:first-child {
      margin-top: 0;
    }
  }
`

const TITLES = {
  agent: 'Agent Login',
  borrower: 'Account Login',
  admin: 'Admin Login',
}

const LoginFormCard = ({ children }) => {
  const { appDomainType, userType } = useLoginInfo()

  const [{ width }, ref] = useRect()

  const isCompact = width < 420

  const title = TITLES[appDomainType] || 'Login'

  const showTermsAndConditions = userType === 'borrower'

  return (
    <Card ref={ref} isCompact={isCompact}>
      {title && (
        <TitleArea>
          <Title>{title}</Title>
        </TitleArea>
      )}

      <Contents>
        {children}
        {showTermsAndConditions && <TermsAndConditions />}
      </Contents>
    </Card>
  )
}

LoginFormCard.propTypes = {
  children: PropTypes.node,
}

export default LoginFormCard
