import _ from 'lodash'
import styled, { css } from 'styled-components'

import { isLoggedIn } from 'core/actions/helpers/tokenHelpers'
import LinkBase from 'core/components/lib/Link'
import { useAccountOptions, useBorrowerBaseUrl } from 'core/hooks'
import { Option } from 'core/hooks/useAccountOptions'

const Links = styled.div`
  display: inline-flex;
  flex-direction: column;
`

const linkStyles = css`
  margin-bottom: 16px;
`

const Link = styled(LinkBase)`
  ${linkStyles}
`

const canDisplayLink = (option?: Option): boolean => {
  const { action, href, label, disabled, hide } = option || {}

  return disabled !== true && hide !== true && !!label && (!!action || !!href)
}

const FooterLinks = () => {
  const baseUrl = useBorrowerBaseUrl()

  const options = useAccountOptions({ isMobile: false })

  const helpOption = _.find(options, (option) => {
    return option.icon === 'help'
  })

  const isloggedIn = isLoggedIn()

  return (
    <Links>
      {!isloggedIn ?
        <>
          <Link to={`${baseUrl}/login`}>Login</Link>

          {!_.isEmpty(helpOption) && canDisplayLink(helpOption) && (
            <Link href={helpOption?.href}>{helpOption?.label}</Link>
          )}
        </>
      : <>
          {_.map(options, (option?: Option) => {
            if (!canDisplayLink(option)) return null

            const { action } = option || {}
            const to = action ? `${baseUrl}${action}` : undefined

            return (
              <Link key={option?.label} to={to} href={option?.href}>
                {option?.label}
              </Link>
            )
          })}
        </>
      }
    </Links>
  )
}

export default FooterLinks
