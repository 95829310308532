import { createRef, useState, useEffect, useCallback } from 'react'

import { useDraft } from 'contexts/DraftContext'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { LOADING } from 'core/actions/constants'
import clearDocumentDescriptors from 'core/actions/documents/clearDocumentDescriptors'
import createDocumentDescriptors from 'core/actions/documents/createDocumentDescriptors'
import uploadFiles from 'core/actions/documents/uploadFiles'
import { FileUploadModal } from 'core/components'
import useInterval from 'core/hooks/useInterval'
import { makeGetLoadingStatus } from 'core/selectors/loading'
import { useTime } from 'core/time'

import {
  MAX_UPLOAD_SIZE_DEFAULT,
  MULTI_FILE_ERROR,
  MAX_FILE_NAME_CHAR_LIMIT,
  MAX_FILE_NAME_CHAR_ERROR,
  FileUploadContext,
} from './index'

const getCreateDocumentDescriptorsLoadingStatus = makeGetLoadingStatus('CreateDocumentDescriptors')
const getUploadFileLoadingStatus = makeGetLoadingStatus('UploadFiles')

const FileUploadContextProvider = ({ children }) => {
  const dispatch = useDispatch()
  const documentDescriptors = useSelector((state) => state.documents.byId)
  const fileInput = createRef()
  const { addDraft, removeDraft } = useDraft()
  const { getNow, difference } = useTime()

  const [acceptedFileTypes, setAcceptedFileTypes] = useState('')
  const [error, setError] = useState(null)
  const [selectedFiles, setSelectedFiles] = useState(null)
  const [autoUpload, setAutoUpload] = useState(false)
  const [maxFileSize, setMaxFileSize] = useState(MAX_UPLOAD_SIZE_DEFAULT)
  const [allowMultiFileUpload, setAllowMultiFileUpload] = useState(false)
  const [maxFileNameCharLimit, setMaxFileNameCharLimit] = useState(MAX_FILE_NAME_CHAR_LIMIT)
  const [showUploadStatusModal, setShowUploadStatusModal] = useState(false)
  const [startTime, setStartTime] = useState(() => getNow())
  const [elapsedTime, setElapsedTime] = useState(undefined)
  const [delay, setDelay] = useState(1000)
  const [documentUploadLinkKey, setDocumentUploadLinkKey] = useState(false)

  // ----------------------------------
  // File upload status
  const documentDescriptorsLoadingStatus = useSelector(getCreateDocumentDescriptorsLoadingStatus)
  const uploadFileLoadingStatus = useSelector(getUploadFileLoadingStatus)
  const isUploading = documentDescriptorsLoadingStatus === 'loading' || uploadFileLoadingStatus === 'loading'

  useEffect(() => {
    if (isUploading) {
      setDelay(1000)
      setStartTime(getNow())
    }
  }, [isUploading, getNow])

  useEffect(() => {
    if (uploadFileLoadingStatus !== 'loading') {
      setDelay(null)
    }
  }, [uploadFileLoadingStatus])

  // ----------------------------------
  // Draft state
  useEffect(() => {
    if (isUploading) {
      addDraft('FileUpload', {
        ignoreAppRouteChange: true,
      })
    } else {
      removeDraft('FileUpload')
    }
  }, [isUploading, addDraft, removeDraft])

  // ----------------------------------
  // Upload progress variables and methods
  const progress = useSelector((state) => state.communicator.requestProgress)
  let progressTotal = 0
  let progressLoaded = 0

  if (!_.isEmpty(progress)) {
    Object.values(progress).forEach((file) => {
      progressTotal += file?.total
      progressLoaded += file?.loaded
    })
  }

  // ----------------------------------
  // Open file selector
  const onOpenFileSelector = ({
    acceptedFileTypes = '',
    autoUpload = false,
    maxFileSize,
    allowMultiFileUpload = true,
    maxFileNameCharLimit = MAX_FILE_NAME_CHAR_LIMIT,
    magicLinkKey = null,
  }) => {
    setDocumentUploadLinkKey(magicLinkKey)
    localStorage.setItem('cancelUpload', 'false')
    setAcceptedFileTypes(acceptedFileTypes)
    setAutoUpload(autoUpload)
    if (maxFileSize) setMaxFileSize(maxFileSize)
    setAllowMultiFileUpload(allowMultiFileUpload)
    setMaxFileNameCharLimit(maxFileNameCharLimit)

    fileInput.current.value = ''
    fileInput.current.accept = acceptedFileTypes
    fileInput.current.click()

    /**
     * Reseting the CreateDocumentDescriptor loading key here
     * because it is a dependency for when the `fileInput` ref
     * should be clicked in the `useEffect()` hook below
     */
    dispatch({
      type: LOADING,
      key: 'CreateDocumentDescriptors',
      status: undefined,
    })
  }

  // ----------------------------------
  // Input change event
  const _onInputChange = (e) => {
    setError(null)

    const files = Object.values(e.target.files).map((file) => file)

    // Max file size check
    let fileSizeLimitExceeded = false
    files.forEach((file) => {
      if (file.size > maxFileSize) {
        fileSizeLimitExceeded = true
      }
    })
    if (fileSizeLimitExceeded) {
      const fileSizeInMegaBytes = Math.round((maxFileSize / 100000) * 100) / 100
      setError(`This file exceeds the ${fileSizeInMegaBytes} megabyte limit. Please try a different file.`)
      return
    }

    // Multi file check
    if (files.length > 1 && !allowMultiFileUpload) {
      setError(MULTI_FILE_ERROR)
      return
    }

    // File name character limit check
    let fileNameCharLimitExceeded = false
    files.forEach((file) => {
      if (file.name.length > maxFileNameCharLimit) {
        fileNameCharLimitExceeded = true
      }
    })
    if (fileNameCharLimitExceeded) {
      setError(MAX_FILE_NAME_CHAR_ERROR)
      return
    }

    setSelectedFiles(files)

    if (autoUpload) {
      onCreateDocumentDescriptors(files)
    }
  }

  // ----------------------------------
  // Create document descriptors
  const onCreateDocumentDescriptors = (files) => {
    if (!files) {
      files = selectedFiles
    }
    const body = files.map((file) => {
      return {
        type: 'other',
        loanId: null,
        fileName: file.name,
      }
    })
    dispatch(clearDocumentDescriptors())
    dispatch(
      createDocumentDescriptors({
        key: 'CreateDocumentDescriptors',
        files: body,
        documentUploadLinkKey,
      }),
    )
  }

  // ----------------------------------
  // Upload method
  const _onUploadFiles = useCallback(() => {
    localStorage.setItem('cancelUpload', 'false')
    setShowUploadStatusModal(true)
    dispatch(
      uploadFiles({
        key: 'UploadFiles',
        files: selectedFiles,
        documentDescriptors,
        documentUploadLinkKey,
      }),
    )
  }, [dispatch, selectedFiles, documentDescriptors, documentUploadLinkKey])

  useEffect(() => {
    if (documentDescriptorsLoadingStatus !== undefined && documentDescriptorsLoadingStatus !== 'loading') {
      _onUploadFiles()
    }
  }, [documentDescriptorsLoadingStatus, _onUploadFiles])

  // ----------------------------------
  // Cancel upload
  const onCancelUpload = () => {
    localStorage.setItem('cancelUpload', 'true')
    setShowUploadStatusModal(false)
    dispatch({
      type: LOADING,
      key: 'UploadFiles',
      status: undefined,
    })
  }

  // ----------------------------------
  // Retry methods and hooks
  const onRetryUpload = () => {
    onCancelUpload()
    setTimeout(() => {
      _onUploadFiles()
      setStartTime(getNow())
    }, 10)
  }

  useInterval(() => {
    const currentTime = getNow()
    setElapsedTime(difference(currentTime, startTime, 'seconds'))
  }, delay)

  useEffect(() => {
    if (isUploading) {
      setDelay(1000)
      setStartTime(getNow())
    }
  }, [isUploading, getNow])

  return (
    <FileUploadContext.Provider
      value={{
        onOpenFileSelector,
        onUploadFiles: onCreateDocumentDescriptors,
        onCancelUpload,
        setShowUploadStatusModal,
        onRetryUpload,
        elapsedTime,
        error,
        progress: {
          loaded: progressLoaded,
          total: progressTotal,
        },
      }}
    >
      <input
        ref={fileInput}
        type='file'
        name='file'
        id='fileElem'
        multiple='multiple'
        accept={acceptedFileTypes}
        onChange={_onInputChange}
        style={{ display: 'none' }}
      />
      {children}
      {showUploadStatusModal && (
        <FileUploadModal
          elapsedTime={elapsedTime}
          error={error}
          onCancelUpload={onCancelUpload}
          onOpenFileSelector={onOpenFileSelector}
          onRetryUpload={onRetryUpload}
          progress={progress}
          setShowUploadStatusModal={setShowUploadStatusModal}
        />
      )}
    </FileUploadContext.Provider>
  )
}

FileUploadContextProvider.propTypes = {
  children: PropTypes.node,
}

export default FileUploadContextProvider
