import { checkTimeordate } from '../types'
import mtz from '../utils/mtz'

const makeStartOfDay = (context = {}) => {
  const { timezone } = context

  const startOfDay = (value) => {
    checkTimeordate(value)

    return mtz(value, timezone).startOf('day').toISOString()
  }

  return startOfDay
}

export default makeStartOfDay
