import { LOADING, APIendpoint, getStoredToken } from 'core/actions/constants'
import { POSTwithToken } from 'core/api/legacyApi'
import { getGlobalValue } from 'core/badGlobalDoNotUse'

export const RENDER_TO_DOCUMENT = 'RENDER_TO_DOCUMENT'
export const RENDER_DOCUMENT = 'RENDER_DOCUMENT'
export const INITIATE_RENDER_DOCUMENT = 'INITIATE_RENDER_DOCUMENT'
export const SEND_VERIFICATION_CODE_SUCCESS = 'SEND_VERIFICATION_CODE_SUCCESS'
export const SEND_VERIFICATION_CODE_MAX_ATTEMPT = 'SEND_VERIFICATION_CODE_MAX_ATTEMPT'
export const SEND_VERIFICATION_CODE_TIMEOUT = 'SEND_VERIFICATION_CODE_TIMEOUT'
export const SEND_VERIFICATION_CODE_FAILED = 'SEND_VERIFICATION_CODE_FAILED'
export const REQUEST_PROGRESS = 'REQUEST_PROGRESS'
export const CLEAR_COMMUNICATION_ERROR = 'CLEAR_COMMUNICATION_ERROR'

const communicatorEndpoint = `${APIendpoint}/communicator`

/**
 * Renders data without saving it anywhere for a faster way of showcasing data
 */
export const renderDocument = ({ subject, channel = 'gui', loanId, data }) => {
  return (dispatch) => {
    const endpoint = `${communicatorEndpoint}/render`

    const body = {
      subject,
      channel,
      personId: getGlobalValue('borrowerPersonId'),
      context: data,
      loanId,
    }

    dispatch({
      type: INITIATE_RENDER_DOCUMENT,
    })

    return fetch(endpoint, POSTwithToken(getStoredToken(), body))
      .then((response) => {
        return response.text()
      })
      .then((html) => {
        dispatch({
          type: RENDER_DOCUMENT,
          payload: html,
        })
      })
  }
}

/**
 * Renders data to the previous initial document descriptor that is in draft mode
 */
export const renderToDocument = ({ subject, documentId, data, loanId }) => {
  return (dispatch) => {
    const endpoint = `${communicatorEndpoint}/render-to-document`

    const body = {
      fmt: 'pdf',
      subject,
      channel: 'document',
      personId: getGlobalValue('borrowerPersonId'),
      documentId: documentId,
      context: data,
      loanId,
    }

    return fetch(endpoint, POSTwithToken(getStoredToken(), body))
      .then((response) => {
        return response.text()
      })
      .then((html) => {
        dispatch({
          type: RENDER_TO_DOCUMENT,
          payload: html,
        })
      })
  }
}

export const sendVerificationCode = ({ key, dataType, channel, data }) => {
  return async (dispatch) => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading',
      })
    }

    const body = {
      personId: getGlobalValue('borrowerPersonId'),
      [dataType]: data,
      channel: channel,
    }

    const endpoint = `${communicatorEndpoint}/send-confirmation-code`
    const response = await fetch(endpoint, POSTwithToken(getStoredToken(), body))
    const isSuccess = response.status >= 200 && response.status <= 299
    const badRequest = response.status === 400
    const maxRequest = response.status === 429
    const timeoutRequest = response.status === 408

    if (isSuccess) {
      dispatch({
        type: SEND_VERIFICATION_CODE_SUCCESS,
      })
    }

    if (badRequest) {
      dispatch({
        type: SEND_VERIFICATION_CODE_FAILED,
        payload: {
          errorMessage: `Verification code couldn't be sent due to a technical issue. Please try again or contact Customer Support for help.`,
          errorStatus: 'generalFailed',
        },
      })
    }

    if (maxRequest) {
      dispatch({
        type: SEND_VERIFICATION_CODE_MAX_ATTEMPT,
        payload: {
          errorMessage: `Verification failed as you've exceeded the maximum number of attempts.`,
          errorStatus: 'maxRequest',
        },
      })
    }

    if (timeoutRequest) {
      dispatch({
        type: SEND_VERIFICATION_CODE_TIMEOUT,
        payload: {
          errorMessage: 'The verification code we’ve sent expired. Try to send a new code.',
          errorStatus: 'timeoutRequest',
        },
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error',
      })
    }
  }
}
