import { combineReducers } from 'redux'

import {
  BORROWER_LOANS_LOADING,
  BORROWER_LOANS_SUCCESS,
  BORROWER_LOANS_ERROR,
  BORROWER_LOAN_LOADING,
  BORROWER_LOAN_SUCCESS,
  BORROWER_LOAN_ERROR,
} from 'core/actions/borrowerLoading'

const inititalState = {
  loading: false,
  success: false,
  error: false,
}

const loansLoadingReducer = (state = inititalState, action) => {
  const { type } = action

  switch (type) {
    case BORROWER_LOANS_LOADING:
      return { ...state, loading: true }
    case BORROWER_LOANS_SUCCESS:
      return { loading: false, success: true, error: false }
    case BORROWER_LOANS_ERROR:
      return { loading: false, success: false, error: true }
    default:
      return state
  }
}

const loanLoadingIndexReducer = (state = {}, action) => {
  const { type, loanId } = action
  const loanState = state[loanId]

  const updateLoan = (newLoanState) => {
    return { ...state, [loanId]: { ...loanState, ...newLoanState } }
  }

  switch (type) {
    case BORROWER_LOAN_LOADING:
      return updateLoan({ loading: true })
    case BORROWER_LOAN_SUCCESS:
      return updateLoan({ loading: false, success: true, error: false })
    case BORROWER_LOAN_ERROR:
      return updateLoan({ loading: false, success: false, error: false })
    default:
      return state
  }
}

export default combineReducers({
  loans: loansLoadingReducer,
  loan: loanLoadingIndexReducer,
})
