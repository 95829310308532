import styled, { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { CompanyLogo } from 'core/components'
import Footer from 'core/components/Layout/Footer/Footer'
import QueryProvider from 'core/system/queries/QueryProvider'

const LoginContentsArea = styled.div`
  padding: 16px;
  padding-bottom: 256px;
`

const LogoContainer = styled.div`
  padding-bottom: 16px;
  text-align: center;
`

const LoginLayout = ({ companyTheme, children }: { companyTheme: any; children: React.ReactNode }) => {
  return (
    <QueryProvider>
      <StyledThemeProvider theme={companyTheme}>
        <LoginContentsArea>
          <LogoContainer>
            <CompanyLogo type='appLargeCentered' />
          </LogoContainer>
          {children}
        </LoginContentsArea>
        <Footer />
      </StyledThemeProvider>
    </QueryProvider>
  )
}

export default LoginLayout
