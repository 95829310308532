import { APIendpoint, GET_PERMISSIONS, LOADING, getStoredToken } from 'core/actions/constants'
import { GET } from 'core/api/legacyApi'
import { handleErrors } from 'core/helpers/handleErrors'

const getPermissions = ({ key }) => {
  return async (dispatch) => {
    if (key) {
      dispatch({ type: LOADING, key, status: 'loading' })
    }

    const endpoint = `${APIendpoint}/auth/permissions`
    const response = await fetch(endpoint, GET(getStoredToken()))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299

    if (isSuccess) {
      dispatch({ type: GET_PERMISSIONS, payload: responseBody.data })
    }

    if (key) {
      dispatch({ type: LOADING, key, status: isSuccess ? 'success' : 'error' })
    }
  }
}

export default getPermissions
