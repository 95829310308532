import UnhandledExceptions from 'app/UnhandledExceptions'

import ErrorBoundary from 'core/components/lib/ErrorBoundary/ErrorBoundary'

import BootstrapManager from './BootstrapManager'

export default function Root() {
  return (
    <ErrorBoundary ident='AppRoot' isAppRoot>
      <UnhandledExceptions>
        <BootstrapManager />
      </UnhandledExceptions>
    </ErrorBoundary>
  )
}
