import { css } from 'styled-components'

import { variables } from 'core/styles'

export const errorBannerCss = css`
  color: ${variables.colorBlack70};
  background-color: ${variables.colorRedLighten};
`

export const successBannerCss = css`
  color: ${variables.colorBlack70};
  background-color: ${variables.colorGreenLighten};
`

export const warningBannerCss = css`
  color: ${variables.colorBlack70};
  background-color: ${variables.colorOrangeLighten};
`
