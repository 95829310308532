/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const companyGetAll: OperationData<Types.CompanyGetAll> = {
  method: 'get',
  name: 'companyGetAll',
  summary: `Get all companies`,
  path: '/companies',
  queryKey: ({}) => ['companies'],
}

export const createCompany: OperationData<Types.CreateCompany> = {
  method: 'post',
  name: 'createCompany',
  summary: `Create a new company`,
  path: '/companies',
  queryKey: ({}) => ['companies'],
}

export const companyGet: OperationData<Types.CompanyGet> = {
  method: 'get',
  name: 'companyGet',
  summary: `Get a company`,
  path: '/companies/{companyId}',
  queryKey: ({ companyId }) => ['companies', companyId],
}

export const updateCompany: OperationData<Types.UpdateCompany> = {
  method: 'put',
  name: 'updateCompany',
  summary: `Update a company`,
  path: '/companies/{companyId}',
  queryKey: ({ companyId }) => ['companies', companyId],
}

export const companyAppDomainGetAll: OperationData<Types.CompanyAppDomainGetAll> = {
  method: 'get',
  name: 'companyAppDomainGetAll',
  summary: `Get all app domains for a company`,
  path: '/companies/{companyId}/app-domains',
  queryKey: ({ companyId }) => ['companies', companyId, 'app-domains'],
}

export const companyAppDomainPost: OperationData<Types.CompanyAppDomainPost> = {
  method: 'post',
  name: 'companyAppDomainPost',
  summary: `Create a company app domain.`,
  path: '/companies/{companyId}/app-domains',
  queryKey: ({ companyId }) => ['companies', companyId, 'app-domains'],
}

export const companyAppDomainGet: OperationData<Types.CompanyAppDomainGet> = {
  method: 'get',
  name: 'companyAppDomainGet',
  summary: `Get an app domain for a company`,
  path: '/companies/{companyId}/app-domains/{companyAppDomainId}',
  queryKey: ({ companyId, companyAppDomainId }) => ['companies', companyId, 'app-domains', companyAppDomainId],
}

export const companyAppDomainPut: OperationData<Types.CompanyAppDomainPut> = {
  method: 'put',
  name: 'companyAppDomainPut',
  summary: `Update an app domain for a company`,
  path: '/companies/{companyId}/app-domains/{companyAppDomainId}',
  queryKey: ({ companyId, companyAppDomainId }) => ['companies', companyId, 'app-domains', companyAppDomainId],
}

export const getCompanyConfig: OperationData<Types.GetCompanyConfig> = {
  method: 'get',
  name: 'getCompanyConfig',
  summary: `Get the company configuration`,
  path: '/companies/{companyId}/config',
  queryKey: ({ companyId }) => ['companies', companyId, 'config'],
}

export const setCompanyConfig: OperationData<Types.SetCompanyConfig> = {
  method: 'put',
  name: 'setCompanyConfig',
  summary: `Set the company configuration`,
  path: '/companies/{companyId}/config',
  queryKey: ({ companyId }) => ['companies', companyId, 'config'],
}

export const legacyUpdatePlaidCredentials: OperationData<Types.LegacyUpdatePlaidCredentials> = {
  method: 'post',
  name: 'legacyUpdatePlaidCredentials',
  summary: `Update a company's plaid credentials (DEPRECATED)`,
  path: '/companies/{companyId}/credentials/plaid',
  queryKey: ({ companyId }) => ['companies', companyId, 'credentials', 'plaid'],
}

export const updatePlaidCredentials: OperationData<Types.UpdatePlaidCredentials> = {
  method: 'post',
  name: 'updatePlaidCredentials',
  summary: `Update a company's Plaid credentials`,
  path: '/companies/{companyId}/3rdparty/plaid/credentials',
  queryKey: ({ companyId }) => ['companies', companyId, '3rdparty', 'plaid', 'credentials'],
}

export const getByoTwilio: OperationData<Types.GetByoTwilio> = {
  method: 'get',
  name: 'getByoTwilio',
  summary: `Get a company's BYO TWilio`,
  path: '/companies/{companyId}/3rdparty/byo-twilio',
  queryKey: ({ companyId }) => ['companies', companyId, '3rdparty', 'byo-twilio'],
}

export const updateByoTwilio: OperationData<Types.UpdateByoTwilio> = {
  method: 'put',
  name: 'updateByoTwilio',
  summary: `Update a company's BYO TWilio`,
  path: '/companies/{companyId}/3rdparty/byo-twilio',
  queryKey: ({ companyId }) => ['companies', companyId, '3rdparty', 'byo-twilio'],
}

export const syncByoTwilio: OperationData<Types.SyncByoTwilio> = {
  method: 'post',
  name: 'syncByoTwilio',
  summary: `Sync a company's BYO Twilio settings`,
  path: '/companies/{companyId}/3rdparty/byo-twilio/sync',
  queryKey: ({ companyId }) => ['companies', companyId, '3rdparty', 'byo-twilio', 'sync'],
}
