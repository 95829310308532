import { checkTimeordate } from '../types'
import mtz from '../utils/mtz'

const makeFormat = (context = {}) => {
  const format = (timeordate, formatString = 'D MMM Y, h:mm A', timezone) => {
    if (!timeordate) return null

    checkTimeordate(timeordate)

    return timezone ?
        mtz(timeordate, context.timezone).tz(timezone).format(formatString)
      : mtz(timeordate, context.timezone).format(formatString)
  }

  return format
}

export default makeFormat
