import { AuthTypes } from 'noauth/pages/Login/LoginWrapper/LoginInfoProvider'

import { APIendpoint } from 'core/actions/helpers/tokenHelpers'
import { POST } from 'core/api/legacyApi'

export const parseError = (error?: string | Error) => {
  return (
    typeof error === 'string' ? error
    : typeof error?.message === 'string' ? error.message
    : undefined
  )
}

export const loginWithPassword = async ({
  username,
  password,
  userType,
  companyId,
  authType,
}: {
  username: string
  password: string
  userType: string
  companyId: string
  authType: AuthTypes
}) => {
  const resp = await fetch(
    `${APIendpoint}/auth/token?userType=${userType}&companyId=${companyId}&authType=${authType}`,
    POST({ username, password }),
  )
  const json = await resp.json()

  if (resp.ok) {
    return json
  } else {
    throw json
  }
}

export const requestOneTimeCode = async ({
  username,
  companyId,
  userType,
  channel,
  force,
}: {
  username: string
  companyId: string
  userType: string
  channel: string
  force?: boolean
}) => {
  const resp = await fetch(
    `${APIendpoint}/companies/${companyId}/auth/send-one-time-code`,
    POST({ username, channel, userType, force }),
  )
  const json = await resp.json()

  if (resp.ok) {
    return json
  } else {
    throw json
  }
}
