import React, { useState } from 'react'

import styled from 'styled-components'

import { APIendpoint, getStoredToken } from 'core/actions/constants'
import { POSTwithToken, PUTwithToken, GET, DELETE } from 'core/api/legacyApi'
import variables from 'core/styles/variables'

const Input = styled.input`
  padding: 4px;
  border: 1px solid ${variables.colorBlack50};
`

const TextArea = styled.textarea`
  padding: 4px;
  border: 1px solid ${variables.colorBlack50};
`

const Button = styled.button`
  padding: 4px;
`

const Select = styled.select`
  width: 160px;
`

const Section = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 16px;
`

const InputLabel = styled.span`
  margin-right: 16px;
`

const GraySpan = styled.span`
  margin-right: 4px;
  color: gray;
`

const methodOptions = [
  {
    label: 'POST',
    value: 'post',
  },
  {
    label: 'PUT',
    value: 'put',
  },
  {
    label: 'GET',
    value: 'get',
  },
  {
    label: 'DELETE',
    value: 'delete',
  },
]

const callApiEndpoint = ({ url, body, method }) => {
  const endpoint = `${APIendpoint}${url}`
  const parsedBody = body ? JSON.parse(body) : undefined

  switch (method) {
    case 'post':
      fetch(endpoint, POSTwithToken(getStoredToken(), parsedBody))
      return
    case 'put':
      fetch(endpoint, PUTwithToken(getStoredToken(), parsedBody))
      return
    case 'get':
      fetch(endpoint, GET(getStoredToken()))
      return
    case 'delete':
      fetch(endpoint, DELETE(getStoredToken()))
      break
    default:
  }
}

const GenericApiCall = () => {
  const [url, setUrl] = useState('')
  const [body, setBody] = useState('')
  const [method, setMethod] = useState('post')

  return (
    <div>
      <h3>Generic API Call</h3>
      <Section>
        <InputLabel>HTTP method:</InputLabel>
        <Select onChange={(e) => setMethod(e.target.value)} value={method}>
          {methodOptions.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </Select>
      </Section>
      <Section>
        <InputLabel>URL (with query string if needed):</InputLabel>
        <GraySpan>{APIendpoint}</GraySpan>
        <Input type='text' value={url} onChange={(e) => setUrl(e.target.value)} />
      </Section>
      <Section>
        <div>
          <InputLabel>Request body (optional):</InputLabel>
        </div>
        <TextArea
          value={body}
          onChange={(e) => setBody(e.target.value)}
          disabled={method === 'get' || method === 'delete'}
          rows='20'
          cols='80'
        />
      </Section>
      <Section>
        <Button disabled={url === ''} onClick={() => callApiEndpoint({ url, method, body })}>
          Call API endpoint
        </Button>
      </Section>
    </div>
  )
}

export default GenericApiCall
