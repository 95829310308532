import qs from 'qs'

const parseQueryParams = (queryString = '') => {
  const str = queryString.slice(0, 1) === '?' ? queryString.slice(1) : queryString

  return qs.parse(str)
}

const getQueryParams = () => {
  return parseQueryParams(window.location.search)
}

const clearQueryParams = () => {
  const path = window.location.pathname
  window.history.replaceState({}, document.title, path)
}

export { parseQueryParams, getQueryParams, clearQueryParams }
