import PropTypes from 'prop-types'
import styled from 'styled-components'

import FormLabel from 'core/components/lib/FormLabel'
import Tooltip from 'core/components/lib/Tooltip'
import { useRect, useBorrowerIsMobile } from 'core/hooks'
import { variables } from 'core/styles'

import CardSectionList from './CardSectionList'

export const Container = styled.div`
  display: flex;
  align-items: ${(p) => p.alignItems ?? 'flex-start'};
  margin: ${(p) => p.margin};
`

const LabelContainer = styled.div`
  position: relative;
  display: flex;
  flex: 0 0 ${(p) => p.labelWidth || (p.shortLabel ? '40px' : '240px')};
  align-self: ${(p) => p.alignLabel ?? 'center'};
  margin-right: ${(p) => (p.marginRight ? p.marginRight : '0px')};
  transform: ${(p) => (p.hasSubLabel ? 'translateY(-0.4em)' : 'none')};
`

const SubLabel = styled(FormLabel)`
  position: absolute;
  bottom: 0;
  left: 0;
  color: ${variables.colorBlack40};
  transform: translateY(1.5rem);
`

const Content = styled.div`
  position: relative;
  flex: 1 1 auto;
`

const BlockContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
`
const BlockLabel = styled(FormLabel)`
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
`

const BlockContent = styled.div`
  padding: 8px;
  color: ${variables.colorBlack90};
`

const TooltipBox = styled.div`
  align-self: ${(p) => p.alignSelf ?? 'center'};
  margin-left: 16px;
`

const CardSection = (props) => {
  const { alignItems, alignLabel, children, label, margin, shortLabel, subLabel, labelWidth, marginRight, tooltip } =
    props

  const isMobile = useBorrowerIsMobile()
  const [{ width }, ref] = useRect()

  const compact = isMobile || width < 400

  return compact ?
      <BlockContainer ref={ref} margin={margin}>
        {label && <BlockLabel shortLabel={shortLabel} text={label} />}
        <BlockContent>{children}</BlockContent>
      </BlockContainer>
    : <>
        <Container ref={ref} margin={margin} alignItems={alignItems}>
          {label && (
            <LabelContainer
              hasSubLabel={!!subLabel}
              shortLabel={shortLabel}
              labelWidth={labelWidth}
              marginRight={marginRight}
              alignLabel={alignLabel}
            >
              <FormLabel text={label} />
              {subLabel && <SubLabel text={subLabel} />}
            </LabelContainer>
          )}
          <Content>{children}</Content>
          {tooltip && (
            <TooltipBox alignSelf={alignLabel}>
              <Tooltip content={tooltip} />
            </TooltipBox>
          )}
        </Container>
      </>
}

CardSection.propTypes = {
  alignItems: PropTypes.string,
  alignLabel: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  /** Only provide this if you need to override the
   * default margin of 24px for some special use case.
   */
  margin: PropTypes.string,
  shortLabel: PropTypes.bool,
  subLabel: PropTypes.string,
  labelWidth: PropTypes.string,
  marginRight: PropTypes.string,
  tooltip: PropTypes.node,
}
CardSection.defaultProps = {
  margin: '24px',
}

CardSection.List = CardSectionList

export default CardSection
