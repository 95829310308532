import { FC, PropsWithChildren } from 'react'

import isPropValid from '@emotion/is-prop-valid'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ShouldForwardProp, StyleSheetManager, ThemeProvider } from 'styled-components'

import store from 'core/store'
import { formatTheme } from 'core/SystemProvider/customizationsHelpers'

import { CompanyIdProvider } from './CompanyIdProvider'
import ToastProvider from './ToastProvider'

// styled-components v6 forwards all props by default. This resores the default behaviour of v5. Moving forward we
// should use transient props (see https://styled-components.com/docs/api#transient-props)
const shouldForwardProp: ShouldForwardProp<'web'> = (propName, target) =>
  typeof target !== 'string' || isPropValid(propName)

type CommonProvidersProps = PropsWithChildren<{
  companyId: string
}>

const CommonProviders: FC<CommonProvidersProps> = ({ companyId, children }) => (
  <BrowserRouter>
    <ReduxProvider store={store}>
      <CompanyIdProvider companyId={companyId}>
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <ThemeProvider theme={formatTheme({})}>
            <ToastProvider>{children}</ToastProvider>
          </ThemeProvider>
        </StyleSheetManager>
      </CompanyIdProvider>
    </ReduxProvider>
  </BrowserRouter>
)

export default CommonProviders
