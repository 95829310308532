import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'

import { R } from 'core/helpers'
import defaultQueryConfig from 'core/system/queries/defaultQueryConfig'
import { NewPaginator, Paging, SingletonResponse } from 'core/types/generated/utilities/types'
import getDefaultOptions from 'core/types/hooks/defaultOptions'

import { OperationData, Operation, callAPI } from './makeMutationHook'

export type PaginatedOperation = Operation & {
  response: NewPaginator & { data?: Array<unknown> }
}

export type LegacyPaginatedOperation = Operation & {
  response: Paging & { data?: Array<unknown> }
}

export type SingletonOperation = Operation & {
  response: SingletonResponse & { data?: unknown }
}

export type OperationResponse<Op extends Operation> =
  Required<Op['response']> extends Required<PaginatedOperation['response']> ?
    Op extends PaginatedOperation ?
      UseQueryResult<Op['response']['data']> & { pagination: NewPaginator | undefined }
    : never
  : Required<Op['response']> extends Required<LegacyPaginatedOperation['response']> ?
    Op extends LegacyPaginatedOperation ?
      UseQueryResult<Op['response']['data']> & { pagination: Paging | undefined }
    : never
  : Op extends SingletonOperation ? UseQueryResult<Op['response']['data']>
  : UseQueryResult<Op['response']>

export type QueryKey<Op extends Operation> = readonly [...Op['key'], {}]

export type QueryHookArguments<Op extends Operation> = Op['parameters'] & {
  query?: Op['query']
  body?: Op['requestBody']
  options?: Partial<UseQueryOptions<Op['response'], unknown, Op['response'], QueryKey<Op>>>
}

export const getQueryOptions = <Op extends Operation, Options extends { enabled?: boolean }>(
  data: OperationData<Op>,
  args: Op['parameters'] & { query?: Op['query']; options?: Options },
): Options & {
  queryKey: QueryKey<Op>
  queryFn: () => Promise<Op['response']>
  throwOnError: boolean
  meta: { operation: string }
} => {
  const defaultOptions = getDefaultOptions(data)
  const { query, options, ...parameters } = defaultOptions ? (R.mergeDeep(defaultOptions, args) as typeof args) : args

  const queryKey = [...data.queryKey(parameters), query ?? {}] as const

  return {
    ...defaultQueryConfig,
    queryKey,
    queryFn: () => callAPI<Op>(data, { query, ...parameters } as any),
    throwOnError: true,
    meta: {
      operation: data.summary,
    },
    ...(options ?? ({} as Options)),
    enabled: !queryKey.some((variable) => !variable) && options?.enabled,
  }
}

const makeQueryHook =
  <Op extends Operation>(data: OperationData<Op>) =>
  (args: QueryHookArguments<Op>): OperationResponse<Op> => {
    const result = useQuery(getQueryOptions(data, args))

    return (
      result.data && typeof result.data === 'object' && 'data' in result.data ?
        {
          ...result,
          data: result.data.data,
          pagination: R.pick(result.data as any, ['total', 'count', 'nextUrl', 'previousUrl']),
        }
      : result) as any
  }

export default makeQueryHook
