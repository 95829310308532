/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const bootstrap: OperationData<Types.Bootstrap> = {
  method: 'get',
  name: 'bootstrap',
  summary: `Get bootstrap data`,
  path: '/bootstrap',
  queryKey: ({}) => ['bootstrap'],
}
