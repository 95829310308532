import forEach from 'lodash/forEach'

import { checkTimeordate } from '../types'
import mtz from '../utils/mtz'

const makeClosestTo = (context = {}) => {
  const { timezone } = context

  const closestTo = (subjectTimeordate, timeordateArray) => {
    checkTimeordate(subjectTimeordate)
    forEach(timeordateArray, checkTimeordate)

    const subjectMoment = mtz(subjectTimeordate, timezone)
    const diffArray = timeordateArray.map((item) => Math.abs(mtz(item, timezone).diff(subjectMoment)))
    const minDiff = Math.min(...diffArray)
    const minIndex = diffArray.indexOf(minDiff)
    return timeordateArray[minIndex]
  }

  return closestTo
}

export default makeClosestTo
