// Error import: keep at top
import './polyfill'
import 'core/system/ErrorLogging'
// -------------------------
import 'index.scss'
import 'core/hooks/useLog'
import { datadogLogs } from '@datadog/browser-logs'
import { createRoot } from 'react-dom/client'

import { getOverride } from 'core/components/PeachOverrides/storage'
import Root from 'core/SystemProvider/Root'

if (import.meta.env.VITE_ENABLE_API_MOCKING && getOverride('enableApiMocking')) {
  import('core/components/PeachOverrides/msw').then((lib) => lib.start())
}

// Disabled on local builds
if (!!import.meta.env.VITE_DATADOG_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    sessionSampleRate: 100,
    env: import.meta.env.VITE_ENV_LABEL ?? 'unknown',
    version: import.meta.env.VITE_SENTRY_RELEASE,
  })
}

createRoot(document.getElementById('root')!).render(<Root />)
