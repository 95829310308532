import React from 'react'

import PropTypes from 'prop-types'

import { useUserType } from 'core/hooks'

import ActionDisabledMessage from './ActionDisabledMessage'

const agentText = `You do not have permission to perform this action.  Please contact your supervisor for help.`

const borrowerText = `You do not have permission to perform this action.  Please contact support for help.`

const PermissionMessage = ({ backUrl }) => {
  const userType = useUserType()

  const borrowerUrl = backUrl || '/loans'

  const text = userType === 'agent' ? agentText : borrowerText

  return <ActionDisabledMessage message={text} backUrl={borrowerUrl} />
}

PermissionMessage.propTypes = {
  backUrl: PropTypes.string,
}

export default PermissionMessage
